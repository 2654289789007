import {
  MyIcon,
  VehicleIcon,
  DriverIcon,
  // eslint-disable-next-line import/extensions
} from '../../assets/images/svg/ProfileIcon.jsx';

const UserProfileSidebarMenu = [
  {
    name: 'Driver Profile',
    icon: DriverIcon,
    path: '/profiles/driver',
  },
  {
    name: 'Vehicle Profile',
    icon: VehicleIcon,
    path: '/profiles/vehicle',
  },
  {
    name: 'My Profile',
    icon: MyIcon,
    path: '/profiles/my-profile',
  },
];

export default UserProfileSidebarMenu;
