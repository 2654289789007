/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Link } from 'react-router-dom';
import TermsPopUp from '../Terms/TermsPopup';
import PrivacyPopUp from '../Terms/PrivacyPopup';
import Authorization from '../../Helpers/Authorization';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPrivacyModal: false,
      openTermsModal: false,
    };
  }

  toggleModal = () => {
    this.setState({
      openPrivacyModal: !this.state.openPrivacyModal,
    });
  };

  openTermsModal = () => {
    this.setState({ openTermsModal: true });
  };

  closeTermsModal = () => {
    this.setState({ openTermsModal: false });
  };

  render() {
    const { openPrivacyModal, openTermsModal } = this.state;

    return (
      <>
        <footer
          className={
            Authorization.isLoggedIn()
              ? 'footerWrapper'
              : 'footerWrapperNotLogged'
          }
        >
          <div className="footer-left">
            <ul className="list-reset footerList">
              <li
                onClick={this.toggleModal}
                className="privacyPage"
                role="presentation"
              >
                Privacy policy
              </li>
              <li
                onClick={this.openTermsModal}
                className="TersmPage"
                role="presentation"
              >
                Terms & Conditions
              </li>
              {Authorization.isLoggedIn() && (
                <Link to="/support">
                  <li>
                    Help
                    <span className="ml-1">
                      <i className="fa fa-question-circle" />
                    </span>
                  </li>
                </Link>
              )}
            </ul>
          </div>
          <div className="subWrapper">
            <p>For Support - Telephone: 1800-120-380380</p>
            <p>dicv_service@daimlertruck.com | </p>

            <p>
              {`Copyrights © ${new Date().getFullYear()} Daimler India. 
              All rights reserved.`}
            </p>
          </div>
        </footer>

        <PrivacyPopUp open={openPrivacyModal} onClose={this.toggleModal} />
        <TermsPopUp open={openTermsModal} onClose={this.closeTermsModal} />
      </>
    );
  }
}
export default Footer;
