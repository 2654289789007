import {
  PENDING,
  FULFILLED,
  REJECTED,
  saveProfile,
  getAdminDetails,
  getCountries,
  getProfile,
} from '../../../Helpers/Constants';

/**
 * get dealer profile list
 * Hanlde the Pending, Fullfilled (Success), Rejected action
 *
 * @return object
 */
export function getMyProfile(data) {
  return {
    types: [
      `GET_MY_PROFILE_${PENDING}`,
      `GET_MY_PROFILE_${FULFILLED}`,
      `GET_MY_PROFILE_${REJECTED}`,
    ],
    method: 'GET',
    url: getProfile,
    queryParams: {
      userId: data.userId,
    },
  };
}
export function getCountryList(data) {
  return {
    types: [
      `GET_COUNTRY_LIST_${PENDING}`,
      `GET_COUNTRY_LIST_${FULFILLED}`,
      `GET_COUNTRY_LIST_${REJECTED}`,
    ],
    method: 'GET',
    url: getCountries,
    payload: {},
    data,
  };
}
/**
 * create dealer profile async action creators
 * Hanlde the Pending, Fullfilled (Success), Rejected action
 *
 * @return object
 */
export function saveMyProfile(data) {
  return {
    types: [
      `UPDATE_MY_ADMIN_PROFILE_${PENDING}`,
      `UPDATE_MY_ADMIN_PROFILE_${FULFILLED}`,
      `UPDATE_MY_ADMIN_PROFILE_${REJECTED}`,
    ],
    method: 'PUT',
    url: saveProfile,
    queryParams: {
      userId: data.userId,
    },
    payload: {},
    data,
  };
}

export function getUserDetailForMyAdmin(data) {
  return {
    types: [
      `GET_USER_DETAIL_${PENDING}`,
      `GET_USER_DETAIL_${FULFILLED}`,
      `GET_USER_DETAIL_${REJECTED}`,
    ],
    method: 'GET',
    url: getAdminDetails,
    queryParams: {
      userId: data.userId,
    },
    payload: {},
    data,
  };
}
