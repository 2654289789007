import React from 'react';
import ReactGA from 'react-ga';

// Reducers-File
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Router

// Routes-File
import PrivateRoute from './components/Routes/PrivateRoute';
import PublicRoute from './components/Routes/PublicRoute';
import { ROUTE } from './config';
import Env from './Helpers/env';

// Not-Found Component
import { NotFound } from './components/NotFound';
import Authorization from './Helpers/Authorization';
// import LiveTracking from './components/MyFleet/components/LiveTracking';
import LiveTrack from './components/MyFleet/MyLiveTrack';

class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize('UA-183818462-1');
    if (Authorization.isLoggedIn()) {
      ReactGA.set({ userId: Authorization.getAuthUserId() });
    }
    // ReactGA.set({ page: window.location.pathname });
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }

  setRoutes = () => {
    const route = ROUTE;

    return route.map(route => {
      if (route.private === true) {
        return (
          <PrivateRoute
            private={route.private}
            key={route.id}
            path={route.path}
            meta={route.meta}
            exact={route.exact}
            component={route.component}
            sideBar={route.sideBar}
            sideBarMenu={route.sideBarMenu}
          />
        );
      }

      return (
        <PublicRoute
          key={route.id}
          path={route.path}
          meta={route.meta}
          exact={route.exact}
          section={route.component}
          component={route.component}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <Router basename={Env.getEnv('REACT_APP_HOMEPAGE')}>
          <Switch>
            {this.setRoutes()}
            <PublicRoute exact path="/login" component="/login" />
            {/* <Route path="/liveTracking" component={LiveTracking} /> */}
            <Route path="/liveTracking" component={LiveTrack} />
            <NotFound default />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
