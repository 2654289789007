import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Authorization from '../../Helpers/Authorization';
import Application from './Application';

/**
 * If we have a logged-in user, redirect to the home page. Otherwise, display the component.
 */
const PublicRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (Authorization.isLoggedIn()) {
        if (Authorization.isAdmin()) {
          return <Redirect to={{ pathname: '/profile' }} />;
        }

        return <Redirect to={{ pathname: '/owner-suite' }} />;
      }

      return (
        <Application
          {...props}
          isLoggedIn={Authorization.isLoggedIn}
          auth={Authorization}
          component={Component}
          path={path}
        />
      );
    }}
  />
);

export default PublicRoute;
