/*eslint-disable*/
import lodashCamelCase from 'lodash.camelcase';
import ReactGA from 'react-ga';
import _ from 'lodash';
import React from 'react';
import toastr from 'toastr';
import moment from 'moment';
import { setMinutes, setHours, subDays } from 'date-fns';
import Authorization from './Authorization';

class Utility {
  /**
   * Get element from array by property
   *
   * @param array
   * @param mixed
   * @param string
   * @return mixed
   */
  static getElementFromArrayByProperty(elements, value, property = 'id') {
    let selectedScriptIndex = false;

    const selectedElement = elements.filter((element, index) => {
      let filtered = false;
      if (element[property] === value) {
        filtered = true;
        selectedScriptIndex = index;
      }

      return filtered;
    });

    return {
      element: selectedElement.shift(),
      index: selectedScriptIndex,
    };
  }

  /**
   * Get specific element attributes from array
   *
   * @param array
   * @param mixed
   * @param string
   * @return mixed
   */
  static getSpecificAttributeFromArray(elements, property) {
    return Array.isArray(elements) && Utility.isString(property)
      ? elements.map((element) =>
          Utility.isObject(element) && element.hasOwnProperty(property)
            ? element[property]
            : null
        )
      : [];
  }

  /**
   * delete element from array by property value
   *
   * @param array
   * @param mixed
   * @param string
   * @return mixed
   */
  static removeElementFromArrayByProperty(elements, value, property = 'id') {
    return elements.filter(
      (element) => element[property] && element[property] !== value
    );
  }

  /**
   * Safly push element to array
   *
   * @param elements
   * @param element
   * @param string
   * @return array
   */
  static safelyPushElementToArray(elements, element, property = 'id') {
    const elementInfo = Utility.getElementFromArrayByProperty(
      elements,
      element[property]
    );

    if (!elementInfo.index && Array.isArray(elements)) {
      elements.push(element);
    }

    return elements;
  }

  /**
   * @description
   * Determines if a reference is an `Object`. Unlike `typeof` in JavaScript, `null`s are not
   * considered to be objects. Note that JavaScript arrays are objects.
   *
   * @param {*} value Reference to check.
   * @returns {boolean} True if `value` is an `Object` but not `null`.
   */
  static isObject(value) {
    return value !== null && typeof value === 'object';
  }

  /**
   *
   * @description
   * Determines if a reference is a `String`.
   *
   * @param {*} value Reference to check.
   * @returns {boolean} True if `value` is a `String`.
   */
  static isString(value) {
    return typeof value === 'string';
  }

  /**
   * Determines if a value is a regular expression object.
   *
   * @private
   * @param {*} value Reference to check.
   * @returns {boolean} True if `value` is a `RegExp`.
   */
  static isRegExp(value) {
    return toString.call(value) === '[object RegExp]';
  }

  /**
   * @description
   * Determines if a value is a date.
   *
   * @param {*} value Reference to check.
   * @returns {boolean} True if `value` is a `Date`.
   */
  static isDate(value) {
    return toString.call(value) === '[object Date]';
  }

  /**
   * @description
   * Determines if a reference is a `Function`.
   *
   * @param {*} value Reference to check.
   * @returns {boolean} True if `value` is a `Function`.
   */
  static isFunction(value) {
    return typeof value === 'function';
  }

  /**
   * @description
   * Determines if a reference is defined.
   *
   * @param {*} value Reference to check.
   * @returns {boolean} True if `value` is defined.
   */
  static isDefined(value) {
    return typeof value !== 'undefined';
  }

  /**
   * @description
   * Check current browser is ie
   *
   * @returns {boolean} True if `value` is defined.
   */
  static isIE() {
    return /* @cc_on!@ */ false || !!document.documentMode;
  }

  /**
   * @description
   * Check current browser is Edge
   *
   * @returns {boolean} True if `value` is defined.
   */
  static isEdge() {
    return !Utility.isIE() && !!window.StyleMedia;
  }

  /**
   * @description
   * Get image as base 64
   *
   * @param image
   * @param ext
   * @returns string
   */
  static getImageAsBase64(image, ext) {
    return `data:image/${ext};base64,${image}`;
  }

  /**
   * @description
   * Get string as camel case
   *
   * @param string
   * @returns string
   */
  static camelCase(str) {
    const convertedStr = lodashCamelCase(str);

    return convertedStr.charAt(0).toUpperCase() + convertedStr.slice(1);
  }
}

export default Utility;

/**
 * function is to get formatted date time
 * @param {*} datetime Unix date time
 */
export const getFormattedDateTime = (datetime) => {
  // unix to milliseconds
  const milliseconds = parseInt(datetime, 10);
  datetime = new Date(milliseconds);

  return datetime.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

/**
 * function is to get formatted date
 * @param {*} datetime Unix date
 */
export const getFormattedDate = (datetime) => {
  // unix to milliseconds
  const milliseconds = parseInt(datetime, 10);
  datetime = new Date(milliseconds);

  return datetime.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
};

/**
 * Convert strings to its actual data type
 * @param {*} inputObj input object
 *
 * @return Object
 */
export const ParseStringToItsDatatype = (inputObj) => {
  const obj = { ...inputObj };
  for (const i in obj) {
    switch (true) {
      case String(obj[i]).toLowerCase() == 'true':
        obj[i] = true;
        break;
      case String(obj[i]).toLowerCase() == 'false':
        obj[i] = false;
        break;
      case String(obj[i]).match(/^[0-9]+$/) != null:
        obj[i] = parseInt(obj[i], 10);
        break;
      case String(obj[i]).match(/^[-+]?[0-9]+\.[0-9]+$/) != null:
        obj[i] = parseFloat(obj[i]);
        break;
      default:
        break;
    }
  }

  return obj;
};

/**
 * Function is to truncate the string based on number of characters
 * @param {*} string
 * @param {*} length
 */
export const TrimString = (string, length) =>
  _.truncate(string, {
    length, // maximum 30 characters
    separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
  });

export const isArrayEqual = function (x, y) {
  if (x.length !== y.length) return false;

  return _(x).differenceWith(y, _.isEqual).isEmpty();
};

/**
 * This method is to show no records found  in table
 * @param count:array
 * @param edit :boolean
 */
export const noRecordsTable = (count, edit) => {
  if (count.length) {
    const value = edit === true ? count.length + 3 : count.length + 2;

    return (
      <tr>
        <td colSpan={value}>
          <p className="nodatamsg">No Records Found</p>
        </td>
      </tr>
    );
  }
};

export const noRecordsTableTrip = (loading, count, edit) => {
  if (count.length) {
    const value = edit === true ? count.length + 3 : count.length + 2;

    return (
      <tr>
        <td colSpan={value}>
          {loading ? (
            <p className="nodatamsg">Loading...</p>
          ) : (
            <p className="nodatamsg">No Records Found</p>
          )}
        </td>
      </tr>
    );
  }
};

export const handleToastrMessage = (type, msg) => {
  if (type === 'success') {
    toastr.success(msg);
  } else if (type === 'error') {
    toastr.error(msg);
  } else if (type === 'warning') {
    toastr.warning(msg);
  }
};

export const dateAndTime = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  const date = `${today.getDate()}-${month}-${today.getFullYear()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

  return `${date} ${time}`;
};

export const downloadPdf = (response, fileName) => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(response, `${`${fileName}_${dateAndTime()}`}.pdf`);
  } else {
    const file = new Blob([response], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('A');
    a.href = fileURL;
    a.download = `${`${fileName}_${dateAndTime()}`}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
export const downloadXls = (response, fileName) => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(response, `${`${fileName}_${dateAndTime()}`}.xls`);
  } else {
    const file = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('A');
    a.href = fileURL;
    a.download = `${`${fileName}_${dateAndTime()}`}.xls`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
/**
 * function is to get formatted date
 * @param {*} datetime Unix date
 */
export const topFiveDrivers = (driverAnalysisList) => {
  const topFiveDriversArr = [];
  if (driverAnalysisList) {
    let driversAsc = _.sortBy(driverAnalysisList, 'driverScore');
    const drivers = driversAsc.reverse();
    for (let r = 0; r < drivers.length; r++) {
      if (r < 5) {
        topFiveDriversArr.push(drivers[r]);
        driversAsc = driversAsc.filter(
          (obj) => obj.driverId !== drivers[r].driverId
        );
      } else break;
    }

    return driversAsc;
  }

  return 1;
};

export const isResponseComplete = (responseData) => {
  const isFetching = !responseData.isFetching;

  return isFetching;
};

export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const isResponseSucces = (responseData) => {
  if (!responseData.isError) {
    if (!responseData.response.type && isEmpty(responseData.response)) {
      toastr.warning('Response is empty');

      return false;
    }
    if (responseData.response && responseData.response.status === 204) {
      // const message = responseData.response.message
      //   ? responseData.response.message
      //   : 'Response Message is empty';

      return false;
    }

    return true;
  }
  if (responseData.isError) {
    toastr.error(responseData.response.message);

    return false;
  }
};

export const isResponseSuccesForLiveTrip = (responseData) => {
  if (!responseData.isError) {
    if (!responseData.response.type && isEmpty(responseData.response)) {
      // toastr.warning('Response is empty');
      return false;
    }
    if (responseData.response && responseData.response.status === 204) {
      return true;
    }

    return true;
  }
  if (responseData.isError) {
    toastr.error(responseData.response.message);

    return false;
  }
};

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const ReturnMsg = (event) => {
  let txtMsg =
    event.target.value == 'last30Days'
      ? 'Select period 30 days click'
      : event.target.value == 'last6Months'
      ? 'Select period 6 Months click'
      : event.target.value == 'last7Days'
      ? 'Select period 7 days click'
      : 'Select period 1 year click';

  return txtMsg;
};

export const ReturnLabel = (event) => {
  let txtLabel =
    event.target.value == 'last30Days'
      ? 'Choose period 30 days views'
      : event.target.value == 'last6Months'
      ? 'Choose period 6 months  views'
      : event.target.value == 'last7Days'
      ? 'Choose period 7 days views'
      : 'Choose period 1 year views';

  return txtLabel;
};

export const getStatus = (response) => {
  let newStatus = [];
  let newArray = [];
  let statusArray = [];

  Object.keys(response.data).forEach((status) => {
    newStatus.push({
      number: isNaN(response.data[status])
        ? 0
        : parseInt(response.data[status]),
      text:
        status === 'RUNNING'
          ? 'Running'
          : status === 'IDLING'
          ? 'Idling'
          : status === 'OFFLINE' && 'Offline',
      color:
        status == 'RUNNING'
          ? '#0c96e8'
          : status == 'IDLING'
          ? '#8e78ff'
          : status == 'OFFLINE' && '#ff8081',
    });
  });

  newStatus.map((e) => {
    statusArray.push(e.number);
  });

  const sum = statusArray.reduce((a, b) => a + b, 0);

  newStatus.map((e) => {
    newArray.push({
      progress: Math.round((e.number / sum) * 100),
      number1: e.number,
      color: e.color,
      text: e.text,
    });
  });

  return newArray;
};

export const getScore = (response) => {
  let driverScore = [];
  Object.keys(response.data).forEach((status) => {
    driverScore.push({
      status: isNaN(response.data[status])
        ? 0
        : parseFloat(response.data[status]),
      text: status,
    });
  });

  return driverScore;
};

const zeroTest = (element) => element === 0;

export const getTrip = (data) => {
  var zeroArr = [];

  Object.keys(data.response && data.response.statusPercentage).forEach((e) => {
    if (data.response.statusPercentage[e] == null) {
      zeroArr.push(0);
    } else {
      zeroArr.push(data.response.statusPercentage[e]);
    }
  });

  const tripZero = zeroArr.every(zeroTest);

  const tripData = [];
  Object.keys(
    data.response.statusPercentage && data.response.statusPercentage
  ).forEach((e) => {
    tripData.push({
      text: e,
      number:
        data.response.statusPercentage[e] == null
          ? 0
          : data.response.statusPercentage[e] &&
            parseInt(data.response.statusPercentage[e]),
      color:
        e == 'Early'
          ? '#09d0eb'
          : e == 'Ontime'
          ? '#8b09dc'
          : e == 'Delay expected'
          ? '#d669fd'
          : '#d669fd',
    });
  });
  return { tripData, tripZero };
};

// function to format fmt dates
export const getFMTDates = (startDate, endDate) => {
  const date = moment(startDate).format('YYYY-MM-DD');
  const hr = `${moment(startDate).hour()}`;
  const hrs = hr.length == 1 ? 0 + hr : hr;
  const FinalFormat = `${date} ${hrs}:00:00`;

  const date1 = moment(endDate).format('YYYY-MM-DD');
  const hr1 = `${moment(endDate).hour()}`;
  const hrs1 = hr1.length == 1 ? 0 + hr1 : hr1;
  const endFinalFormat = `${date1} ${hrs1}:00:00`;
  return { FinalFormat, endFinalFormat };
};

export const FromDateConversion = (startDate) => {
  const startDt = moment(startDate).format('YYYY-MM-DD');
  const startHr = `${moment(startDate).hour()}`;
  const startMin = `${moment(startDate).minute()}`;
  const startHrs = startHr.length == 1 ? 0 + startHr : startHr;
  const mins = startMin.length == 1 ? 0 + startMin : startMin;
  const startFinalFromat = `${startDt}T${startHrs}:${mins}`;
  return startFinalFromat;
};

export const ToDateConversion = (endDate) => {
  const endDt = moment(endDate).format('YYYY-MM-DD');
  const endHr = `${moment(endDate).hour()}`;
  const endMin = `${moment(endDate).minute()}`;
  const endHrs = endHr.length == 1 ? 0 + endHr : endHr;
  const emins = endMin.length == 1 ? 0 + endMin : endMin;
  const endFinalFromat = `${endDt}T${endHrs}:${emins}`;
  return endFinalFromat;
};

// function to check user role and controlling few apis
export const restrictRole = (role) => {
  const userId = Authorization.getAuthUserId();
  const userRole = Authorization.getAuthUserRole();
  if (role === 'SUPPORT') {
    if (
      userRole === 'ANALYTICSUSER' ||
      userRole === 'ROOTADMIN' ||
      userId === 98061
    ) {
      return true;
    } else {
      return false;
    }
  } else if (role === 'CUSTOMER') {
    if (
      userRole !== 'ANALYTICSUSER' &&
      userRole !== 'ROOTADMIN' &&
      userId !== 98061
    ) {
      return true;
    } else {
      return false;
    }
  }
};

// function to return batch vehicles
export const checkBatchVehicles = (vehicleList, firstIndex, lastIndex) => {
  const batchVehicles = vehicleList && vehicleList.slice(firstIndex, lastIndex);
  const batchVehicleIds =
    batchVehicles &&
    batchVehicles.map((e) => {
      return e.vehicleId + '';
    });

  return batchVehicleIds;
};

// function to format driving analysis dates
export const drivingAlanlysisDates = (startDate, endDate) => {
  const startDt = moment(startDate).format('YYYY-MM-DD');
  const startHr = `${moment(startDate).hour()}`;
  const startMin = `${moment(startDate).minute()}`;
  const startHrs = startHr.length == 1 ? 0 + startHr : startHr;
  const mins = startMin.length == 1 ? 0 + startMin : startMin;
  const startFinalFromat = `${startDt}T${startHrs}:${mins}`;

  const endDt = moment(endDate).format('YYYY-MM-DD');
  const endHr = `${moment(endDate).hour()}`;
  const endMin = `${moment(endDate).minute()}`;
  const endHrs = endHr.length == 1 ? 0 + endHr : endHr;
  const emins = endMin.length == 1 ? 0 + endMin : endMin;
  const endFinalFromat = `${endDt}T${endHrs}:${emins}`;
  return { startFinalFromat, endFinalFromat };
};

// function to return batch vehicles based on set selected
export const findRange = (vehicleList) => {
  const len = vehicleList.length;
  if (len <= 50) {
    return {
      batchRange: [{ batch: '1-50', value: 1, id: 1 }],
    };
  } else if (len <= 100) {
    return {
      batchRange: [
        { batch: '1-50', value: 1, id: 1 },
        { batch: '51-100', value: 2, id: 2 },
      ],
    };
  } else if (len <= 150) {
    return {
      batchRange: [
        { batch: '1-50', value: 1, id: 1 },
        { batch: '51-100', value: 2, id: 2 },
        { batch: '101-150', value: 3, id: 3 },
      ],
    };
  } else if (len <= 200) {
    return {
      batchRange: [
        { batch: '1-50', value: 1, id: 1 },
        { batch: '51-100', value: 2, id: 2 },
        { batch: '101-150', value: 3, id: 3 },
        { batch: '151-200', value: 4, id: 4 },
      ],
    };
  } else if (len > 200) {
    return {
      batchRange: [
        { batch: '1-50', value: 1, id: 1 },
        { batch: '51-100', value: 2, id: 2 },
        { batch: '101-150', value: 3, id: 3 },
        { batch: '151-200', value: 4, id: 4 },
        { batch: '201-250', value: 5, id: 5 },
      ],
    };
  }
};

// function to return selected vehicles
export const selectedVehicles = (filterData) => {
  const vehicles = [];
  if (Object.keys(filterData).length === 0) {
    return vehicles;
  } else {
    const { filterObj } = filterData;
    const { vehicles } = filterObj;
    return vehicles;
  }
};

// function to return selected dates
export const selectedDates = (filterData) => {
  if (Object.keys(filterData).length !== 0) {
    const { filterObj } = filterData;
    const { startDate, endDate } = filterObj;
    return {
      startDate,
      endDate,
    };
  }
};

// function to reset end date if end date is today with 0 hours else return date
export const resetEndDate = (endDate) => {
  const selectedDt = moment(endDate).format('YYYY-MM-DD');
  const curentDt = moment(new Date()).format('YYYY-MM-DD');
  let edate = endDate;

  if (curentDt === selectedDt) {
    edate = setHours(setMinutes(edate, 0), 0);
  } else {
    return edate;
  }

  return edate;
};

// function to reset end hour if end date is today with 0 hours else return selected hour
export const resetEndHour = (endDate) => {
  const selectedDt = moment(endDate).format('YYYY-MM-DD');
  const curentDt = moment(new Date()).format('YYYY-MM-DD');
  let eHour = 0;

  if (curentDt === selectedDt) {
    eHour = 0;
  } else {
    eHour = 23;
    return eHour;
  }

  return eHour;
};

// function to reset end hour if end date is today with current hours else return selected hour
export const resetAlertEndHour = (endDate) => {
  const selectedDt = moment(endDate).format('YYYY-MM-DD');
  const curentDt = moment(new Date()).format('YYYY-MM-DD');
  let eHour = 0;

  if (curentDt === selectedDt) {
    eHour = new Date().getHours();
  } else {
    eHour = 23;
    return eHour;
  }
  return eHour;
};

// function to reset end date if end date is today with current hours else return date
export const resetEndAlertDate = (endDate) => {
  const selectedDt = moment(endDate).format('YYYY-MM-DD');
  const curentDt = moment(new Date()).format('YYYY-MM-DD');
  let edate = endDate;

  if (curentDt === selectedDt) {
    edate = setHours(setMinutes(edate, 0), new Date().getHours());
  } else {
    return edate;
  }

  return edate;
};

// function to reset start date if start date is today with 0 hours else return date
export const resetStartDate = (startDate) => {
  const selectedDt = moment(startDate).format('YYYY-MM-DD');
  const curentDt = moment(new Date()).format('YYYY-MM-DD');
  let edate = startDate;

  if (curentDt === selectedDt) {
    edate = subDays(setHours(setMinutes(new Date(), 0), 0), 1);
  } else {
    return edate;
  }
  return edate;
};

//function to clear selected batch vehicles and to return remaining vehicles while switching tiles
export const filterVehicles = (
  checkedItems,
  activeVehiclesList,
  firstIndex,
  lastIndex
) => {
  let newItem = [];
  checkedItems.splice(0, 50);
  const items = checkBatchVehicles(activeVehiclesList, firstIndex, lastIndex);
  items.push(...checkedItems);
  newItem = [...new Set(items)];
  return newItem;
};

//function to clear selected batch vehicles and to return remaining vehicles while clicking clear
export const clearVehicles = (checkedItems) => {
  checkedItems.splice(0, 50);
  return checkedItems;
};

//function to maintain selected vehicles
export const maintainVehicles = (
  checkedItems,
  activeVehiclesList,
  firstIndex,
  lastIndex
) => {
  let newItem = [];
  const items = checkBatchVehicles(activeVehiclesList, firstIndex, lastIndex);
  items.push(...checkedItems);
  newItem = [...new Set(items)];
  return newItem;
};

//function to find diiference between two dates in days
export const findDifferenceInDays = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const Difference_In_Time = date2.getTime() - date1.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Difference_In_Days;
};

// function to retain original list while toggling dropdown
export const retainVehicleList = (vehicleList) => {
  if (vehicleList && vehicleList.length > 0) {
    const range = findRange(vehicleList && vehicleList);
    const sortedData = _.orderBy(vehicleList, ['registrationId'], ['asc']);
    return {
      batchRange: range.batchRange,
      sortedData,
    };
  } else {
    return vehicleList;
  }
};

//toggle function to display vehiclepopup
export const togglePopUp = () => {
  let driverDetails = document.querySelector('#driverDetails');
  let lodingTemp = document.querySelector('#loading-pop-up');
  let viewDetails = document.querySelector('#view-details');
  let closeInfo = document.querySelector('#openArrow');
  let vehicleDetails = document.querySelector('.vehicleDetails');
  lodingTemp.innerHTML = '';
  viewDetails.style.display = 'none';
  closeInfo.style.display = 'block';
  vehicleDetails.style.display = 'flex';
  driverDetails.style.display = 'flex';
  lodingTemp.innerHTML = '';
};

//toggle function to display vehiclepopup
export const showTripDetails = (response) => {
  let tripenddate = document.querySelector('#tripenddate');
  let tripendtime = document.querySelector('#tripendtime');
  let tripdistance = document.querySelector('#tripdistance');
  let tripfuel = document.querySelector('#tripfuel');
  let tripadblue = document.querySelector('#tripadblue');

  tripenddate.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.scheduledEndDate &&
    response.findMyTruckVehicleDetail.scheduledEndDate;
  tripenddate.classList.add('info-bg-date-fmt');

  tripendtime.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.scheduledEndTime &&
    response.findMyTruckVehicleDetail.scheduledEndTime;
  tripendtime.classList.add('info-bg-trip-time');

  tripdistance.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.tripDistance &&
    `${response.findMyTruckVehicleDetail.tripDistance} Km`;
  tripdistance.classList.add('info-bg-trip-distance');

  tripfuel.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.tripTFC &&
    `${response.findMyTruckVehicleDetail.tripTFC} Ltrs`;
  tripfuel.classList.add('info-bg-fuel-fmt');

  tripadblue.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.tripADL &&
    `${response.findMyTruckVehicleDetail.tripADL} Ltrs`;
  tripadblue.classList.add('info-bg-adl-fmt');
};

//toggle function to display vehiclepopup
export const showVehicleDetails = (response) => {
  let date = document.querySelector('#vehicleDate');
  let time = document.querySelector('#vehicleTime');
  let fuel = document.querySelector('#vehicleFuel');
  let adblue = document.querySelector('#vehicleAdblue');
  let driver = document.querySelector('#driver');
  let tripcontact = document.querySelector('#tripcontact');

  if (
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.driverFullName == ''
  ) {
    driver.innerHTML = 'NA';
    driver.classList.add('info-bg-driver-fmt');
  } else {
    driver.innerHTML =
      response &&
      response.findMyTruckVehicleDetail &&
      response.findMyTruckVehicleDetail.driverFullName &&
      response.findMyTruckVehicleDetail.driverFullName;
    driver.classList.add('info-bg-driver-fmt');
  }

  if (
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.contactNumber == null
  ) {
    tripcontact.innerHTML = 'NA';
    tripcontact.classList.add('info-bg-phone-fmt');
  } else {
    tripcontact.innerHTML =
      response &&
      response.findMyTruckVehicleDetail &&
      response.findMyTruckVehicleDetail.contactNumber &&
      response.findMyTruckVehicleDetail.contactNumber;
    tripcontact.classList.add('info-bg-phone-fmt');
  }

  time.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.lastUpdatedTime &&
    response.findMyTruckVehicleDetail.lastUpdatedTime;
  time.classList.add('info-bg-time-fmt');

  fuel.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.currentFuelLevel &&
    `${response.findMyTruckVehicleDetail.currentFuelLevel} %`;
  fuel.classList.add('info-bg-fuel-fmt');

  adblue.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.currentAdblueLevel &&
    `${response.findMyTruckVehicleDetail.currentAdblueLevel} %`;
  adblue.classList.add('info-bg-adl-fmt');

  date.innerHTML =
    response &&
    response.findMyTruckVehicleDetail &&
    response.findMyTruckVehicleDetail.lastUpdatedDate &&
    response.findMyTruckVehicleDetail.lastUpdatedDate;
  date.classList.add('info-bg-date-fmt');
};

{
  /** formatting -- geocoder response into address field format */
}
export const geocoderAddressFormater = (address_components) => {
  let address = '';
  address_components.map((e, i) => {
    if (e.types.includes('premise'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('street_number'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('neighborhood'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('sublocality_level_3'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('sublocality_level_2'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('sublocality_level_1'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('locality'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('administrative_area_level_2'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('administrative_area_level_1'))
      address = address.concat(e.long_name, ', ');
    if (address_components.length === i + 1 && e.types.includes('country'))
      address = address.concat(e.long_name, '.');
    if (address_components.length !== i + 1 && e.types.includes('country'))
      address = address.concat(e.long_name, ', ');
    if (e.types.includes('postal_code'))
      address = address.concat(e.long_name, '.');
  });
  return address;
};
