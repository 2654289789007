/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';
import ReactTooltip from 'react-tooltip';
import Authorization from '../../Helpers/Authorization';
import { getUserDetailForMyAdmin } from '../Profiles/MyProfile/MyProfileAction';
import Logo from '../../assets/images/Truck-Connect-Logo.png';
import profileImg from '../../assets/images/profile-icon-modified.png';
import logoutImg from '../../assets/images/log-out-new.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      firstName: '',
    };

    this.handleClickToggle = this.handleClickToggle.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem('firstName') !== null) {
      const firstName = localStorage.getItem('firstName');
      this.setState({ firstName });
    } else {
      const obj = { userId: Authorization.getAuthUserId() };
      this.props.dispatch(getUserDetailForMyAdmin(obj));
    }
  }

  componentDidUpdate(prevProps) {
    // get response with based on id
    if (
      !isEqual(
        prevProps.getUserDetailForMyAdmin.response,
        this.props.getUserDetailForMyAdmin.response
      )
    ) {
      const authUser = JSON.parse(localStorage.getItem('auth_user'));
      const { response } = this.props.getUserDetailForMyAdmin;

      if (authUser.id === response.userId) {
        localStorage.setItem('firstName', response.firstName);
        this.setState({ firstName: response.firstName });
      }
    }
  }

  handleClickToggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };

  render() {
    const { firstName } = this.state;

    return (
      <>
        <div className="full-height">
          <div className="d-flex justify-content-between bg-color p-2">
            <img src={Logo} className="header-logo" alt="logo" />
            <div className="loginInformation text-white">
              {`Welcome back, ${firstName ? firstName.toLowerCase() : ''}  !`}
            </div>
            <div
              className="d-flex align-items-center"
              style={{ color: 'white', zoom: '0.8' }}
            >
              <div className="mr-4">
                <Link to="/profile">
                  <img
                    src={profileImg}
                    alt="profile"
                    data-for="ProfileId"
                    data-tip="Profile"
                  />
                </Link>
              </div>
              {' '}
              <div
                role="presentation"
                className="text-white p-1 align-self-center cursor-pointer d-flex"
                onClick={() => Authorization.logout()}
              >
                <img className="mr-1" src={logoutImg} alt="logout" />
                <div>Logout</div>
              </div>
            </div>
            <ReactTooltip
              backgroundColor="grey"
              textColor="white"
              place="left"
              id="ProfileId"
            />
          </div>
        </div>
      </>
    );
  }
}
export default connect(state => ({
  getUserDetailForMyAdmin: state.getUserDetail,
}))(Header);
