import React from "react";
export const DriverIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100px"
    height="150px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
  >
    <path
      fill="#FFFFFF"
      stroke="#4099C9"
      strokeMiterlimit="10"
      d="M76.488,77.604c0,1.194-0.969,2.164-2.164,2.164H29.335
	c-1.195,0-2.165-0.97-2.165-2.164V22.44c0-1.195,0.969-2.165,2.165-2.165h44.989c1.195,0,2.164,0.969,2.164,2.165V77.604z"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="56.061"
      y1="31.229"
      x2="71.406"
      y2="31.229"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="56.061"
      y1="38.543"
      x2="71.406"
      y2="38.543"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="56.061"
      y1="45.285"
      x2="71.406"
      y2="45.285"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="33.975"
      y1="51.908"
      x2="71.406"
      y2="51.908"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="33.975"
      y1="59.338"
      x2="71.406"
      y2="59.338"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="33.975"
      y1="68.041"
      x2="71.406"
      y2="68.041"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="34.445"
      y1="74.714"
      x2="71.877"
      y2="74.714"
    />
    <g>
      <g>
        <g>
          <path
            fill="#4099C9"
            d="M50.427,43.125c-1.786-0.938-3.151-1.461-4.363-1.667l0.131-0.181c0.053-0.071,0.07-0.162,0.049-0.247
				l-0.277-1.105c0.742-1.043,1.178-2.347,1.178-3.583v-1.835c0.001,0,0.002-0.001,0.003-0.002c0.244-0.204,0.419-0.472,0.514-0.769
				l-0.305-0.083l-1.561-0.274c-2.296-0.414-4.592-0.414-6.823,0l-1.588,0.281l-0.28,0.07c0.093,0.299,0.268,0.569,0.515,0.775
				c0,0.001,0.002,0.002,0.003,0.003v1.834c0,1.236,0.438,2.54,1.178,3.583l-0.276,1.105c-0.021,0.085-0.003,0.176,0.048,0.247
				l0.132,0.181c-1.212,0.206-2.577,0.729-4.363,1.667c-0.545,0.287-0.883,0.849-0.883,1.465v1.272c0,0.165,0.132,0.298,0.297,0.298
				h17.258c0.164,0,0.297-0.133,0.297-0.298v-1.271C51.311,43.974,50.973,43.412,50.427,43.125z M41.132,43.783l-1.995-2.744
				l0.134-0.534c0.001,0.001,0.002,0.002,0.003,0.003c0.719,0.781,1.633,1.332,2.664,1.457c0,0,0,0,0.002,0L41.132,43.783z
				 M39.969,40.36c-0.075-0.07-0.15-0.138-0.222-0.214c0,0-0.001-0.001-0.002-0.002c-0.109-0.116-0.215-0.24-0.317-0.37
				c-0.009-0.012-0.022-0.018-0.033-0.028c-0.702-0.885-1.175-2.064-1.175-3.404v-1.517c0.007,0.002,0.014,0.003,0.021,0.005
				c0.027,0.008,0.055,0.013,0.082,0.02c0.033,0.007,0.064,0.015,0.098,0.02c0.029,0.005,0.06,0.007,0.089,0.01
				c0.031,0.003,0.062,0.008,0.093,0.009c0.039,0.002,0.078,0,0.117,0c0.022,0,0.045,0.001,0.067,0
				c0.063-0.004,0.125-0.011,0.188-0.022c2.231-0.413,4.527-0.413,6.823,0c0.087,0.016,0.174,0.021,0.261,0.023
				c0.009,0,0.019,0.002,0.028,0.002c0.01,0,0.019-0.002,0.027-0.002c0.149-0.002,0.296-0.022,0.437-0.064v1.517
				c0,1.34-0.472,2.519-1.174,3.404c-0.011,0.01-0.024,0.016-0.033,0.028c-0.103,0.13-0.208,0.254-0.317,0.37
				c0,0.001-0.002,0.002-0.003,0.002c-0.071,0.076-0.146,0.144-0.221,0.214c-0.727,0.656-1.591,1.041-2.416,1.041
				C41.561,41.402,40.696,41.015,39.969,40.36z M43.638,43.783l-0.808-1.817c0,0,0.001,0,0.002,0
				c1.03-0.125,1.945-0.676,2.663-1.457c0.001-0.001,0.003-0.002,0.004-0.003l0.134,0.534L43.638,43.783z"
          />
          <path
            fill="#4099C9"
            d="M38.869,32.792c2.298-0.426,4.666-0.426,7.032,0l1.585,0.28l0.25,0.067
				c-0.053-2.181-1.839-3.939-4.031-3.939h-2.641c-2.191,0-3.975,1.755-4.031,3.933l0.229-0.057L38.869,32.792z M41.492,30.391
				c0-0.165,0.132-0.298,0.298-0.298h1.189c0.165,0,0.297,0.133,0.297,0.298v1.487c0,0.165-0.132,0.298-0.297,0.298H41.79
				c-0.166,0-0.298-0.133-0.298-0.298V30.391z"
          />
          <rect
            x="42.087"
            y="30.688"
            fill="#4099C9"
            width="0.596"
            height="0.893"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const DealerIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100px"
    height="150px"
    viewBox="-18 -18 100 100"
    enableBackground="new -18 -18 100 100"
  >
    <path
      fill="#FFFFFF"
      stroke="#4099C9"
      strokeMiterlimit="10"
      d="M56.911,59.582c0,1.195-0.97,2.164-2.164,2.164H9.758
c-1.195,0-2.165-0.969-2.165-2.164V4.42c0-1.195,0.969-2.165,2.165-2.165h44.989c1.194,0,2.164,0.969,2.164,2.165V59.582z"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="36.482"
      y1="13.209"
      x2="51.829"
      y2="13.209"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="36.482"
      y1="20.523"
      x2="51.829"
      y2="20.523"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="36.482"
      y1="27.264"
      x2="51.829"
      y2="27.264"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.397"
      y1="33.888"
      x2="51.829"
      y2="33.888"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.397"
      y1="41.318"
      x2="51.829"
      y2="41.318"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.397"
      y1="50.021"
      x2="51.829"
      y2="50.021"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.867"
      y1="56.693"
      x2="52.299"
      y2="56.693"
    />
    <g>
      <path
        fill="#4099C9"
        d="M23.315,17.45c2.266,0,4.102-2.23,4.102-4.983c0-2.752-0.603-4.983-4.102-4.983s-4.103,2.231-4.103,4.983
 S21.049,17.45,23.315,17.45L23.315,17.45z"
      />
      <path
        fill="#4099C9"
        d="M31.056,25.867c-0.076-4.795-0.702-6.161-5.494-7.026c0,0-0.674,0.859-2.246,0.859
 c-1.572,0-2.247-0.859-2.247-0.859c-4.739,0.855-5.404,2.202-5.49,6.87c-0.008,0.381-0.011,0.401-0.013,0.357
 c0.001,0.083,0.001,0.237,0.001,0.504c0,0,1.141,2.299,7.749,2.299s7.749-2.299,7.749-2.299c0-0.172,0-0.291,0-0.373
 C31.062,26.226,31.06,26.173,31.056,25.867L31.056,25.867z"
      />
    </g>
  </svg>
);
export const VehicleIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100px"
    height="150px"
    viewBox="-18 -18 100 100"
    enableBackground="new -18 -18 100 100"
  >
    <path
      fill="#FFFFFF"
      stroke="#4099C9"
      strokeMiterlimit="10"
      d="M56.518,59.583c0,1.194-0.97,2.164-2.164,2.164H9.363
	c-1.195,0-2.165-0.97-2.165-2.164V4.42c0-1.195,0.969-2.165,2.165-2.165h44.989c1.195,0,2.164,0.969,2.164,2.165v55.163H56.518z"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="36.089"
      y1="13.209"
      x2="51.436"
      y2="13.209"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="36.089"
      y1="20.523"
      x2="51.436"
      y2="20.523"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="36.089"
      y1="27.264"
      x2="51.436"
      y2="27.264"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.003"
      y1="33.888"
      x2="51.436"
      y2="33.888"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.003"
      y1="41.318"
      x2="51.436"
      y2="41.318"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.003"
      y1="50.023"
      x2="51.436"
      y2="50.023"
    />
    <line
      fill="none"
      stroke="#4099C9"
      strokeMiterlimit="10"
      x1="14.472"
      y1="56.693"
      x2="51.904"
      y2="56.693"
    />
    <g>
      <path
        fill="#4099C9"
        d="M31.302,25.22c-0.006-0.688-0.039-1.375-0.101-2.061l-0.046-0.522c-0.071-0.808-0.749-1.427-1.561-1.427
		h-0.792v-3.361h0.756h0.601c0.209,0,0.379-0.169,0.379-0.378v-2.556c0-0.209-0.17-0.379-0.379-0.379h-0.601h-0.756v-1.515v-1.759
		c0-0.452,0.248-0.845,0.615-1.051V9.239c-0.898,0.173-1.577,0.963-1.577,1.913v1.869c-2.463-0.506-8.204-0.506-10.667,0v-1.869
		c0-0.95-0.679-1.74-1.578-1.913v0.972c0.367,0.207,0.615,0.6,0.615,1.051v1.759v1.515h-0.757h-0.6
		c-0.209,0-0.378,0.169-0.378,0.379v2.556c0,0.209,0.169,0.378,0.378,0.378h0.6h0.757v3.361h-0.792c-0.81,0-1.487,0.619-1.561,1.427
		l-0.046,0.522c-0.062,0.686-0.095,1.374-0.101,2.061c2.332,0.119,5.451,0.321,5.451,0.55h-5.45c0.008,0.64,0.039,1.279,0.095,1.917
		l0.053,0.595c0.018,0.203,0.188,0.359,0.392,0.359h0.534l0.18,2.051h2.146l-0.059-2.051h10.912l-0.061,2.051h2.147l0.179-2.051
		h0.533c0.204,0,0.374-0.156,0.392-0.359l0.052-0.595c0.057-0.638,0.087-1.278,0.096-1.917h-5.449
		C25.852,25.542,28.97,25.339,31.302,25.22z M28.802,14.915h0.756v2.556h-0.756V14.915z M28.439,22.236h1.957v1.009h-1.957V22.236z
		 M28.439,23.593h1.957v1.01h-1.957V23.593z M15.453,17.471v-2.556h0.757v2.556H15.453z M16.573,24.603h-1.957v-1.01h1.957V24.603z
		 M16.573,23.246h-1.957v-1.009h1.957V23.246z M16.202,27.601h-1.515v-0.758h1.515V27.601z M28.81,26.843h1.514v0.758H28.81V26.843z
		 M25.473,24.634h-0.916v-7.604H23.8v7.604h-0.915v-7.604h-0.758v7.604h-0.916v-7.604h-0.757v7.604H19.54v-7.353
		c-0.418,0-0.758,0.328-0.758,0.731v6.62h-0.757v-6.896c0-0.071,0.011-0.139,0.027-0.205h-0.075c-0.444,0-0.804-0.36-0.804-0.805
		v-2.792H27.84v2.792c0,0.444-0.361,0.805-0.805,0.805H26.96c0.017,0.066,0.027,0.134,0.027,0.205v6.896h-0.758v-6.62
		c0-0.404-0.339-0.731-0.757-0.731L25.473,24.634L25.473,24.634z"
      />
    </g>
  </svg>
);
export const MyIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100px"
    height="150px"
    viewBox="-18 -18 100 100"
    enableBackground="new -18 -18 100 100"
  >
    <g>
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        d="M50.551,46.677l-12.745-9.392c-0.227-0.167-0.526-0.2-0.783-0.085
		c-0.257,0.115-0.431,0.36-0.458,0.641c-0.37,3.944-1.96,7.529-3.642,10.326c-0.155,0.256-0.441,0.402-0.74,0.375
		c-0.298-0.026-0.555-0.221-0.66-0.501l-1.859-4.923c-0.098-0.259-0.051-0.549,0.121-0.764l1.409-1.76
		c0.245-0.306,0.225-0.747-0.047-1.03l-1.377-1.429c-0.147-0.153-0.351-0.239-0.563-0.239h-1.433c-0.212,0-0.415,0.086-0.562,0.239
		l-1.378,1.429c-0.272,0.283-0.292,0.724-0.047,1.03l1.409,1.76c0.172,0.215,0.218,0.505,0.121,0.764l-1.858,4.923
		c-0.105,0.28-0.362,0.476-0.661,0.503c-0.298,0.026-0.585-0.121-0.739-0.376c-1.683-2.796-3.273-6.383-3.643-10.327
		c-0.026-0.28-0.201-0.524-0.458-0.639s-0.555-0.082-0.782,0.085L6.433,46.677c-0.2,0.147-0.317,0.381-0.317,0.628v8.413
		c0,0.432,0.35,0.781,0.781,0.781h21.582h0.027h21.582c0.431,0,0.781-0.35,0.781-0.781v-8.413
		C50.867,47.059,50.75,46.825,50.551,46.677z"
      />
      <path
        fill="#FFFFFF"
        stroke="#FFFFFF"
        d="M18.241,24.338c0.233,0.141,0.377,0.395,0.377,0.668v3.209
		c0,0.248,0.118,0.481,0.317,0.628l2.987,2.207c0.199,0.147,0.317,0.38,0.317,0.628v2.3c0,0.275,0.146,0.531,0.382,0.672
		c3.252,1.936,8.021,1.936,11.272,0c0.236-0.141,0.382-0.396,0.382-0.672v-2.711c0-0.248,0.118-0.481,0.317-0.628l2.988-2.207
		c0.199-0.147,0.317-0.38,0.317-0.628v-3.209c0-0.273,0.143-0.527,0.376-0.668c1.156-0.701,2.031-2.594,2.031-4.554
		c0-1.809-0.745-3.107-1.768-3.296c-0.37-0.068-0.64-0.391-0.64-0.767v-3.258c0-5.886-4.472-8.21-10.035-8.046
		c-4.458,0.132-5.932,1.938-6.341,2.649c-0.103,0.178-0.271,0.309-0.47,0.363c-0.777,0.214-2.648,0.925-2.648,2.813
		c0,0.289,0.115,3.926,0.177,5.871c0.012,0.38-0.251,0.715-0.625,0.79c-1.011,0.205-1.745,1.497-1.745,3.292
		C16.21,21.744,17.085,23.638,18.241,24.338z"
      />
    </g>
  </svg>
);
export const CustomerIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100px"
    height="150px"
    viewBox="-18 -18 100 100"
    enableBackground="new -18 -18 100 100"
  >
    <g>
      <path
        fill="#FFFFFF"
        d="M37.028,26.667v-3.419c1.118-0.402,2.013-2.146,2.013-3.958s-0.895-3.013-2.013-2.735V13.17
		c0-4.919-3.738-6.863-8.387-6.725c-4.648,0.138-5.415,2.453-5.415,2.453s-2.311-0.082-2.311,1.911v6.089
		c-1.118-0.277-2.013,0.923-2.013,2.735c0,1.812,0.895,3.556,2.013,3.958v3.418l3.026,2.236v1.707h-0.02l-2.934,1.897v3.329
		c0.011,4.372,3.582,7.933,7.959,7.933c4.376,0,7.947-3.561,7.958-7.939v-3.355l-2.883-1.864h-0.02v-2.05L37.028,26.667z"
      />
      <path
        fill="#FFFFFF"
        d="M6.332,37.23c1.397,0,2.626-0.717,3.342-1.802h3.667v1.201c0.008,2.845,0.786,5.512,2.131,7.81
		l-3.279,2.293c-0.458-0.181-0.954-0.284-1.476-0.284c-2.21,0-4.002,1.793-4.002,4.004c0,2.209,1.792,4.002,4.002,4.002
		c2.211,0,4.003-1.793,4.003-4.002c0-0.039-0.005-0.075-0.005-0.112l3.496-2.447c2.297,2.182,5.253,3.675,8.536,4.142v3.826
		c-1.085,0.716-1.803,1.943-1.803,3.342c0,2.21,1.792,4.002,4.003,4.002c2.21,0,4.002-1.792,4.002-4.002
		c0-1.397-0.717-2.626-1.802-3.342v-3.826c3.28-0.467,6.237-1.959,8.535-4.144l3.496,2.449c-0.001,0.037-0.006,0.073-0.006,0.112
		c0,2.209,1.792,4.002,4.003,4.002c2.21,0,4.002-1.793,4.002-4.002c0-2.211-1.792-4.004-4.002-4.004
		c-0.521,0-1.019,0.104-1.476,0.284l-3.279-2.295c1.345-2.298,2.123-4.966,2.13-7.814v-1.195h3.667
		c0.716,1.085,1.945,1.802,3.342,1.802c2.211,0,4.003-1.792,4.003-4.003s-1.792-4.003-4.003-4.003c-1.396,0-2.626,0.717-3.342,1.802
		h-5.868v0.001H40.15v0.91v2.977v1.702c-0.016,6.161-5.042,11.174-11.204,11.174c-6.162,0-11.188-5.013-11.204-11.168v-1.675v-3.01
		v-0.91h-2.202v-0.001H9.673c-0.716-1.085-1.945-1.802-3.342-1.802c-2.21,0-4.003,1.792-4.003,4.003S4.121,37.23,6.332,37.23z"
      />
    </g>
  </svg>
);
export const VehiSummary = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="150px"
    height="172px"
    viewBox="-9 -13.1 100 100"
    enableBackground="new -9 -13.1 100 100"
  >
    <defs />
    <path
      fill="#FFFFFF"
      d="M84.7,41.9c-1.2-1.3-2.9-2.5-4-3.2c-0.2-2.3-0.8-5.1-2-7.7c-1.5-3.1-4.4-6.3-8.9-6.3h-8
 c-0.4,0.1-0.8,0.2-1.1,0.5c-0.3,0.3-0.5,0.7-0.5,1.1v20c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3V22.6c0-1.1-0.9-2-2-2H43.8
 C43.1,9.1,33.5,0,21.9,0C9.8,0,0,9.8,0,21.9c0,7.6,3.9,14.2,9.7,18.2v13.6c0,1.1,0.9,2,2,2h5.7c0.1-0.6,0.2-1.2,0.5-1.8
 c1.4-3.9,5.1-6.5,9.3-6.5c3.6,0,6.9,2,8.6,5.1c0.5,1,0.9,2.1,1.1,3.2c0,0,22.9,0,24.9,0c0.1-0.6,0.2-1.2,0.4-1.8
 c1.4-3.9,5.1-6.5,9.3-6.5c3.6,0,6.9,2,8.6,5.1c0.5,1,0.9,2,1.1,3.1c0.3,0,2.2,0,2.2,0c0.7,0,1.4-0.3,1.9-0.8
 c0.5-0.5,0.8-1.2,0.8-1.9v-8.2C86,43.4,85.3,42.6,84.7,41.9z M21.9,39.1c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2
 c9.5,0,17.2,7.7,17.2,17.2C39.1,31.4,31.4,39.1,21.9,39.1z M66,39.3v-9.4h3.7c1.8,0,3,1.1,4.2,3.3c0.9,1.9,1.4,4.3,1.5,6.1H66z"
    />
    <path
      fill="#FFFFFF"
      d="M24.5,21.9l-0.9-11.2c-0.1-0.8-0.7-1.5-1.5-1.5c-0.9-0.1-1.7,0.6-1.8,1.5l-0.8,10l-6.6,6
 c-0.6,0.5-0.7,1.4-0.3,2.1c0.5,0.8,1.5,1,2.3,0.5l8.7-5.4C24.2,23.6,24.6,22.8,24.5,21.9L24.5,21.9z"
    />
    <path
      fill="#FFFFFF"
      d="M27.1,50.1c-4,0-7.2,3.2-7.2,7.2c0,4,3.2,7.2,7.2,7.2c4,0,7.2-3.2,7.2-7.2C34.3,53.3,31.1,50.1,27.1,50.1z
  M27.1,60c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7S28.6,60,27.1,60z"
    />
    <path
      fill="#FFFFFF"
      d="M71.4,50.1c-4,0-7.2,3.2-7.2,7.2c0,4,3.2,7.2,7.2,7.2c4,0,7.2-3.2,7.2-7.2C78.6,53.3,75.4,50.1,71.4,50.1z
  M71.4,60c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7S72.9,60,71.4,60z"
    />
  </svg>
);
export const OverSpeed = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="150px"
    height="172px"
    viewBox="-2.074 -29.408 100 100"
    enableBackground="new -2.074 -29.408 100 100"
  >
    <defs />
    <path
      fill="#FFFFFF"
      d="M77.491,31.772c-3.277,0-5.938,2.659-5.938,5.936s2.661,5.936,5.938,5.936c3.281,0,5.936-2.659,5.936-5.936
	S80.772,31.772,77.491,31.772z M77.491,40.314c-1.435,0-2.601-1.169-2.601-2.605c0-1.438,1.166-2.603,2.601-2.603
	c1.438,0,2.605,1.165,2.605,2.603C80.096,39.145,78.929,40.314,77.491,40.314z"
    />
    <path
      fill="#FFFFFF"
      d="M31.183,31.772c-3.274,0-5.934,2.659-5.934,5.936s2.66,5.936,5.934,5.936c3.28,0,5.939-2.659,5.939-5.936
	S34.463,31.772,31.183,31.772z M31.173,40.314c-1.438,0-2.604-1.169-2.604-2.605c0-1.438,1.166-2.603,2.604-2.603
	s2.604,1.165,2.604,2.603C33.777,39.145,32.611,40.314,31.173,40.314z"
    />
    <path
      fill="#FFFFFF"
      d="M91.004,32.966h-1.158c0.047-4.575,0.077-11.071-0.105-12.639c-0.228-1.938-4.062-7.607-4.062-7.607
	c-2.873-4.952-3.67-6.008-6.938-6.008h-3.095h-3.357h-6.197v24.868H37.728c1.507,1.607,2.438,3.76,2.438,6.129
	c0,0.4-0.037,0.661-0.087,1.047h16.124h9.892h0.943h1.542c-0.041-0.344-0.066-0.691-0.066-1.047c0-4.949,4.029-8.977,8.979-8.977
	c4.951,0,8.978,4.028,8.978,8.977c0,0.218-0.018,0.432-0.032,0.646l4.568-0.023c1.484,0,2.683-1.201,2.683-2.683
	C93.688,34.167,92.488,32.966,91.004,32.966z M70.313,19.029V9.962h3.296h2.351h2.164c2.287,0,2.74,0.977,4.206,4.126
	c0,0,1.659,3.084,2.075,4.94H70.313z"
    />
    <path
      fill="#FFFFFF"
      d="M17.497,38.887h4.798c-0.053-0.387-0.088-0.779-0.088-1.179c0-2.37,0.928-4.522,2.435-6.129h-7.165
	L17.497,38.887z"
    />
    <path
      fill="#FFFFFF"
      d="M62.123,28.36l-0.036-25c0-1.849-1.513-3.361-3.36-3.361H20.764c-1.849,0-3.36,1.512-3.36,3.361l0.001,0.946
	L6.277,4.292c-1.347,0-2.439,1.085-2.439,2.433C3.834,7.757,4.473,8.639,5.379,8.993C5.653,9.102,5.954,9.165,6.27,9.161
	l11.148,0.022l0.008,3.219l-5.493-0.009c-1.347-0.001-2.438,1.089-2.443,2.435c0,1.032,0.64,1.913,1.545,2.271
	c0.275,0.108,0.575,0.168,0.888,0.171l5.516,0.006l0.008,3.302L2.441,20.555C1.092,20.555,0,21.644,0,22.988
	c-0.003,1.033,0.636,1.915,1.54,2.272c0.276,0.11,0.577,0.166,0.893,0.166l15.029,0.026l0.006,2.908H62.123z"
    />
  </svg>
);
export const VehiInstant = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="150px"
    height="172px"
    viewBox="0 0 150 172"
    enableBackground="new 0 0 150 172"
  >
    <g>
      <g>
        <path
          fill="#FFFFFF"
          d="M116.743,104.833c-17.693,0-32.341,14.396-32.341,32.09s14.395,32.341,32.091,32.341
			c17.694,0,32.341-14.646,32.341-32.341S134.438,104.833,116.743,104.833z M129.686,134.364l-16.337,22.48
			c-0.128,0.174-0.327,0.271-0.532,0.271c-0.085,0-0.169-0.017-0.251-0.05c-0.281-0.118-0.444-0.412-0.398-0.714l2.489-16.034
			l-8.517,1.595c-0.268,0.05-0.534-0.068-0.677-0.298c-0.144-0.23-0.133-0.526,0.025-0.745l16.886-23.191
			c0.178-0.245,0.5-0.337,0.779-0.223s0.446,0.406,0.403,0.707l-2.387,16.941l7.842-1.778c0.268-0.061,0.542,0.052,0.693,0.284
			C129.856,133.84,129.849,134.14,129.686,134.364z M54.957,65.064c0.062,0.474,0.483,0.817,0.959,0.817h12.082
			c0.531,0,0.962-0.432,0.962-0.964V40.804c0-0.532-0.431-0.964-0.962-0.964H40.582c-0.532,0-0.962,0.432-0.962,0.964v24.113
			c0,0.532,0.43,0.964,0.962,0.964h2.463c0.476,0,0.898-0.344,0.959-0.817c0.378-2.9,2.685-4.699,5.477-4.699
			C52.272,60.365,54.579,62.164,54.957,65.064z M85.738,65.881h1.019c0.531,0,0.963-0.432,0.963-0.964v-9.404
			c0-0.227-0.079-0.445-0.225-0.619l-7.847-9.404c-0.183-0.219-0.453-0.346-0.738-0.346h-7.063c-0.532,0-0.962,0.433-0.962,0.965
			v18.808c0,0.532,0.43,0.964,0.962,0.964h1.021c0.476,0,0.897-0.344,0.959-0.817c0.377-2.9,2.684-4.699,5.475-4.699
			c2.792,0,5.099,1.799,5.478,4.699C84.84,65.537,85.262,65.881,85.738,65.881z M82.016,53.824h-8.245
			c-0.531,0-0.962-0.432-0.962-0.964v-4.823c0-0.532,0.431-0.964,0.962-0.964h4.237c0.286,0,0.556,0.127,0.738,0.347l4.009,4.823
			C83.277,52.871,82.831,53.824,82.016,53.824z M18.599,149.222c-3.347,0-6.061-2.714-6.061-6.06V24.997
			c0-3.347,2.713-6.06,6.061-6.06h16.687v0.85c0,2.87,2.327,5.196,5.197,5.196h43.65c2.87,0,5.196-2.326,5.196-5.196v-0.85h16.357
			c3.347,0,6.06,2.713,6.06,6.06v73.012c2.827-0.62,5.757-0.963,8.762-0.999V17.868c0-4.592-3.723-8.314-8.314-8.314H89.329V7.475
			c0-2.87-2.326-5.196-5.196-5.196h-43.65c-2.87,0-5.197,2.327-5.197,5.196v2.079H12.421c-4.592,0-8.314,3.722-8.314,8.314v131.094
			c0,4.593,3.722,8.314,8.314,8.314h69.027c-1.113-2.565-1.983-5.26-2.583-8.055H18.599z M79.302,62.184
			c-2.255,0-4.088,1.839-4.088,4.099c0,2.26,1.833,4.099,4.088,4.099c2.254,0,4.089-1.839,4.089-4.099
			C83.391,64.023,81.556,62.184,79.302,62.184z M79.302,67.488c-0.663,0-1.202-0.541-1.202-1.206s0.539-1.206,1.202-1.206
			s1.203,0.541,1.203,1.206S79.965,67.488,79.302,67.488z M51.31,114.864c-2.216,0-4.013,1.798-4.013,4.014s1.796,4.012,4.013,4.012
			h16.975c2.216,0,4.013-1.796,4.013-4.012s-1.796-4.014-4.013-4.014H51.31z M103.476,88.592c0-2.215-1.796-4.012-4.013-4.012H51.31
			c-2.216,0-4.013,1.797-4.013,4.012c0,2.216,1.796,4.015,4.013,4.015h48.153C101.68,92.606,103.476,90.808,103.476,88.592z
			 M49.48,70.382c2.254,0,4.088-1.839,4.088-4.099c0-2.26-1.834-4.099-4.088-4.099c-2.254,0-4.088,1.839-4.088,4.099
			C45.392,68.543,47.226,70.382,49.48,70.382z M49.48,65.077c0.663,0,1.203,0.541,1.203,1.206s-0.54,1.206-1.203,1.206
			s-1.203-0.541-1.203-1.206S48.817,65.077,49.48,65.077z"
        />
      </g>
    </g>
  </svg>
);
export const AlertReport = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="150px"
    height="172px"
    viewBox="-16 -6 100 100"
    enableBackground="new -16 -6 100 100"
  >
    <defs />
    <rect x="23.4" y="37.1" fill="#FFFFFF" width="24.1" height="3.6" />
    <rect x="11.3" y="35.2" fill="#FFFFFF" width="6.5" height="6.8" />
    <rect x="23.4" y="24.2" fill="#FFFFFF" width="24.1" height="3.6" />
    <rect x="11.3" y="22.4" fill="#FFFFFF" width="6.5" height="6.8" />
    <path
      fill="#FFFFFF"
      d="M40.2,3.9h-5.5C34.5,1.7,31.3,0,29,0c-2.3,0-5.5,1.7-5.7,3.9h-5.5c-1.7,0-3.2,1.4-3.2,3.2v1.2v5.4h28.7V8.3
 V7.1C43.4,5.3,42,3.9,40.2,3.9z M29,6.8c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4
 C31.3,5.8,30.3,6.8,29,6.8z"
    />
    <path
      fill="#FFFFFF"
      d="M59.2,47.4V13.9c0-3.6-3-6.5-6.7-6.5h-5.1v1.9l0,1.4h1.5c2.7,0,5,2.2,5,5v31.2c0.2,0,0.3,0,0.5,0
 C56,46.9,57.6,47.1,59.2,47.4z"
    />
    <path
      fill="#FFFFFF"
      d="M31.2,68.9h-8.1V50.8H5.2V15.7c0-2.7,2.2-5,5-5h0.4V7.4H6.7C3,7.4,0,10.3,0,13.9v54.8c0,3.6,3,6.5,6.7,6.5
 h25.1c-0.4-1.7-0.6-3.4-0.6-5.1C31.2,69.7,31.2,69.3,31.2,68.9z M19.2,68.4L5.5,54.7h13.8V68.4z"
    />
    <path
      fill="#FFFFFF"
      d="M54.4,50.9c-10.6,0-19.2,8.6-19.2,19.2c0,10.6,8.6,19.2,19.2,19.2s19.2-8.6,19.2-19.2
 C73.5,59.5,64.9,50.9,54.4,50.9z M54.7,83.3c-1.2,0-2.3-1-2.3-2.3c0-1.2,1-2.3,2.3-2.3c1.2,0,2.3,1,2.3,2.3
 C57,82.2,56,83.3,54.7,83.3z M59,61.9l-3.2,13.7c-0.1,0.5-0.6,0.9-1.1,0.9s-1-0.4-1.1-0.9l-3.2-13.7c-0.1-0.4-0.2-0.8-0.2-1.3
 c0-2.5,2-4.5,4.5-4.5s4.5,2,4.5,4.5C59.2,61.1,59.2,61.5,59,61.9z"
    />
  </svg>
);
