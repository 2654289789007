/* eslint-disable */
import React from 'react';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';

class FMTVehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedVehicles: props.checkedVehicles,
    };
  }

  handleCheckBoxVehicles = (type, e) => {
    const { checkedVehicles, fields } = this.props;
    if (
      e.target.checked &&
      e.target.value &&
      !checkedVehicles.includes(e.target.value) &&
      checkedVehicles.includes('all')
    ) {
      checkedVehicles.push(e.target.value);
      checkedVehicles.splice(checkedVehicles.indexOf('all'), 1);
    } else if (
      checkedVehicles.includes(e.target.value) &&
      !checkedVehicles.includes('all')
    ) {
      checkedVehicles.splice(checkedVehicles.indexOf(e.target.value), 1);
    } else if (e.target.value === 'all' && !checkedVehicles.includes('all')) {
      checkedVehicles.length = 0;
      checkedVehicles.push('all');
    } else if (!checkedVehicles.includes('all')) {
      checkedVehicles.push(e.target.value);
    }

    this.setState(
      {
        checked: e.target.name,
      },
      () => {
        this.props.searchResults(checkedVehicles, this.state.checked);
      }
    );
  };

  handleChangeTruck = (str) => {
    const val = str.target.value.toUpperCase();
    this.props.searchVehicle(val);
  };

  clearBtnSearch = () => {
    this.props.clearBtnSearch();
  };

  filterApplied = () => {
    this.props.filterApplied();
  };

  handleFocus = () => {
    this.props.handleFocus();
  };

  onKeyUp = (e) => {
    e.preventDefault();
  };

  render() {
    const {
      disableSearch,
      checkedVehicles,
      myTruck,
      inputFocused,
      vehicleData,
      isExpand,
    } = this.props;

    return (
      <div className="position-relative">
        <>
          <Form
            className={classnames('m-2', {
              'mt-4': !isExpand,
            })}
            onSubmit={this.onKeyUp}
          >
            <Form.Control
              disabled={disableSearch}
              style={
                disableSearch
                  ? {
                      cursor: 'not-allowed',
                    }
                  : {}
              }
              placeholder={
                checkedVehicles && checkedVehicles.length == 0
                  ? 'Search truck'
                  : checkedVehicles.length + ' ' + 'Selected'
              }
              className="bg-transparent font-italic fmt-search search-input search-icon"
              onClick={this.handleFocus}
              onChange={this.handleChangeTruck}
              value={myTruck}
            />
          </Form>
        </>

        {inputFocused ? (
          !disableSearch && vehicleData && vehicleData.length > 0 ? (
            <div className="group-drop-fmt-search">
              <div className="search-scroll-div">
                {vehicleData.map((vehicle, index) => {
                  return (
                    <div key={index} className="each-search-tile">
                      <label className="dropdown-container">
                        {vehicle.registrationId}
                        <input
                          type="checkbox"
                          name={vehicle.vehicleId}
                          value={vehicle.vehicleId}
                          defaultChecked={
                            this.state.checked === vehicle.vehicleId
                          }
                          checked={checkedVehicles.includes(
                            vehicle.vehicleId.toString()
                          )}
                          onChange={(e) => {
                            this.handleCheckBoxVehicles(
                              vehicle.registrationId,
                              e
                            );
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-around text-center">
                <div className="search-btn-fmt" onClick={this.filterApplied}>
                  OK
                </div>
                <div className="search-btn-fmt" onClick={this.clearBtnSearch}>
                  CLEAR
                </div>
              </div>
            </div>
          ) : (
            <div className="group-drop-fmt-search">
              <div className="search-scroll-div">
                <div className="noTextFoundFmt">No Vehicles Found</div>
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }
}

export default FMTVehicleList;
