import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

export function NotFound() {
  return (
    <div>
      <Header />
      <h1>Component Not Found</h1>
      <Footer />
    </div>
  );
}

export function NotAuthroized() {
  return (
    <div>
      <Header />
      <h1>Access denied - You are not authorized to access this page</h1>
      <Footer />
    </div>
  );
}
