/* eslint-disable max-len */
const privacyInfo = {
  data: [
    {
      num: 1,
      title: 'Usage/ log Information',
      info:
        'We collect usage information such as IP address, GPS location, the web browser type, operating system, address of the reference website or the website to which you exit to, date and time you access our website or services, items you click on, pages you visit and time spent on each page.',
    },
    {
      num: 2,
      title:
        'Cookies, other tracking technologies, external links and online Advertising',
      info:
        "We use cookies and similar technologies to provide, protect and improve our products and services such as by personalizing content, offering, understanding user behavior and providing safer experiences. Cookies are small pieces of data sent from a website and stored on a user's web browser. When the user browses the same website in the future, the cookie notifies the website of the user's previous activity. Cookies do not harm your computer in any way, and some are automatically deleted after a certain period of time. You can remove or reject cookies using your browser or device settings, but in some cases doing so may affect your ability to use our products and services.",
    },
    {
      num: 3,
      title: 'Links to Other third party websites/Online Advertising',
      info:
        'Please be aware that while you use our site, you may be sent to other third party website if you click on a hyperlink. These other third party websites may send their own cookies to visitors to collect data or solicit Personal Information. The privacy policies of these other third party websites may be significantly different from this Privacy Policy. DICV shall not responsible for the privacy practices of these other third party websites and cannot guarantee the security of any of your Personal Information collected there.',
    },
    {
      num: 4,
      title: ' Sign up and Account information',
      info: `When you subscribe and sign-up to any of our products/ service(s)/ trials, we may collect your (i) contact information such as name, e-mail address, mailing address, IP address, geographic location, or phone number of user; (ii) name and e-mail address when user provide feedback from within the service(s); and (iii) unique identifiers, such as username, account number or password. 
            <br/> <br/> Subject to this notice and the terms, we will use such data, including without limitation, to <br/>
             
             (i)grant software license and other service(s); <br/>
             (ii)send you communication from the service(s);  <br/>
             (iii)send you requested information about the service(s);  <br/>
             (iv)respond to customer service requests, questions and concerns;  <br/>
             (v)administer your Account; 

             `,
    },
    {
      num: 5,
      title: 'Personal Information',
      info: `We collect your Personal Information that you provide to us when accessing or using our services. “Personal Information” includes your name, address, telephone number, email address and information that you provide when you (a) create or update your Truckonnect  account, (b) communicate with us, (c) purchase services/products from us. 
        <br/> If you are a user and want to update your profile (“Profile”), or post information using your Profile, we may also collect verification information. We may collect demographic information about you, products/services you like, products you intend to purchase in, or any other information provided by you during the use of our website. We might collect this as a part of a survey also. If you believe we have inadvertently collected such information, please contact the grievance officer at the email id <a href="mailto:dicv_grievanceofficer.truckonnect@daimler.com"> dicv_grievanceofficer.truckonnect@daimler.com </a>, so that we can promptly obtain parental consent or remove the information.
        `,
    },
    {
      num: 6,
      title: 'Use of Non-Personal Information',
      info:
        'We may use information other than Personal Information, including aggregated or anonymous information, for ad targeting, to analyze trends, administer our services, improve customer service, diagnose problems with our servers, track user movement, and gather demographic information for aggregate/analytics/statistics use. ',
    },
    {
      num: 7,
      title: 'Use of Personal Information',
      info:
        'We use Personal Information to facilitate your use of the services, to process your requests or transactions, to provide you with information, products and services you request, to administer and assist us with the operation of our business, and for the purpose for which the information was provided. We may use the information we collect to send you news relevant to you or in accordance with your preferences. We may use information you provide to send you about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products. We do not disclose, rent, sell or otherwise make available your Personal Information to third parties for direct marketing purposes.',
    },
    {
      num: 8,
      title: 'Text Messaging',
      info: `We do not ordinarily send text messages for marketing purposes, but to the extent you provide us with your mobile telephone number, you consent to the transmission of text messages to such number.<br/>
        We take reasonable steps to secure your personally identifiable information against unauthorized access or disclosure. However, no security or encryption method can be guaranteed to protect information from hackers or human error.<br/>
        We may use information to protect our company, our customers, or our websites. We may share information with any successor to all or part of our business. For example, if part of our business is sold we may give our customer list as part of that transaction.<br/>
        
        We use information as otherwise permitted by law. We may share your information any Authority, statutory or otherwise, including but not limited to, any security agencies and reserves the right to comply with the directions of such authorities at its discretion and without intimation. <br/>
If you wish to terminate your account please write to us at <a href="mailto:dicv_truckonnecthelpdesk@daimler.com">dicv_truckonnecthelpdesk@daimler.com.</a>  If you have any questions or concerns about our privacy policies, please send an email to <a href="mailto:dicv_grievanceofficer.truckonnect@daimler.com"> dicv_grievanceofficer.truckonnect@daimler.com</a>
`,
    },
    {
      num: 9,
      title: 'Data Retention',
      info:
        'We receive and store any information you enter on our website or give us in any other way.  We do not retain personal information longer than is necessary to fulfill the purposes for which it was collected and to maintain reasonable business records. DICV shall dispose the Personal Information once it has served its intended purpose or as specified by the data subject. ',
    },
    {
      num: 10,
      title: 'Security',
      info: `We have adopted reasonable security practices and procedures to ensure that the Personal Information is collected and preserved in a secured manner. In case the user wishes to know more details about the adopted reasonable security practices and procedures, they may contact the grievance officer for the same. <br/>
        While we will endeavor to take all reasonable and appropriate steps to keep secured any information and prevent its unauthorized access, the user agrees and acknowledges that the Company cannot provide any absolute assurance regarding the security of Personal Information. To the fullest extent permissible under applicable laws, DICV disclaims any liability in relation to any breach of security or loss or disclosure of information in relation to the Personal Information.
        <br/> If the user needs to access, update or correct the Personal Information, he/she may contact the grievance officer for the same.
        `,
    },
    {
      num: 11,
      title: 'Intellectual Property',
      info:
        'All title, ownership rights and intellectual property rights in and relating to this website or web page including but not limited to copyrights, logos, names, trademarks, service marks, design, text, images, links, concepts and themes are owned by DICV and/or its group companies collectively or used under authorized license by the DICV. Any reproduction, transmission, publication, performance, broadcast, alteration, license, creation of derivative works or any unauthorized use in total or in part and in any manner without the prior written consent of the DICV is strictly prohibited.',
    },
    {
      num: 12,
      title: 'Changes to this Privacy Policy',
      info: `DICV reserves its right to revise and update this Privacy Policy at any time and the same will be notified to the user. Any such revisions will be effective on and from the date of posting the same to the intranet/internet website of the Company, and will apply to all information collected both prior to and following the effective date. Please review the Privacy Policy from time to time.
        <br/> <em>Please note: Use of website /intranet following any such revisions will be deemed acceptance by the user of such revisions.</em>
        `,
    },
    {
      num: 13,
      title: 'Grievance',
      info:
        'The user may approach the grievance officer if they have any grievance, questions or concerns with respect to the processing and use of their Personal Information. The grievance officer can be contacted by mail at the email id <a href="mailto:dicv_grievanceofficer.truckonnect@daimler.com">dicv_grievanceofficer.truckonnect@daimler.com.</a>',
    },
  ],
};

module.exports = privacyInfo;
