import {
  PENDING,
  FULFILLED,
  REJECTED,
  fmtVehicleUrl,
  tripTrackUrl,
  routePlayBackUrl,
  fmtSearch,
  geoFenceEditUrl,
  deleteGeofenceUrl,
  submitGeofenceUrl,
  showAll,
  vehicleDetailUrl,
  fleetRegionUrl,
  geoFenceSearchUrl,
  liveNotificationUrl,
  geoFenceRegionUrl,
  getVehiclesUrl,
  alertFMTUrl,
} from '../../Helpers/Constants';
import Authorization from '../../Helpers/Authorization';

const userId = Authorization.getAuthUserId();

export function getVehicles() {
  return {
    types: [
      `GET_VEHICLES_${PENDING}`,
      `GET_VEHICLES_${FULFILLED}`,
      `GET_VEHICLES_${REJECTED}`,
    ],
    method: 'GET',
    url: fmtVehicleUrl,
    payload: {},
  };
}

export function plotLiveTracking(vehicleId) {
  return {
    types: [
      `PLOT_LIVE_TRACK_${PENDING}`,
      `PLOT_LIVE_TRACK_${FULFILLED}`,
      `PLOT_LIVE_TRACK_${REJECTED}`,
    ],
    method: 'GET',
    url: tripTrackUrl,

    queryParams: { vehicleId },
    payload: {},
  };
}

export function routePlayBack(data) {
  let param = data;
  param = {
    vehicleId: data.vehicleId,
    fromDate: data.startDate,
    toDate: data.endDate,
    telemetryEnabled: true,
  };

  return {
    types: [
      `PLOT_ROUTE_PLAY_BACK_${PENDING}`,
      `PLOT_ROUTE_PLAY_BACK_${FULFILLED}`,
      `PLOT_ROUTE_PLAY_BACK_${REJECTED}`,
    ],
    method: 'POST',
    url: routePlayBackUrl,
    payload: {},
    data: param,
  };
}

export function getFilterFMTData(data) {
  return {
    types: [
      `FILTER_FMT_${PENDING}`,
      `FILTER_FMT_${FULFILLED}`,
      `FILTER_FMT_${REJECTED}`,
    ],
    method: 'POST',
    url: fmtSearch,

    payload: {},
    data,
  };
}

export function getVehicleInfofmt(data) {
  return {
    types: [
      `VEHICLE_INFO_FMT_${PENDING}`,
      `VEHICLE_INFO_FMT_${FULFILLED}`,
      `VEHICLE_INFO_FMT_${REJECTED}`,
    ],
    method: 'POST',
    url: vehicleDetailUrl,

    payload: {},
    data,
  };
}

export function getgrprgn() {
  return {
    types: [
      `GET_FMT_GR_${PENDING}`,
      `GET_FMT_GR_${FULFILLED}`,
      `GET_FMT_GR_${REJECTED}`,
    ],
    method: 'GET',
    url: fleetRegionUrl,
    queryParams: { userId },
    payload: {},
  };
}

export function getGeofenceSearchList() {
  return {
    types: [
      `GEOFENCE_SEARCH_LIST_${PENDING}`,
      `GEOFENCE_SEARCH_LIST_${FULFILLED}`,
      `GEOFENCE_SEARCH_LIST_${REJECTED}`,
    ],
    method: 'GET',
    url: geoFenceSearchUrl,
    queryParams: { userId },
    payload: {},
  };
}

export function geofenceEditDetails(geoId) {
  return {
    types: [
      `GEOFENCE_EDIT_DETAILS_${PENDING}`,
      `GEOFENCE_EDIT_DETAILS_${FULFILLED}`,
      `GEOFENCE_EDIT_DETAILS_${REJECTED}`,
    ],
    method: 'GET',
    url: geoFenceEditUrl,
    queryParams: { userId, 'geofence-id': geoId },
    payload: {},
  };
}

export function getLiveNotification(data) {
  return {
    types: [
      `LIVE_NOTIFICATION_${PENDING}`,
      `LIVE_NOTIFICATION_${FULFILLED}`,
      `LIVE_NOTIFICATION_${REJECTED}`,
    ],
    method: 'POST',
    url: liveNotificationUrl,

    payload: {},
    data,
  };
}

export function showAllTrucks() {
  return {
    types: [
      `SHOW_ALL_TRUCKS_${PENDING}`,
      `SHOW_ALL_TRUCKS_${FULFILLED}`,
      `SHOW_ALL_TRUCKS_${REJECTED}`,
    ],
    method: 'GET',
    url: showAll,
    payload: {},
  };
}

export function getActiveVehicles(data) {
  return {
    types: [
      `GET_ACTIVE_VEHICLES_${PENDING}`,
      `GET_ACTIVE_VEHICLES_${FULFILLED}`,
      `GET_ACTIVE_VEHICLES_${REJECTED}`,
    ],
    method: 'GET',
    url: getVehiclesUrl,
    queryParams: {
      userId,
    },
    payload: {},
    data,
  };
}

export function deleteGeoFenceNewPortal(data) {
  return {
    types: [
      `DELETE_GEOFENCE_NEW_${PENDING}`,
      `DELETE_GEOFENCE_NEW_${FULFILLED}`,
      `DELETE_GEOFENCE_NEW_${REJECTED}`,
    ],
    method: 'POST',
    url: deleteGeofenceUrl,
    payload: {},
    data,
  };
}

export function getGeofenceDropdownList() {
  return {
    types: [
      `GET_GEOFENCE_DROPDOWN_${PENDING}`,
      `GET_GEOFENCE_DROPDOWN_${FULFILLED}`,
      `GET_GEOFENCE_DROPDOWN_${REJECTED}`,
    ],
    method: 'GET',
    url: geoFenceRegionUrl,
    queryParams: {
      userId,
    },
    payload: {},
  };
}

export function submitGeofenceNew(data) {
  return {
    types: [
      `SUBMIT_GEO_NEW_${PENDING}`,
      `SUBMIT_GEO_NEW_${FULFILLED}`,
      `SUBMIT_GEO_NEW_${REJECTED}`,
    ],
    method: 'POST',
    url: submitGeofenceUrl,
    payload: {},
    data,
  };
}

export function alertsPlayBack(data) {
  let param = data;
  param = {
    vehicleId: data.vehicleId,
    fromDate: data.startDate,
    toDate: data.endDate,
    telemetryEnabled: true,
  };

  return {
    types: [
      `PLOT_ALERTS_FMT_${PENDING}`,
      `PLOT_ALERTS_FMT_${FULFILLED}`,
      `PLOT_ALERTS_FMT_${REJECTED}`,
    ],
    method: 'POST',
    url: alertFMTUrl,
    payload: {},
    data: param,
  };
}
