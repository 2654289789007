import {
  VehicleSummaryIcon,
  OverspeedIcon,
  AlertsIcon,
  vehicleInstantIcon,
  EngineOverRunIcon,
  NeutralDrivingIcon,
  FuelConsumptionIcon,
} from '../../assets/images/svg/reportIcon';

const ReportBarSideMenu = [
  {
    name: 'Vehicle Summary',
    icon: VehicleSummaryIcon,
    path: '/reports/vehicle-summary',
  },
  {
    name: 'Over Speed',
    icon: OverspeedIcon,
    path: '/reports/over-speed',
  },
  // {
  //   name: "CAN Signal",
  //   icon: OverspeedIcon,
  //   path: "/reports/can-signal"
  // },
  {
    name: 'Fuel Consumption',
    icon: FuelConsumptionIcon,
    path: '/reports/fuel-consumption',
  },
  {
    name: 'Alerts',
    icon: AlertsIcon,
    path: '/reports/alert',
  },
  {
    name: 'Vehicle Instant',
    icon: vehicleInstantIcon,
    path: '/reports/vehicle-instant',
  },
  {
    name: 'Engine Over Run',
    icon: EngineOverRunIcon,
    path: '/reports/engine-overrun',
  },
  {
    name: 'Neutral Driving',
    icon: NeutralDrivingIcon,
    path: '/reports/neutral-driving',
  },
];

export default ReportBarSideMenu;
