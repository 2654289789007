import React from 'react';

const AlertInfoWindow = props => {
  const { alertName, alertTime, location } = props;

  return (
    <div className="maps-popup">
      <div className="tempo">
        <div className="reponsive-popup">
          <div className="truck-info">
            <div className="eventDetails">
              <div className="mb-3">
                <div className="mb-1 head">{alertName}</div>
                <div className="mb-1 head">{alertTime}</div>
              </div>
              <div>
                <div className="locationAlert info-bg-location">{location}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertInfoWindow;
