/* eslint-disable */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import moment from 'moment';
import toastr from 'toastr';
import w3wImage from '../../../assets/images/New-w3w-icon.png';
import isEqual from 'react-fast-compare';
import { addDays, subDays, parseISO } from 'date-fns';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import back_button from '../../../assets/images/back_button.png';
import cluster_fmt_light from '../../../assets/images/cluster_fmt_new.svg';
import LiveInfoWindow from './LiveInfoWindow';
import FleetDatePicker from './FleetDatePicker';
import Authorization from '../../../Helpers/Authorization';
import SpeedControls from './SpeedControls';
import Env from '../../../Helpers/env';
import truck_running from '../../../assets/images/fmt_status/truck_running_new.png';
import satelliteIcon from '../../../assets/images/sate002.png';
import mapIcon from '../../../assets/images/map-003.png';
import { mapStyles } from '../../../Helpers/Constants';
import LayoutContainer from './LayoutContainer';
import locationMarker from '../../../assets/images/map_icon.png';
import cluster_icon_events from '../../../assets/images/cluster_icon_events.png';
import alert_cluster from '../../../assets/images/alert_cluster.png';
import events from '../../../assets/images/events.png';
import singleEvent from '../../../assets/images/singleEvent.png';
import eventsT from '../../../assets/images/eventsT.png';
import AlertWindow from './AlertWindow';
import okImg from '../../../assets/images/okay_geo.png';
import noImg from '../../../assets/images/close-icon_geo.png';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../Common/loader';
import classnames from 'classnames';
import { calculateDelay } from '../../../Helpers/Constants';
import FleetNoData from './FleetNoData';
import AlertInfoWindow from './AlertInfoWindow';

import {
  togglePopUp,
  showTripDetails,
  Event,
  showVehicleDetails,
  geocoderAddressFormater,
} from '../../../Helpers/Utility';

const userId = Authorization.getAuthUserId();

let map;
let infowindow;
let apiTime = 0;

let selectedMarker = {};
// Store the latlng cords to draw polyline
let liveLocationCoords = [];
let polyline = [];

export default class LiveRightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markerCluster: [],
      eventMarkerCluster: [],
      alertsMarkerCluster: [],
      alertMarker: [],
      eventMarker: [],
      marker: [],
      data: [],
      geofenceStatus: '',
      apiT: '',
      vdata: {},
      isLoadingLocal: false,
      playbackOption: '',
      selectedSpeed: 1,
      finalCircle: [],
      selectedFields: { playBackVehicleID: 0 },
      finalPolygon: [],
      emDate: new Date(),
      emxDate: new Date(),
      startDate: subDays(new Date(), 1),
      endDate: new Date(),
      emTime: 0,
      emxTime: new Date().getHours(),
      mTime: 0,
      mxTime: new Date().getHours(),
      registrationId: '',
      isLoading: false,
      alertMarkers: [],
      isBtnActive: true,
      isAlertActive: false,
      hidePanel: false,
      isLightThemeActive: true,
      showGenerateLive: false,
      mapTrigger: false,
      clickedImei: 0,
    };
    this.route = [];
    this.eventRoutePlayBack = null;
    this.currentPolyLine = null;
    this.count = 0;
    this.finalPolyline = null;
    this.initialMaker = null;
    this.finalMarker = null;
    this.currentMarker = null;
    this.gridMarker = null;
    this.speed = 150000;
    this.delay = 2000;
    this.lastPlaybackvalueTime = 0;
    this.coordinatesCoveredByPlayBack = -1;
    this.completeplaybackData = [];
    this.currentAdl = null;
    this.currentFuelLevel = null;
    this.currentOdo = null;
    this.initMap = this.initMap.bind(this);
  }

  componentDidMount() {
    if (window.google && window.google.maps) {
      this.initMap();
    } else {
      window.addEventListener('load', this.initMap);
    }
    this.clearMarkers();
    if (this.state.isAlertActive) {
      this.clearAlertMarkers();
      this.props.alertsToPlot();
    }
    this.props.getDates(this.state.startDate, this.state.endDate);
    return () => {
      window.removeEventListener('load', this.initMap);
    };
  }

  addEventListenerGeoFenceEdit(infowindow, geoFenceId, geofenceDetail) {
    window.google.maps.event.addListener(infowindow, 'domready', () => {
      document.querySelector(`#geofence_edit${geoFenceId}`) &&
        document
          .querySelector(`#geofence_edit${geoFenceId}`)
          .addEventListener('click', (e) => {
            e.preventDefault();
            this.contentEdit(geofenceDetail);
          });
    });
  }

  addEventListenerGeoFenceCancel(infowindow) {
    window.google.maps.event.addListener(infowindow, 'domready', () => {
      document.querySelector('#geo_del_cancel') &&
        document
          .querySelector('#geo_del_cancel')
          .addEventListener('click', (e) => {
            e.preventDefault();
            document.getElementById('geofence_delete_pop').style.display =
              'none';
          });
    });
  }

  addEventListenerGeoFenceDelete(infowindow, geoFenceId) {
    window.google.maps.event.addListener(infowindow, 'domready', () => {
      document.querySelector(`#geofence_delete${geoFenceId}`) &&
        document
          .querySelector(`#geofence_delete${geoFenceId}`)
          .addEventListener('click', (e) => {
            e.preventDefault();

            document.getElementById('geofence_delete_pop').style.display =
              'block';
            document.getElementById(
              'geofence_delete_pop'
            ).style.backgroundColor = '#FCD1D1';
            document.getElementById('geofence_delete_pop').style.borderRadius =
              '4px';
            document.getElementById('geofence_delete_pop').style.padding =
              '0.5rem';
            document.getElementById('geofence_delete_pop').style.border =
              '2px solid #D43434';
          });
    });
  }

  deleteRightPanelFunc = (id) => {
    this.props.deleteGeofenceFunc(id);
  };

  addEventListenerGeoFenceDeletePopUp(infowindow, geoFenceId, geofenceDetail) {
    window.google.maps.event.addListener(infowindow, 'domready', () => {
      document.querySelector('#geo_del_ok') &&
        document.querySelector('#geo_del_ok').addEventListener('click', (e) => {
          e.preventDefault();
          this.removeShapes();
          this.deleteRightPanelFunc(geoFenceId);
          this.contentShowPop(geoFenceId, geofenceDetail);
        });
    });
  }

  contentShowPop = (id, detail) => {};

  contentClick = (geoFenceId) => {
    this.props.deleteGeofence(geoFenceId);
  };

  contentEdit = (geofenceDetail) => {
    this.props.togglePopupstatus(true, geofenceDetail, true);
    this.props.editGeoApi(geofenceDetail);
  };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        prevProps.handlePlayBackChanges,
        this.props.handlePlayBackChanges
      )
    ) {
      this.setState({ playbackOption: this.props.handlePlayBackChanges });
    }

    if (!isEqual(prevProps.selectedVehicelId, this.props.selectedVehicelId)) {
      const fields = this.state.selectedFields;

      fields.playBackVehicleID = this.props.selectedVehicelId;
      fields.playBackVehicleID = this.props.selectedVehicelId;
      this.setState({ selectedFields: fields });
    }
    // if (!isEqual(prevProps.plotAlerts, this.state.isAlertActive)) {
    //   this.clearAlertMarkers();
    //   this.props.alertsToPlot();
    // }
  }

  changeGeofenceStatus(status) {
    this.setState({ geofenceStatus: status });
  }

  startPlaybackk = (vehicleId) => {
    if (infowindow) {
      infowindow.close();
    }
    this.delay = 2000;
    this.setState({ selectedVehicelId: vehicleId, selectedSpeed: 1 }, () => {
      this.setRegIdForInfoWindow();
    });
    this.props.getPlayBack(true, true, vehicleId);
  };

  setRegIdForInfoWindow = () => {
    this.setState({ playbackRegId: this.state.playbackRegId });
  };

  addEventListenerAlertInfoWindow = (infowindow) => {
    window.google.maps.event.addListener(infowindow, 'domready', () => {
      document.querySelector('#alertclose') &&
        document.querySelector('#alertclose').addEventListener('click', (e) => {
          if (infowindow) {
            infowindow.close();
          }
        });
    });
  };

  addEventListenerInfoWindowForTrip = (infowindow) => {
    window.google.maps.event.addListener(infowindow, 'domready', () => {
      let loc = {
        lat: this.state.clickedLat,
        lng: this.state.clickedLong,
      };

      const geocoder = new window.google.maps.Geocoder();
      let address = document.querySelector('#geoAddress');
      let vehicleDetails = document.querySelector('.vehicleDetails');
      let driverDetails = document.querySelector('#driverDetails');
      let showDetails = document.querySelector('#view-details');
      let closeInfo = document.querySelector('#openArrow');

      geocoder.geocode({ location: loc }, (results, status) => {
        if (
          status == window.google.maps.GeocoderStatus.OK &&
          results.length &&
          results[0].address_components.length
        ) {
          const addressFormat = geocoderAddressFormater(
            results[0].address_components
          );
          address.innerHTML = addressFormat;
        }
      });

      document.querySelector('#infoclose') &&
        document.querySelector('#infoclose').addEventListener('click', (e) => {
          if (infowindow) {
            infowindow.close();
            this.props.tripPlottingInfoFunction(false);
          }
        });

      document.querySelector('#shareLiveButton') &&
        document
          .querySelector('#shareLiveButton')
          .addEventListener('click', (e) => {
            console.log('Generate Live');
            const val = document.querySelector('#shareLiveButton');
            val.getAttribute('value');
            const data = val.getAttribute('value');

            const vehicleId = data || 0;
            e.preventDefault();
            this.setState({ showGenerateLive: true });
            console.log(this.state.showGenerateLive);
          });

      document.querySelector('#playBackButton') &&
        document
          .querySelector('#playBackButton')
          .addEventListener('click', (e) => {
            const val = document.querySelector('#playBackButton');
            val.getAttribute('value');
            const data = val.getAttribute('value');
            const vehicleId = data || 0;
            e.preventDefault();
            selectedMarker = {};
            this.clearMarkers();
            this.clearGridMarker();
            this.startPlaybackk(vehicleId);
          });

      document.querySelector('#liveButton') &&
        document.querySelector('#liveButton').addEventListener('click', (e) => {
          const val = document.querySelector('#liveButton');
          const vehicleId = val.getAttribute('vehicleId');
          const imei = val.getAttribute('imei');
          e.preventDefault();
          selectedMarker = {};
          this.clearMarkers();
          //this.clearGridMarker();
          //plotLiveTracking(vehicleId);

          if (infowindow) {
            infowindow.close();
          }
          //Start Event Streaming

          this.infoWindow = new google.maps.InfoWindow();
          const eventSource = new EventSource(
            `${Env.env.REACT_APP_BACKEND_BASE_URL}/dicvlivelocation/stream?path=${this.props.liveShareId}&imei=${imei}`
          );
          this.setState({ isLoading: true });
          eventSource.addEventListener('liveShareEvent', (message) => {
            // google.maps.event.removeListener(listener);
            google.maps.event.clearListeners(this.infoWindow, 'closeclick');
            const data = JSON.parse(message.data);
            if ('ERROR' in data) {
              console.log('Calling Expired Error callback');
              eventSource.close();
              this.props.errorCallback();
            } else {
              const position = { lat: data.LAT, lng: data.LONG };
              const vehicleStatus = data.status;
              const UpdatedTime = data.UpdatedTime;

              liveLocationCoords.push(
                new google.maps.LatLng(data.LAT, data.LONG)
              );

              if (liveLocationCoords.length > 1) {
                this.setState({ isLoading: false });
                let line = new window.google.maps.Polyline({
                  path: liveLocationCoords,
                  map: map,
                  strokeOpacity: 2.0,
                  strokeWeight: 2,
                });
                polyline.push(line);
                if (vehicleStatus == 'RUNNING') {
                  if (liveLocationCoords.length > 2) {
                    this.markerIgnition.setMap(null);
                  }
                  this.markerIgnition = new window.google.maps.Marker({
                    position: position,
                    icon: require('../../../assets/images/fmt_status/truck_running_new.png'),
                    map: map,
                  });
                } else if (vehicleStatus == 'IDLE') {
                  if (liveLocationCoords.length > 2) {
                    this.markerIgnition.setMap(null);
                  }
                  this.markerIgnition = new window.google.maps.Marker({
                    position: position,
                    icon: require('../../../assets/images/fmt_status/truck_idle_new.png'),
                    map: map,
                  });
                } else {
                  if (liveLocationCoords.length > 2) {
                    this.markerIgnition.setMap(null);
                  }
                  this.markerIgnition = new window.google.maps.Marker({
                    position: position,
                    icon: require('../../../assets/images/fmt_status/truck_offline_new.png'),
                    map: map,
                  });
                }
              } else {
                this.startMarkIgnition = new window.google.maps.Marker({
                  position: position,
                  icon: require('../../../assets/images/map_markers/current_location_pointer_47x69.png'),
                  map: map,
                });
              }

              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(
                new window.google.maps.LatLng(position.lat, position.lng)
              );

              // this.map.fitBounds(bounds);
              // this.map.panToBounds(bounds);
              //this.map.setZoom(10);

              this.infoWindow.setContent(
                '<b>Vehicle Status:</b>' +
                  data.status +
                  '<br/> <b>Vehicle Reg No: </b>' +
                  data.RegNo +
                  '<br>'
                //+
                // '<br/> <b>Updated Time: </b>' +
                // data.UpdatedTime +
                // '<br>'
              );
              this.infoWindow.open(this.map, this.markerIgnition);
              this.map.setCenter(position, 8);
              var listener = google.maps.event.addListener(
                this.infoWindow,
                'closeclick',
                () => {
                  polyline.forEach((line) => line.setMap(null));
                  polyline.length = 0;
                  liveLocationCoords.length = 0;
                  this.clearMarkers();
                  this.markerIgnition.setMap(null);
                  eventSource.close();
                  this.startMarkIgnition.setMap(null);
                  this.props.resetLive();
                }
              );
            }
          });

          return () => {
            eventSource.close();
          };
        });
    });
  };
  clearFlightPlan = () => {
    this.flightPlanCoordinates = [];
  };
  drawPolylinePlayback = (data = []) => {
    this.clearMarkers();
    this.clearRoutePathPlayback();

    this.data = data;
    const bounds = new window.google.maps.LatLngBounds();

    const colorbandRoute = ['#7f9cd1'];
    const polyLineCount = 0;
    let markerIgnition;
    this.registrationId = '';

    let tempLatLngObj;
    let infowindow_stoppage;
    this.flightPlanCoordinates = [];
    this.markersIgnitionArray = [];
    const playBackCordinatesList = [];
    let playBackCordinatesDupList = [];
    let count = data && data.length;
    let increement = 0;

    this.polylineData = data.map((value, k) => {
      increement++;
      const polyLineRoute = [];
      if (value.currentAdl !== null) {
        this.currentAdl = value.currentAdl;
      }
      if (value.currentFuelLevel !== null) {
        this.currentFuelLevel = value.currentFuelLevel;
      }
      if (value.currentOdo !== null) {
        this.currentOdo = value.currentOdo;
      }

      tempLatLngObj = {
        lat: value.gpsLatitude,
        lng: value.gpsLongitude,
        gpsIgnition: value.gpsIgnition,
        gpsSpkm: value.gpsSpkm,
        gpsTime: value.gpsTime,
        vehicleDirection: value.vehicleDirection,
        plotNextPoint: value.plotNextPoint,
        label: value.label,
        adl: value.currentAdl === null ? this.currentAdl : value.currentAdl,
        odo: value.currentOdo === null ? this.currentOdo : value.currentOdo,
        fuel:
          value.currentFuelLevel === null
            ? this.currentFuelLevel
            : value.currentFuelLevel,
        direction: value.gpsHead,
      };

      this.flightPlanCoordinates.push(tempLatLngObj);

      bounds.extend(
        new window.google.maps.LatLng(value.gpsLatitude, value.gpsLongitude)
      );

      if (k > 0 && this.flightPlanCoordinates[k - 1].plotNextPoint !== false) {
        polyLineRoute.push(this.flightPlanCoordinates[k - 1]);
        polyLineRoute.push(this.flightPlanCoordinates[k]);

        if (data[k].gpsSpkm == 0 && data[k].stopDuration) {
          this.markerIgnition = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              data[k].gpsLatitude,
              data[k].gpsLongitude
            ),
            icon: require('../../../assets/images/map_markers/vehicle_Stop.gif'),
            map: this.map,
          });
          window.google.maps.event.addListener(
            this.markerIgnition,
            'click',
            (function (markerIgnition, val) {
              return function () {
                if (infowindow_stoppage) {
                  infowindow_stoppage.close();
                  infowindow_stoppage = null;
                }
                const geocoder = new window.google.maps.Geocoder();
                const latlng = new window.google.maps.LatLng(
                  val.gpsLatitude,
                  val.gpsLongitude
                );
                geocoder.geocode(
                  { location: latlng },
                  function (results, status) {
                    let location = 'N/A';
                    if (status == window.google.maps.GeocoderStatus.OK) {
                      location = results[0].formatted_address;
                      infowindow_stoppage = new window.google.maps.InfoWindow({
                        content: this.getIWContentForStoppagePoint(
                          markerIgnition,
                          val,
                          location
                        ),
                      });
                      infowindow_stoppage.open(this.map, markerIgnition);
                    } else if (status === 'ZERO_RESULTS') {
                      infowindow_stoppage = new window.google.maps.InfoWindow({
                        content: this.getIWContentForStoppagePoint(
                          markerIgnition,
                          val,
                          location
                        ),
                      });
                    }
                  }
                );
              };
            })(this.markerIgnition, data[k])
          );
          this.markersIgnitionArray.push(markerIgnition);
        }

        this.route[polyLineCount] = new window.google.maps.Polyline({
          path: polyLineRoute,
          map: this.map,
          geodesic: true,
          strokeColor: colorbandRoute[0],
          strokeOpacity: 2.0,
          strokeWeight: 8,
        });

        this.completeplaybackData = this.flightPlanCoordinates;
      }

      return this.route[0];
    });

    if (count == increement) {
      this.props.enableAlerts(true);
    } else {
      this.props.enableAlerts(false);
    }

    if (this.infowindow_playback) {
      this.infowindow_playback.close();
    }
    this.markerNew = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(
        data[0].gpsLatitude,
        data[0].gpsLongitude
      ),
      icon: require('../../../assets/images/truck_running_new.png'),
      map: this.map,
    });

    window.google.maps.event.addListener(this.markerNew, 'click', () => {
      if (
        this.openPlaybackInfoWindow &&
        this.playbackCurrentData &&
        this.playbackCurrentData.lat
      ) {
        if (this.infowindow_playback) {
          this.infowindow_playback.close();
          this.infowindow_playback = null;
        }
        this.infowindow_playback = new window.google.maps.InfoWindow();
        this.infowindow_playback.setContent(this.getIWContentForPlayback());
        this.infowindow_playback.open(this.map, this.markerNew);
      }

      this.openPlaybackInfoWindow &&
        this.openPlaybackInfoWindow(
          this.coordinatesCoveredByPlayBack,
          this.infowindow_playback
        );
    });

    playBackCordinatesDupList = playBackCordinatesList;

    this.openPlaybackInfoWindow(
      this.coordinatesCoveredByPlayBack,
      this.infowindow_playback
    );

    this.map.fitBounds(bounds);
    this.map.panToBounds(bounds);

    if (this.state.isAlertActive) {
      this.alertsToPlot();
    }
  };

  openPlaybackInfoWindow = (
    coordinatesCoveredByPlayBack,
    infowindow_playback
  ) => {
    if (this.state.data.length !== 0) {
      if (infowindow_playback) {
        infowindow_playback.close();
        infowindow_playback = null;
      }
      if (coordinatesCoveredByPlayBack <= 0) {
        this.playbackCurrentData = this.state.data[0];
        this.playbackCurrentData.lat = this.playbackCurrentData.gpsLatitude;
        this.playbackCurrentData.lng = this.playbackCurrentData.gpsLongitude;
      } else {
        this.playbackCurrentData = this.state.data[
          coordinatesCoveredByPlayBack
        ];
      }
      infowindow_playback = new window.google.maps.InfoWindow();
      infowindow_playback.setContent(this.getIWContentForPlayback());
      infowindow_playback.open(this.map, this.markerNew);
    }
  };

  getIWContentForPlayback() {
    if (infowindow) {
      infowindow.close();
    }
    const t = [];
    this.playbackCurrentData &&
      this.playbackCurrentData.label &&
      this.playbackCurrentData.label.map((e) => {
        if (e === 'HA') {
          t.push('HASH ACCELERATION');
        } else if (e === 'HB') {
          t.push('HASH BREAKING');
        } else if (e === 'OS') {
          t.push('OVERSPEEDING');
        } else if (e === 'FD') {
          t.push('FUEl DROP');
        } else if (e === 'EO') {
          t.push('ENGINE OVERRUN');
        } else if (e === 'VI') {
          t.push('VEHICLE IDLING');
        } else if (e === 'VS') {
          t.push('VEHICLE STOPPAGE');
        }
      });

    let content;
    const lat = this.playbackCurrentData.lat
      ? this.playbackCurrentData.lat
      : this.playbackCurrentData.gpsLatitude;
    const lng = this.playbackCurrentData.lng
      ? this.playbackCurrentData.lng
      : this.playbackCurrentData.gpsLongitude;
    const time = moment(this.playbackCurrentData.gpsTime).format(
      'DD-MM-YYYY HH:mm:ss'
    );

    content = '';
    content += '<div id=markerDivPlayback>';

    content += '<table>';

    content +=
      `<tr class="iw_table_row "><td class="iw_attribute_name info-bg-trip-distance-fmt plotImg"></td><td> ${
        this.playbackCurrentData.odo !== null
          ? this.playbackCurrentData.odo + ' KMS'
          : 'NA'
      }` + '</td></tr>';
    content +=
      `<tr class="iw_table_row "><td class="iw_attribute_name info-bg-mill-fmt plotImg"></td><td> ${
        this.playbackCurrentData.gpsSpkm !== null
          ? this.playbackCurrentData.gpsSpkm.toFixed(2) + ' KMPH'
          : 'NA'
      }` + '</td></tr>';
    content += `<tr class="iw_table_row"><td class="iw_attribute_name info-bg-adl-fmt plotImg"></td><td> ${
      this.playbackCurrentData.adl !== null
        ? this.playbackCurrentData.adl.toFixed(2) + ' %'
        : 'NA'
    }</td></tr>`;
    content += `<tr class="iw_table_row"><td class="iw_attribute_name info-bg-fuel-fmt plotImg"></td><td> ${
      this.playbackCurrentData.fuel !== null
        ? this.playbackCurrentData.fuel.toFixed(2) + ' %'
        : 'NA'
    }</td></tr>`;
    content += `<tr class="iw_table_row "><td class="iw_attribute_name info-bg-date-fmt plotImg"></td><td> ${time}</td></tr>`;

    for (let i = 0; i < t.length; i++) {
      content += `<tr class="iw_table_row"><td class="iw_attribute_name vehicleAlert plotImg"></td><td>${t[i]}</td></tr>`;
    }

    content += '</table>';

    content += '</div>';

    return content;
  }

  clickCross = () => {};

  clearMarkers = () => {
    Object.keys(this.state.markerCluster).length &&
      this.state.markerCluster.clearMarkers();
    this.setState({
      markerCluster: [],
      marker: [],
      data: [],
    });
  };

  renderer = {
    render: ({ count, position }) => {
      return new google.maps.Marker({
        position,
        icon: {
          url: cluster_fmt_light,
          // anchor: new google.maps.Point(0,20),
          labelOrigin: new google.maps.Point(22, 3),
          // scaledSize: new google.maps.Size(45, 45),
        },
        label: {
          text: String(count),
          color: '#000',
          fontSize: '10px',
          fontWeight: 'bold',
        },
        // adjust zIndex to be above other markers
        zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
  };

  generateMarkerCluster = (markers = []) => {
    if (typeof window !== 'undefined') {
      const wind = window && window.ClusterIcon && window.ClusterIcon.prototype;

      if (wind !== undefined) {
        // wind.createCss = this.customizeClusters;
      }
    }

    setTimeout(() => {
      const markerCluster = new MarkerClusterer({
        markers,
        map: this.map,
        renderer: this.renderer,
      });
      this.setState({
        markerCluster,
      });
    }, 1000);
  };

  // customizeClusters(pos) {
  //   const style = [];
  //   style.push(`background-image:url(${cluster_fmt_light});`);
  //   const backgroundPosition = this.backgroundPosition_
  //     ? this.backgroundPosition_
  //     : '0 0';
  //   style.push(`background-position:${backgroundPosition};`);

  //   if (typeof this.anchor_ === 'object') {
  //     if (
  //       typeof this.anchor_[0] === 'number' &&
  //       this.anchor_[0] > 0 &&
  //       this.anchor_[0] < this.height_
  //     ) {
  //       style.push(
  //         `height:${this.height_ - this.anchor_[0]}px; padding-top:${
  //           this.anchor_[0]
  //         }px;`
  //       );
  //     } else {
  //       style.push(
  //         `height:${this.height_}px; line-height:${this.height_ - 10}px;`
  //       );
  //     }
  //     if (
  //       typeof this.anchor_[1] === 'number' &&
  //       this.anchor_[1] > 0 &&
  //       this.anchor_[1] < this.width_
  //     ) {
  //       style.push(
  //         `width:${this.width_ - this.anchor_[1]}px; padding-left:${
  //           this.anchor_[1]
  //         }px;`
  //       );
  //     } else {
  //       style.push(`width:${this.width_}px; text-align:center;`);
  //     }
  //   } else {
  //     //
  //     // See the (this.height_ - 10) for line-height
  //     //

  //     style.push(
  //       'height:45px; line-height:10px; width:45px; text-align:center;'
  //     );
  //   }

  //   const txtColor = this.textColor_ ? this.textColor_ : 'black';
  //   const txtSize = this.textSize_ ? this.textSize_ : 11;

  //   style.push(
  //     `cursor:pointer; top:${pos.y}px; left:${pos.x}px; color:${txtColor}; position:absolute; font-size:${txtSize}px; font-family:Arial,sans-serif; font-weight:bold`
  //   );

  //   return style.join('');
  // }

  generateMarker = (vehicleData, infoData) => {
    const bounds = new window.google.maps.LatLngBounds();

    this.clearMarkers();
    if (vehicleData && !vehicleData.length) {
      return this.map.setZoom(5);
    }
    let position;

    const markers =
      vehicleData &&
      vehicleData.map((location, i) => {
        if (location.latitude !== null && location.longitude !== null) {
          position = new window.google.maps.LatLng(
            location.latitude,
            location.longitude
          );
          bounds.extend(position);
          return this.createMarker(location, infoData);
        }
      });

    if (this.map !== undefined) {
      markers.map((ele) => {
        if (ele !== undefined) {
          this.map.fitBounds(bounds);
        }
      });
      window.google.maps.event.addListenerOnce(
        this.map,
        'bounds_changed',
        (event) => {
          if (this.map.getZoom() > 15) {
            this.map.setZoom(13);
          }
        }
      );
    }

    if (
      selectedMarker.latitude &&
      selectedMarker.longitude &&
      this.props.isBackPlayClicked
    ) {
      const destPos = new window.google.maps.LatLng(
        selectedMarker.latitude,
        selectedMarker.longitude
      );
      bounds.extend(destPos);
      this.map.fitBounds(bounds);
      this.map.setCenter({
        lat: selectedMarker.latitude,
        lng: selectedMarker.longitude,
      });
      this.map.setZoom(12);
    }

    this.generateMarkerCluster(markers);

    this.setState({
      markers,
    });

    return markers;
  };

  createMarker = (location, infoData) => {
    if (location.tripDetail == null) {
      this.setState({ clickedVehicledIntrip: false });
    } else {
      this.setState({ clickedVehicledIntrip: true });
    }
    const geocoder = new window.google.maps.Geocoder();
    const loc = {
      lat: location.latitude,
      lng: location.longitude,
    };

    let formattedLocation = '';
    if (loc.lat !== null && loc.lng !== null) {
      geocoder.geocode({ location: loc }, (results, status) => {
        const location = 'N/A';
        if (status == window.google.maps.GeocoderStatus.OK) {
          formattedLocation = results[0].formatted_address;
        }
      });
    }

    const clickedMarker = new window.google.maps.LatLngBounds();
    const vehicleLocation = location;
    const statusLocal = location.vehicleStatus;

    const service = new window.google.maps.DistanceMatrixService();
    let { vehicleStatus = 'offline' } = location;
    vehicleStatus = vehicleStatus ? vehicleStatus.toLowerCase() : '';

    const marker = new window.google.maps.Marker({
      position: {
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      },
      map: this.map,
      icon: this.genrateMarkerIcon(vehicleStatus),
    });

    window.google.maps.event.addListener(marker, 'click', () => {
      Event(
        'FMT click view',
        'FMT vehicle pop up click',
        'FMT vehicle pop up views'
      );

      this.setState({
        clickedLat: location.latitude ? Number(location.latitude) : 0,
        clickedLong: location.longitude ? Number(location.longitude) : 0,
        clickedVehicledId: location.vehicleId,
        clickedVehicleStatus: location.vehicleStatus,
        clickedImei: location.imei,
      });
      selectedMarker = {};
      selectedMarker.latitude = location.latitude;
      selectedMarker.longitude = location.longitude;
      selectedMarker.vehicleStatus = vehicleStatus;
      selectedMarker.vehicleId = location.vehicleId;
      selectedMarker.registrationId = location.registrationId;

      if (infowindow) {
        infowindow.close();
      }
      if (location.latitude !== null && location.longitude !== null) {
        infowindow = new window.google.maps.InfoWindow({
          maxWidth: 515,
          content: ReactDOMServer.renderToString(
            <LiveInfoWindow
              apiT="NA"
              location={statusLocal}
              lat={location.latitude}
              lng={location.longitude}
              regid={location.registrationId}
              clickedVehicledIntrip={this.state.clickedVehicledIntrip}
              playback={this.startPlaybackk}
              formattedLocation={formattedLocation}
              closeInfoWindow={this.closeInfoWindow}
              vehicleId={vehicleLocation.vehicleId}
              imei={vehicleLocation.imei}
              tripDetails={location.tripDetail ? true : false}
            />
          ),
        });
        this.addEventListenerInfoWindow(infowindow);
        infowindow.open(this.map, marker);
      }
    });

    return marker;
  };

  addEventListenerInfoWindow = (infowindow) => {
    window.google.maps.event.addListener(infowindow, 'domready', () => {
      let loc = {
        lat: this.state.clickedLat,
        lng: this.state.clickedLong,
      };

      const geocoder = new window.google.maps.Geocoder();
      let address = document.querySelector('#geoAddress');
      let vehicleDetails = document.querySelector('.vehicleDetails');
      let driverDetails = document.querySelector('#driverDetails');
      let showDetails = document.querySelector('#view-details');
      let closeInfo = document.querySelector('#openArrow');

      geocoder.geocode({ location: loc }, (results, status) => {
        if (
          status == window.google.maps.GeocoderStatus.OK &&
          results.length &&
          results[0].address_components.length
        ) {
          const addressFormat = geocoderAddressFormater(
            results[0].address_components
          );
          address.innerHTML = addressFormat;
        }
      });

      document.querySelector('#infoclose') &&
        document.querySelector('#infoclose').addEventListener('click', (e) => {
          if (infowindow) {
            infowindow.close();
          }
        });

      document.querySelector('#shareLiveButton') &&
        document
          .querySelector('#shareLiveButton')
          .addEventListener('click', (e) => {
            console.log('Generate Live');
            const val = document.querySelector('#shareLiveButton');
            val.getAttribute('value');
            const data = val.getAttribute('value');

            const vehicleId = data || 0;
            e.preventDefault();
            this.setState({ showGenerateLive: true });
            console.log(this.state.showGenerateLive);
          });

      document.querySelector('#playBackButton') &&
        document
          .querySelector('#playBackButton')
          .addEventListener('click', (e) => {
            const val = document.querySelector('#playBackButton');
            val.getAttribute('value');
            const data = val.getAttribute('value');
            const vehicleId = data || 0;
            e.preventDefault();
            this.clearMarkers();
            selectedMarker = {};
            this.startPlaybackk(vehicleId);
          });

      document.querySelector('#liveButton') &&
        document.querySelector('#liveButton').addEventListener('click', (e) => {
          const val = document.querySelector('#liveButton');
          const vehicleId = val.getAttribute('vehicleId');
          const imei = val.getAttribute('imei');
          e.preventDefault();
          selectedMarker = {};
          this.clearMarkers();
          //this.clearGridMarker();
          //plotLiveTracking(vehicleId);
          this.setState({ markers: [] });

          if (infowindow) {
            infowindow.close();
          }

          //Start Event Streaming

          this.infoWindow = new google.maps.InfoWindow();
          const eventSource = new EventSource(
            `${Env.env.REACT_APP_BACKEND_BASE_URL}/dicvlivelocation/stream?path=${this.props.liveShareId}&imei=${imei}`
          );
          this.setState({ isLoading: true });

          eventSource.addEventListener('liveShareEvent', (message) => {
            // google.maps.event.removeListener(listener);
            google.maps.event.clearListeners(this.infoWindow, 'closeclick');
            const data = JSON.parse(message.data);
            if ('ERROR' in data) {
              console.log('Calling Expired Error callback');
              eventSource.close();
              this.props.errorCallback();
            } else {
              const position = { lat: data.LAT, lng: data.LONG };
              const vehicleStatus = data.status;
              const UpdatedTime = data.UpdatedTime;
              liveLocationCoords.push(
                new google.maps.LatLng(data.LAT, data.LONG)
              );

              if (liveLocationCoords.length > 1) {
                this.setState({ isLoading: false });
                let line = new window.google.maps.Polyline({
                  path: liveLocationCoords,
                  map: map,
                  strokeOpacity: 2.0,
                  strokeWeight: 2,
                });
                polyline.push(line);
                if (vehicleStatus == 'RUNNING') {
                  if (liveLocationCoords.length > 2) {
                    this.markerIgnition.setMap(null);
                  }
                  this.markerIgnition = new window.google.maps.Marker({
                    position: position,
                    icon: require('../../../assets/images/fmt_status/truck_running_new.png'),
                    map: map,
                  });
                } else if (vehicleStatus == 'IDLE') {
                  if (liveLocationCoords.length > 2) {
                    this.markerIgnition.setMap(null);
                  }
                  this.markerIgnition = new window.google.maps.Marker({
                    position: position,
                    icon: require('../../../assets/images/fmt_status/truck_idle_new.png'),
                    map: map,
                  });
                } else {
                  if (liveLocationCoords.length > 2) {
                    this.markerIgnition.setMap(null);
                  }
                  this.markerIgnition = new window.google.maps.Marker({
                    position: position,
                    icon: require('../../../assets/images/fmt_status/truck_offline_new.png'),
                    map: map,
                  });
                }
              } else {
                this.startMarkIgnition = new window.google.maps.Marker({
                  position: position,
                  icon: require('../../../assets/images/map_markers/current_location_pointer_47x69.png'),
                  map: map,
                });
              }

              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(
                new window.google.maps.LatLng(position.lat, position.lng)
              );

              // this.map.fitBounds(bounds);
              // this.map.panToBounds(bounds);
              //this.map.setZoom(10);

              this.infoWindow.setContent(
                '<b>Vehicle Status:</b>' +
                  data.status +
                  '<br/> <b>Vehicle Reg No: </b>' +
                  data.RegNo +
                  '<br>'
                //+
                // '<br/> <b>Updated Time: </b>' +
                // data.UpdatedTime +
                // '<br>'
              );
              //this.infoWindow.setPosition(position);
              this.infoWindow.open(this.map, this.markerIgnition);

              this.map.setCenter(position, 8);
              // setTimeout(this.infoWindow.close(), 15000);
              var listener = google.maps.event.addListener(
                this.infoWindow,
                'closeclick',
                () => {
                  polyline.forEach((line) => line.setMap(null));
                  polyline.length = 0;
                  liveLocationCoords.length = 0;
                  eventSource.close();
                  this.clearMarkers();
                  this.markerIgnition.setMap(null);
                  this.startMarkIgnition.setMap(null);
                  this.props.resetLive();
                }
              );
            }
          });

          return () => {
            eventSource.close();
          };
        });
    });
  };

  clearRoutePathPlayback = () => {
    if (!this.route || !this.markersIgnitionArray) {
      return;
    }

    if (this.polylineData.length) {
      this.polylineData.map((val) => (val ? val.setMap(null) : ''));
    }

    for (let i = 0; i < this.route.length; i++) {
      this.route[i].setMap(null);
    }
    for (let i = 0; i < this.markersIgnitionArray.length; i++) {
      this.markersIgnitionArray[i] && this.markersIgnitionArray[i].setMap(null);
    }

    if (this.markerNew) {
      this.markerNew.setMap(null);
      this.markerNew = null;
    }
    if (this.infowindow_playback) {
      this.infowindow_playback.close();
      this.infowindow_playback = null;
    }

    this.currentAdl = null;
    this.currentFuelLevel = null;
    this.currentOdo = null;
  };

  clearEventPathPlayBack = () => {
    if (this.eventRoutePlayBack === null) {
      return;
    }

    if (this.initialMaker) {
      this.initialMaker.setMap(null);
      this.initialMaker = null;
    }
    if (this.finalMarker) {
      this.finalMarker.setMap(null);
      this.finalMarker = null;
    }
    if (this.currentMarker) {
      this.currentMarker.setMap(null);
      this.currentMarker = null;
    }
    this.finalPolyline.setMap(null);
    this.eventRoutePlayBack.setMap(null);
  };

  closeInfoWindow = () => {
    if (infowindow) {
      infowindow.close();
    }
  };

  genrateMarkerIcon = (vehicleStatus) => {
    vehicleStatus = vehicleStatus === 'new' ? 'offline' : vehicleStatus;

    if (
      vehicleStatus !== undefined ||
      vehicleStatus !== '' ||
      vehicleStatus !== null
    ) {
      return require(`${
        '../../../assets/images/fmt_status/' + '/truck_'
      }${vehicleStatus.toLowerCase()}_new.png`);
    }
  };

  clearGridMarker = () => {
    if (this.gridMarker) {
      this.gridMarker.setMap(null);
      this.gridMarker = null;
    }
  };

  plotSelectedVehicle = () => {
    this.createMarker(selectedMarker);
  };

  loadMapBasedOnTheme = (mode) => {
    this.props.enableLightMode(mode);
    this.setState({ isLightThemeActive: mode }, () => {
      if (this.props.isLiveTrackingEnabled) {
        setTimeout(() => {
          this.initMap();
        }, 0);
      }
    });
    this.map.setOptions({ styles: mode ? [] : mapStyles });
  };

  addMarkerWithTimeout(position, timeout) {
    window.setTimeout(() => {
      infoWindow.setPosition(position);
      infoWindow.open(map);
      map.setZoom(16);

      markers.push(
        new google.maps.Marker({
          position: position,
          //zoom: 12,
          center: position,
          map,
          //animation: google.maps.Animation.DROP,
        })
      );
    }, timeout);
  }

  initMap = async () => {
    const mapCenter = { lat: 21.1458, lng: 79.0882 };
    this.map =
      (await window.google) &&
      new window.google.maps.Map(document.getElementById('map1'), {
        center: mapCenter,
        zoom: 4.7,
        fullscreenControl: true,
        streetViewControl: false,
        styles: this.state.isLightThemeActive ? [] : mapStyles,
      });

    map = this.map;

    window.google.maps.event.addListener(this.map, 'click', (event) => {
      infowindow && infowindow.close();
    });

    window.google.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.state.mkr && this.state.mkr.setMap(null);
    });
  };

  generateEventMarkers = (EventData) => {
    const bounds = new window.google.maps.LatLngBounds();
    this.clearMarkers();

    let position;

    const eventMarkers =
      EventData &&
      EventData.map((location, i) => {
        position = new window.google.maps.LatLng(location.lat, location.lng);
        bounds.extend(position);

        return this.createEventMarker(location);
      });

    this.map.fitBounds(bounds);

    if (
      selectedMarker.latitude &&
      selectedMarker.longitude &&
      this.props.isBackPlayClicked
    ) {
      const destPos = new window.google.maps.LatLng(
        selectedMarker.latitude,
        selectedMarker.longitude
      );
      bounds.extend(destPos);
      this.map.fitBounds(bounds);
      this.map.setCenter({
        lat: selectedMarker.latitude,
        lng: selectedMarker.longitude,
      });
      this.map.setZoom(12);
    }

    this.generateEventCluster(eventMarkers);

    this.setState({
      eventMarkers,
    });

    return eventMarkers;
  };

  generateEventCluster = (eventMarkers) => {
    if (typeof window !== 'undefined') {
      const wind = window && window.ClusterIcon && window.ClusterIcon.prototype;

      if (wind !== undefined) {
        wind.createCss = this.customizeEventClusters;
      }
    }

    setTimeout(() => {
      const eventMarkerCluster = new window.MarkerClusterer(
        this.map,
        eventMarkers,
        {
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        }
      );
      this.setState(
        {
          eventMarkerCluster,
        },
        () => {}
      );
    }, 0);
  };

  customizeEventClusters(pos) {
    const style = [];
    style.push(`background-image:url(${cluster_icon_events});`);
    const backgroundPosition = this.backgroundPosition_
      ? this.backgroundPosition_
      : '0 0';
    style.push(`background-position:${backgroundPosition};`);

    if (typeof this.anchor_ === 'object') {
      if (
        typeof this.anchor_[0] === 'number' &&
        this.anchor_[0] > 0 &&
        this.anchor_[0] < this.height_
      ) {
        style.push(
          `height:${this.height_ - this.anchor_[0]}px; padding-top:${
            this.anchor_[0]
          }px;`
        );
      } else {
        //
        // See the (this.height_ - 10) for line-height
        //

        style.push(
          `height:${this.height_}px; line-height:${this.height_ - 10}px;`
        );
      }
      if (
        typeof this.anchor_[1] === 'number' &&
        this.anchor_[1] > 0 &&
        this.anchor_[1] < this.width_
      ) {
        style.push(
          `width:${this.width_ - this.anchor_[1]}px; padding-left:${
            this.anchor_[1]
          }px;`
        );
      } else {
        style.push(`width:${this.width_}px; text-align:center;`);
      }
    } else {
      //
      // See the (this.height_ - 10) for line-height
      //

      style.push(
        'height:47px; line-height:36px; width:39px; text-align:center;'
      );
    }

    const txtColor = this.textColor_ ? this.textColor_ : 'black';
    const txtSize = this.textSize_ ? this.textSize_ : 11;

    style.push(
      `cursor:pointer; top:${pos.y}px; left:${pos.x}px; color:${txtColor}; position:absolute; font-size:${txtSize}px; font-family:Arial,sans-serif; font-weight:bold`
    );

    return style.join('');
  }

  createEventMarker = (eventLocation) => {
    let OsValue = null;
    const label =
      eventLocation &&
      eventLocation.label &&
      eventLocation.label.length > 0 &&
      eventLocation.label.map((label) => label);

    const loc = {
      lat: eventLocation.lat,
      lng: eventLocation.lng,
    };

    if (label) {
      if (label == 'OS') {
        OsValue = eventLocation.speedVal;
        OsValue = OsValue.toFixed(1);
      }
      const markerIcon = {
        url:
          label.length === 1
            ? singleEvent
            : label.length === 2
            ? events
            : eventsT,
        labelOrigin:
          label && label.length > 2
            ? new window.google.maps.Point(28, 15)
            : new window.google.maps.Point(15, 8),
      };

      const eventMarker = new window.google.maps.Marker({
        position: { lat: eventLocation.lat, lng: eventLocation.lng },
        map: this.map,
        icon: markerIcon,
        label: label
          ? {
              color: '#000',
              fontSize: '12px',
              text: label && eventLocation.label.toString(),
            }
          : '',
      });

      window.google.maps.event.addListener(eventMarker, 'click', () => {
        const geocoder = new window.google.maps.Geocoder();

        if (infowindow) {
          infowindow.close();
        }

        geocoder.geocode({ location: loc }, (results, status) => {
          let location = 'N/A';
          if (status == window.google.maps.GeocoderStatus.OK) {
            location = results[0].formatted_address;
          }

          infowindow = new window.google.maps.InfoWindow({
            maxWidth: 300,
            content: ReactDOMServer.renderToString(
              <AlertWindow
                apiT="NA"
                OsValue={OsValue}
                formattedLocation={location}
                closeInfoWindow={this.closeInfoWindow}
                label={label}
              />
            ),
          });

          this.addEventListenerAlertInfoWindow(infowindow);
          infowindow.open(this.map, eventMarker);
        });
      });

      return eventMarker;
    }
  };

  setStrokeColor = (isLightTheme) => {
    let strokeColor = isLightTheme ? '#1A4D7F' : '#71AFF8';
    return strokeColor;
  };

  polyLineStrokeColor = (isLightTheme) => {
    let strokeColor = isLightTheme ? '#7f9cd1' : '#FFDFDF';
    return strokeColor;
  };

  closePlayBack = () => {
    this.props.playBackClose();
  };

  regIdClick = (data, statusForPopup) => {
    if (infowindow) {
      infowindow.close();
    }
    if (data.tripDetail == null) {
      this.setState({ clickedVehicledIntrip: false });
    } else {
      this.setState({ clickedVehicledIntrip: true });
    }
    this.setState({
      clickedLat: data.latitude ? Number(data.latitude) : 0,
      clickedLong: data.longitude ? Number(data.longitude) : 0,
      clickedVehicledId: data.vehicleId,
    });
    let w3woutput = '';
    const bounds = new window.google.maps.LatLngBounds();
    const statusForPopupLocal = statusForPopup;
    var mkr = new window.google.maps.Marker({
      position: { lat: Number(data.latitude), lng: Number(data.longitude) },
      map: this.map,
      icon: this.genrateMarkerIcon(data.vehicleStatus),
    });

    this.setState({ mkr: mkr });

    const latlngNew = new window.google.maps.LatLng(
      data.latitude,
      data.longitude
    );

    selectedMarker = {};

    selectedMarker.latitude = data.latitude;
    selectedMarker.longitude = data.longitude;
    selectedMarker.vehicleStatus = data.vehicleStatus;
    selectedMarker.vehicleId = data.vehicleId;
    selectedMarker.registrationId = data.registrationId;

    bounds.extend(latlngNew);
    this.map.fitBounds(bounds);

    if (this.map !== undefined) {
      this.map.fitBounds(bounds);
      window.google.maps.event.addListenerOnce(
        this.map,
        'bounds_changed',
        (event) => {
          if (this.map.getZoom() > 15) {
            this.map.setZoom(15);
          }
        }
      );
    }

    const geocoder = new window.google.maps.Geocoder();

    let formattedLocation = '';
    if (data.latitude !== null && data.longitude !== null) {
      geocoder.geocode({ location: latlngNew }, (results, status) => {
        const location = 'N/A';
        if (status == window.google.maps.GeocoderStatus.OK) {
          formattedLocation = results[0].formatted_address;
          infowindow = new window.google.maps.InfoWindow({
            maxWidth: 515,
            pixelOffset: new window.google.maps.Size(0, -60),
            content: ReactDOMServer.renderToString(
              <LiveInfoWindow
                apiT="NA"
                top={true}
                regid={data.registrationId}
                lat={data.latitude}
                lng={data.longitude}
                location={statusForPopupLocal}
                playback={this.startPlaybackk}
                formattedLocation={formattedLocation}
                closeInfoWindow={this.closeInfoWindow}
                vehicleId={data.vehicleId}
                tripDetails={data.tripDetail ? true : false}
                imei={data.imei}
              />
            ),
          });
          this.addEventListenerInfoWindowForTrip(infowindow);
          infowindow.open(this.map, mkr);
        }
      });
    }
  };

  togglePlayBackWindow = () => {
    this.setState({ hidePanel: !this.state.hidePanel });
  };

  removeShapes = () => {
    const { finalCircle, finalPolygon } = this.state;

    finalCircle.map((val) => {
      val[0].setMap(null);
      val[1].setMap(null);
    });
    finalPolygon.map((val) => {
      val[0].setMap(null);
      val[1].setMap(null);
    });
    this.setState({
      finalCircle: [],
      finalPolygon: [],
    });
  };

  playBackOption(status) {
    if (status === 'exit') {
      this.removeShapes();
      this.clearRoutePathPlayback();
      this.clearMarkers();
      this.hardExitPlayBack();

      return this.removeShapes();
    }
    if (status === 'play') {
      setTimeout(() => {
        this.clearMarkers();
      }, 10);
      this.startPlayback();
    } else if (status === 'pause') {
      this.pausePlayback();
    } else if (status === 'restart') {
      this.clearRoutePathPlayback();
      this.coordinatesCoveredByPlayBack = -1;

      // marker state
      this.speed = 150000;
      this.delay = this.delay;
      this.lastPlaybackvalueTime = 0;
      this.coordinatesCoveredByPlayBack = -1;
      this.completeplaybackData = [];
      this.route = [];
      this.restartPlayback();
      this.removeShapes();
    } else if (status === 'continue') {
      this.continuePlayBack();
    } else {
      if (this.infowindow_playback) {
        this.infowindow_playback.close();
        this.infowindow_playback = null;
      }
      this.coordinatesCoveredByPlayBack = -1;
      this.removeShapes();
      this.clearRoutePathPlayback();
      this.clearMarkers();

      return this.removeShapes();
    }
  }

  continuePlayBack = () => {
    this.setState({ infoStatus: true });
    this.playStopped = undefined;
    this.pauseContinue = 0;
    if (!this.markerNew) {
      return;
    }
    this.markerNewLat = this.markerNew.position.lat();
    this.markerNewLng = this.markerNew && this.markerNew.position.lng();
    const coordsLeftToPlayback = [];
    for (
      let i = this.coordinatesCoveredByPlayBack;
      i < this.completeplaybackData.length;
      i++
    ) {
      coordsLeftToPlayback.push(this.completeplaybackData[i]);
    }
    this.animateMarker(this.markerNew, coordsLeftToPlayback, this.speed);
  };

  restartPlayback = () => {
    this.props.getDates(this.state.startDate, this.state.endDate);
    this.pausePlayback();
    this.pauseContinue = 2;
    setTimeout(() => {
      this.playStopped = undefined;

      this.coordinatesCoveredByPlayBack = -1;
      this.props.getPlayBack(true, true, this.state.selectedVehicelId);
    }, 1000);
    this.showLoadingPage = false;
  };

  startPlayback = () => {
    // this.flightPlanCoordinates=[]
    this.clearMarkers();
    this.loadingDataPlayBtn = false;
    this.pauseContinue = 0;
    this.stopMode = false;
    if (!this.flightPlanCoordinates)
      return toastr.error('No playback details available');
    if (this.flightPlanCoordinates.length <= 0) {
      toastr.error('No playback details available');
      this.stopMode = true;
    } else {
      this.animateMarker(
        this.markerNew,
        this.flightPlanCoordinates,
        this.speed
      );
    }
  };

  animateMarker(marker, coords, km_h) {
    let target = 0;
    km_h = km_h || 50;
    const goToPoint = () => {
      if (marker) {
        var lat = marker.position.lat();
        var lng = marker.position.lng();
      } else {
        lat = this.markerNewLat;
        lng = this.markerNewLng;
      }

      marker.setIcon(require('../../../assets/images/truck_routeplayback.png'));

      if (coords[target]) {
      } else {
        this.props.handlePlayBack('');
        marker.setIcon(
          require('../../../assets/images/truck_routeplayback.png')
        );

        return null;
      }

      const step = (km_h * 1000 * this.delay) / 3600000;

      const dest = new window.google.maps.LatLng(
        coords[target].lat,
        coords[target].lng
      );
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        dest,
        marker.position
      );

      const numStep = distance / step;
      const i = 0;
      const coordsLeftToPlayback = [];
      const deltaLat = (coords[target].lat - lat) / numStep;
      const deltaLng = (coords[target].lng - lng) / numStep;
      const moveMarker = () => {
        if (this.playStopped === true) {
          return;
        }
        lat += deltaLat;
        lng += deltaLng;
        i += step;
        this.playbackCurrentData = coords[target];

        if (this.infowindow_playback) {
          this.infowindow_playback.close();
          this.infowindow_playback = null;
        }
        this.infowindow_playback = new window.google.maps.InfoWindow();
        this.infowindow_playback.setContent(this.getIWContentForPlayback());
        this.infowindow_playback.open(this.map, marker);

        this.latestLat = lat;
        this.latestLng = lng;
        if (i < distance) {
          marker.setPosition(new window.google.maps.LatLng(lat, lng));
          this.moveMarkerTimeout = setTimeout(moveMarker, this.delay);
        } else {
          marker.setPosition(dest);
          ++this.coordinatesCoveredByPlayBack;
          target++;

          if (target == coords.length) {
            this.pauseContinue = 2;
            this.props.handlePlayBack('restart');
            this.coordinatesCoveredByPlayBack = -1;

            return;
          }
          this.goToPointTimeout = setTimeout(goToPoint, this.delay);
        }

        for (
          var i = this.coordinatesCoveredByPlayBack;
          i < this.completeplaybackData.length;
          i++
        ) {
          coordsLeftToPlayback.push(this.completeplaybackData[i]);
        }
        if (coordsLeftToPlayback <= 0) {
        }
      };

      this.lastPlaybackvalueTime = coords[target].gpsTime;
      if (
        target >= 1 &&
        coords[target].gpsTime - coords[target - 1].gpsTime >= 180000
      ) {
        marker.setPosition(dest);
        ++this.coordinatesCoveredByPlayBack;
        target++;
        this.goToPointTimeout = setTimeout(goToPoint, this.delay);
      } else {
        return moveMarker();
      }
    };

    return goToPoint();
  }

  setPlaybackId(vehicleId) {
    this.clearMarkers();
    this.setState({ selectedVehicelId: vehicleId });
  }

  handlePlayBackOptions = (status) => {
    const { startPlayBack, handlePlaybackStatus } = this.props;
    if (status === 'exit') {
      this.count = 0;
      this.setState({
        emDate: new Date(),
        emxDate: new Date(),
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
        emTime: 0,
        emxTime: new Date().getHours(),
        mTime: 0,
        mxTime: new Date().getHours(),
        playBackLength: false,
        isAlertActive: false,
      });
      startPlayBack('exit');
      this.props.handlePlayBack('exit');
      if (this.props.isLightMode) {
        this.loadMapBasedOnTheme(true);
      } else {
        this.initMap();
      }

      return handlePlaybackStatus(false, false);
    }
    if (status === 'hardExit') {
      this.setState(
        {
          resSubActive: 'option',
          playbackOption: '',
        },
        () => {
          this.pausePlayback();
        }
      );
      this.playBackOption('exit');

      return this.handlePlayBackOptions('exit');
    }

    if (
      this.state.playBackData &&
      !this.state.playBackData.length &&
      status === 'play'
    ) {
      return;
    }
    this.setState(
      {
        resSubActive: 'option',
        playbackOption: status,
      },
      () => {
        this.props.handlePlayBack(status);
        this.setPlaybackId(this.state.selectedFields.playBackVehicleID);
        startPlayBack(status);
        ++this.count;
      }
    );
  };

  handleOutsideClick = () => {
    this.setState({ infoStatus: !this.state.infoStatus });
  };

  pausePlayback = () => {
    this.setState({ infoStatus: false });
    this.pauseContinue = 1;
    clearTimeout(this.goToPointTimeout);
    clearTimeout(this.moveMarkerTimeout);
  };

  hardExitPlayBack = () => {
    this.playStopped = undefined;
    this.coordinatesCoveredByPlayBack = -1;
    this.setState({ infoStatus: true });
  };

  closeLiveTrip = () => {
    this.props.closeLiveTracking();
  };

  clearEventMarkers = () => {
    if (this.state.eventMarkers) {
      for (let i = 0; i < this.state.eventMarkers.length; i++) {
        if (this.state.eventMarkers[i] != undefined) {
          this.state.eventMarkers[i].setMap(null);
        }
      }
    }

    Object.keys(this.state.eventMarkerCluster).length &&
      this.state.eventMarkerCluster.clearMarkers();
    this.setState({
      eventMarkerCluster: [],
      eventMarker: [],
      data: [],
    });
  };

  sameDates = (d) => {
    this.setState({
      emxDate: d,
      emDate: subDays(d, 1),
      endDate: d,
    });
  };

  differentDates = (d) => {
    this.setState({
      emxDate: addDays(d, 1),
      endDate: addDays(d, 1),
    });
  };

  endDates = () => {
    this.setState({
      emxDate: new Date(),
      endDate: new Date(),
      emxTime: new Date().getHours(),
    });
  };

  endDay = (d) => {
    this.setState({
      emDate: subDays(d, 1),
    });
  };

  selectLessTime = (mTime) => this.setState({ mTime });

  selectSameFormatTime = (mTime, mxTime, emxTime) => {
    this.setState({ mTime, mxTime, emxTime });
  };

  selectSameDate = (mTime, mxTime) => this.setState({ mTime, mxTime });
  defaultDates = (mTime, mxTime) => this.setState({ mTime, mxTime });
  selectStartDate = (d) => this.setState({ startDate: d });
  selectEndDate = (d) => this.setState({ endDate: d });
  selectEndTime = (emtime, emxTime) => {
    this.setState({
      emTime: emtime,
      emxTime: emxTime,
    });
  };
  endTimes = (d) => this.setState({ emxDate: d, emDate: subDays(d, 1) });

  speedControls = (value) => {
    this.setState({ selectedSpeed: value });
    const totaDelay = calculateDelay(value);
    if (value == 1) {
      this.delay = 8000;
    } else {
      this.delay = totaDelay;
    }
  };

  reloadRoute = () => {
    const { startDate, endDate } = this.state;
    const date = moment(startDate).format('YYYY-MM-DD');
    const hr = `${moment(startDate).hour()}`;
    const hrs = hr.length == 1 ? 0 + hr : hr;
    const FinalFormat = `${date} ${hrs}:00:00`;

    const date1 = moment(endDate).format('YYYY-MM-DD');
    const hr1 = `${moment(endDate).hour()}`;
    const hrs1 = hr1.length == 1 ? 0 + hr1 : hr1;
    const endFinalFormat = `${date1} ${hrs1}:00:00`;
    this.delay = 2000;

    this.setState({
      startDate: parseISO(FinalFormat),
      endDate: parseISO(endFinalFormat),
      selectedSpeed: 1,
    });
    this.props.goButtonClicked(FinalFormat, endFinalFormat);
  };

  cancelPlayBackPopUp = () => {
    this.props.cancelPlayBackPopUp(true);
  };

  cancelTripPopUp = () => {
    this.props.cancelTripPopUp(true);
  };

  alertsToPlot = () => {
    this.props.alertsToPlot();
  };

  plotAlertsonMap = (alertsData) => {
    const bounds = new window.google.maps.LatLngBounds();

    this.clearAlertMarkers();
    if (alertsData && !alertsData.length) {
      return this.map.setZoom(5);
    }
    let position;

    const alertMarkers =
      alertsData &&
      alertsData.map((location, i) => {
        if (location.latitude !== null && location.longitude !== null) {
          position = new window.google.maps.LatLng(
            location.latitude,
            location.longitude
          );
          bounds.extend(position);

          return this.createAlertMarker(location);
        }
      });
    this.generateAlertsCluster(alertMarkers);
    this.setState({ alertMarkers });
  };

  clearAlertMarkers = () => {
    if (this.state.alertMarkers) {
      for (let i = 0; i < this.state.alertMarkers.length; i++) {
        if (this.state.alertMarkers[i] != undefined) {
          this.state.alertMarkers[i].setMap(null);
        }
      }
    }

    Object.keys(this.state.alertsMarkerCluster).length &&
      this.state.alertsMarkerCluster.clearMarkers();
    this.setState({
      alertsMarkerCluster: [],
      alertMarker: [],
      data: [],
    });
  };

  createAlertMarker = (alertLocation) => {
    const markerIcon = {
      url: singleEvent,
      labelOrigin: new window.google.maps.Point(15, 8),
    };

    const alertMarker = new window.google.maps.Marker({
      position: {
        lat: Number(alertLocation.latitude),
        lng: Number(alertLocation.longitude),
      },
      map: this.map,
      icon: markerIcon,
      label: alertLocation.label,
    });

    let loc = {
      lat: alertLocation.latitude,
      lng: alertLocation.longitude,
    };

    window.google.maps.event.addListener(alertMarker, 'click', () => {
      const geocoder = new window.google.maps.Geocoder();

      if (infowindow) {
        infowindow.close();
      }

      if (this.infowindow_playback) {
        this.infowindow_playback.close();
      }

      geocoder.geocode({ location: loc }, (results, status) => {
        let location = 'N/A';
        if (status == window.google.maps.GeocoderStatus.OK) {
          location = results[0].formatted_address;
        }

        infowindow = new window.google.maps.InfoWindow({
          maxWidth: 300,
          content: ReactDOMServer.renderToString(
            <AlertInfoWindow
              alertName={alertLocation.alertName}
              desc={alertLocation.desc}
              alertTime={alertLocation.alertTime}
              location={location}
            />
          ),
        });
        infowindow.open(this.map, alertMarker);
      });
    });

    return alertMarker;
  };

  disableAlertButton = () => {
    this.clearAlertMarkers();
    this.setState({ isAlertActive: false });
    this.props.disableAlertButton(true);
  };

  alertsToggle = () => {
    this.setState({ isAlertActive: !this.state.isAlertActive }, () => {
      if (this.state.isAlertActive) {
        this.clearAlertMarkers();
        this.props.alertsToPlot();
      } else {
        this.clearAlertMarkers();
        this.props.toggleAlerts();
      }
    });
  };

  generateAlertsCluster = (eventMarkers) => {
    if (typeof window !== 'undefined') {
      const wind = window && window.ClusterIcon && window.ClusterIcon.prototype;

      if (wind !== undefined) {
        wind.createCss = this.customizeAlertClusters;
      }
    }

    setTimeout(() => {
      const alertsMarkerCluster = new window.MarkerClusterer(
        this.map,
        eventMarkers
      );
      this.setState({
        alertsMarkerCluster,
      });
    }, 0);
  };

  customizeAlertClusters(pos) {
    const style = [];
    style.push(`background-image:url(${alert_cluster});`);
    const backgroundPosition = this.backgroundPosition_
      ? this.backgroundPosition_
      : '0 0';
    style.push(`background-position:${backgroundPosition};`);

    if (typeof this.anchor_ === 'object') {
      if (
        typeof this.anchor_[0] === 'number' &&
        this.anchor_[0] > 0 &&
        this.anchor_[0] < this.height_
      ) {
        style.push(
          `height:${this.height_ - this.anchor_[0]}px; padding-top:${
            this.anchor_[0]
          }px;`
        );
      } else {
        //
        // See the (this.height_ - 10) for line-height
        //

        style.push(
          `height:${this.height_}px; line-height:${this.height_ - 10}px;`
        );
      }
      if (
        typeof this.anchor_[1] === 'number' &&
        this.anchor_[1] > 0 &&
        this.anchor_[1] < this.width_
      ) {
        style.push(
          `width:${this.width_ - this.anchor_[1]}px; padding-left:${
            this.anchor_[1]
          }px;`
        );
      } else {
        style.push(`width:${this.width_}px; text-align:center;`);
      }
    } else {
      //
      // See the (this.height_ - 10) for line-height
      //

      style.push(
        'height:40px; line-height:13px; width:39px; text-align:center;'
      );
    }

    const txtColor = this.textColor_ ? this.textColor_ : 'black';
    const txtSize = this.textSize_ ? this.textSize_ : 11;

    style.push(
      `cursor:pointer; top:${pos.y}px; left:${pos.x}px; color:${txtColor}; position:absolute; font-size:${txtSize}px; font-family:Arial,sans-serif; font-weight:bold`
    );

    return style.join('');
  }

  disableGoBtn = (value) => {
    this.props.disableGo(value);
  };

  showSatellite = () => {
    this.map.setMapTypeId('hybrid');
  };

  showStreetView = () => {
    this.map.setMapTypeId('roadmap');
  };

  closeGenerateLive = () => {
    this.setState({ showGenerateLive: false, mapTrigger: false });
  };

  render() {
    const { playbackOption, isAlertActive } = this.state;
    const { plotAlerts, disabledAlerts, isData } = this.props;

    return (
      <>
        {this.state.isLoading ? <Loader /> : null}

        <div id="map1" />
        <div
          className="satellite-icon"
          onClick={this.showSatellite}
          title="Show satellite imagery"
        >
          <img
            src={satelliteIcon}
            className="control-image"
            alt="satellite-icon"
          />
        </div>
        <div
          className="map-option-icon"
          onClick={this.showStreetView}
          title="Show street map"
        >
          <img src={mapIcon} className="control-image" alt="map-icon" />
        </div>

        {this.props.geofencedefaultPop && (
          <div className="geo-type-popup">
            <div className="geo-type-heading">CREATE GEOFENCE</div>
            <div>By default you can create circular geofence.</div>
            <div>To create POLYGONAL geofence press SHIFT.</div>
            <div className="text-center">
              <button
                className="geo-type-button"
                onClick={() => this.props.decideGeofence(true)}
              >
                OK
              </button>
            </div>
          </div>
        )}
        {
          <>
            {!this.props.isPlayback && (
              <>
                <div className="theme_wraper onLoad ">
                  <div
                    onClick={() => this.loadMapBasedOnTheme(false)}
                    className={classnames('dark option-icon', {
                      enable: !this.state.isLightThemeActive,
                    })}
                  ></div>

                  <div
                    onClick={() => this.loadMapBasedOnTheme(true)}
                    className={classnames('light option-icon', {
                      enable: this.state.isLightThemeActive,
                    })}
                  ></div>
                </div>
              </>
            )}
            {this.props.isPlayback && (
              <div
                className={classnames('finalContainer', {
                  'sm-container': this.props.isExpand,
                  'sm-finalContainer': !this.props.isPlayback,
                })}
              >
                <div className="row">
                  <div
                    className={classnames(
                      'map_theme_outerWraper col-2 ',
                      { 'col-md-1': this.props.isPlayback },
                      { 'pr-0': this.props.isPlayback && this.props.isExpand }
                    )}
                  >
                    <div className="theme_wraper">
                      <div
                        onClick={() => this.loadMapBasedOnTheme(false)}
                        className={classnames('dark option-icon', {
                          enable: !this.state.isLightThemeActive,
                        })}
                      ></div>

                      <div
                        onClick={() => this.loadMapBasedOnTheme(true)}
                        className={classnames('light option-icon', {
                          enable: this.state.isLightThemeActive,
                        })}
                      ></div>
                    </div>
                  </div>
                  <div className="col-11">
                    <div className="row flex-nowrap justify-content-start mr-0 ml-0">
                      {this.props.isPlayback && (
                        <div
                          className={classnames(
                            ' align-items-center p-0',
                            { 'col-md-1': !this.props.isExpand },
                            { 'col-4': this.props.isExpand },
                            { hidePanel: this.state.hidePanel }
                          )}
                        >
                          <div className="toggleWrapper d-flex align-self-center">
                            <span>Alert</span>
                            <label>
                              <Toggle
                                defaultChecked={false}
                                checked={this.state.isAlertActive}
                                onChange={this.alertsToggle}
                                icons={false}
                                disabled={
                                  (disabledAlerts && isData) || !plotAlerts
                                    ? true
                                    : false
                                }
                              />
                            </label>
                          </div>
                        </div>
                      )}
                      <div
                        className={classnames(
                          'newPlayBackDiv  justify-content-start',
                          { 'col-11': !this.props.isExpand },
                          { 'col-8': this.props.isExpand },
                          {
                            'pb-2':
                              this.props.isExpand && this.props.isPlayback,
                          },
                          {
                            noBg:
                              this.state.hidePanel &&
                              this.props.isExpand &&
                              this.props.isPlayback,
                          }
                        )}
                      >
                        <div
                          className={classnames('row w-100 mr-0 ml-0', {
                            'd-flex justify-content-end': this.props.isExpand,
                          })}
                        >
                          {this.props.isPlayback && (
                            <div
                              className={classnames(
                                'pl-0',
                                { 'col-10': !this.props.isExpand },
                                { 'col-9': this.props.isExpand },
                                { hidePanel: this.state.hidePanel }
                              )}
                            >
                              <div
                                className={classnames(
                                  'row w-100 mr-0 ml-0 date-wraper',
                                  {
                                    ' justify-content-start': this.props
                                      .isExpand,
                                  }
                                )}
                              >
                                <div
                                  className={classnames(' pl-0', {
                                    'col-md-6': !this.props.isExpand,
                                  })}
                                >
                                  <FleetDatePicker
                                    selectEndDate={this.selectEndDate}
                                    selectEndTime={this.selectEndTime}
                                    endTimes={this.endTimes}
                                    selectStartDate={this.selectStartDate}
                                    defaultDates={this.defaultDates}
                                    selectSameDate={this.selectSameDate}
                                    selectSameFormatTime={
                                      this.selectSameFormatTime
                                    }
                                    endDay={this.endDay}
                                    endDates={this.endDates}
                                    differentDates={this.differentDates}
                                    sameDates={this.sameDates}
                                    selectLessTime={this.selectLessTime}
                                    disableAlertButton={this.disableAlertButton}
                                    alertsToPlot={this.alertsToPlot}
                                    initialEndDate={this.props.initialEndDate}
                                    initialStartDate={
                                      this.props.initialStartDate
                                    }
                                    disableGo={this.disableGoBtn}
                                  />
                                </div>
                                <div
                                  className={classnames('pr-0 pl-0', {
                                    'col-md-6': !this.props.isExpand,
                                  })}
                                >
                                  <SpeedControls
                                    playbackOption={playbackOption}
                                    selectedSpeed={this.state.selectedSpeed}
                                    speedControls={this.speedControls}
                                    handlePlayBackOptions={
                                      this.handlePlayBackOptions
                                    }
                                    closePlayBack={this.closePlayBack}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {this.props.isPlayback && (
                            <div
                              className={classnames(
                                'pl-0 btn-wrp',
                                { 'col-2': !this.props.isExpand },
                                { 'align-self-end col-3': this.props.isExpand }
                              )}
                            >
                              <LayoutContainer
                                isPlaybackWindow={this.props.isPlaybackWindow}
                                playbackOption={playbackOption}
                                togglePlayBackWindow={this.togglePlayBackWindow}
                                reloadRoute={this.reloadRoute}
                                handlePlayBackOptions={
                                  this.handlePlayBackOptions
                                }
                                plotAlerts={this.props.plotAlerts}
                                alertsToPlot={this.alertsToPlot}
                                playBackArr={this.props.playBackArr}
                                isDatesChanged={this.props.isDatesChanged}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        }

        {this.props.isLiveTrackingEnabled &&
        this.props.isInPlayBackStill == false ? (
          <div className="triPlotingBack" onClick={this.closeLiveTrip}>
            <img src={back_button} alt="back_button" />
          </div>
        ) : (
          ''
        )}

        {this.props.viewClicked ? (
          <div
            className="triPlotingBack"
            onClick={() => this.props.viewChangeRight(false)}
          >
            <img src={back_button} alt="back_button" />
          </div>
        ) : (
          ''
        )}

        {this.props.playBackLength && (
          <FleetNoData closePopUp={this.cancelPlayBackPopUp} />
        )}

        {this.props.tripDataLength && (
          <FleetNoData closePopUp={this.cancelTripPopUp} />
        )}

        {this.props.isGridMap && (
          <div
            className="triPlotingBack"
            onClick={() => this.props.goToNormalMap(false)}
          >
            <img src={back_button} alt="back_button" />
          </div>
        )}
      </>
    );
  }
}
