import React from 'react';

function AlertWindow(props) {
  const { label } = props;

  return (
    <div className="maps-popup">
      <div className="tempo">
        <div className="reponsive-popup">
          <div className="truck-info">
            <div className="eventDetails">
              <div className="mb-3">
                {label && label.length > 0 && label.length === 1 && (
                  <div className="mb-1 head">
                    {label[0] === 'HA' && 'Harsh Acceleration'}
                    {label[0] === 'HB' && 'Harsh Braking'}
                    {label[0] === 'HC' && 'Harsh Cornaring'}
                    {label[0] === 'ID' && 'Idling'}
                    {label[0] === 'FD' && 'FuelDrop'}
                    {label[0] === 'OS' && 'Over Speeding'}
                    {label[0] === 'EO' && 'Engine Overrun'}
                    {label[0] === 'VS' && 'Vehicle Stoppage'}
                  </div>
                )}

                {label && label.length > 0 && label.length === 2 && (
                  <>
                    <div className="mb-1 head">
                      {label[0] === 'HA' && 'Harsh Acceleration'}
                      {label[0] === 'HB' && 'Harsh Braking'}
                      {label[0] === 'HC' && 'Harsh Cornaring'}
                      {label[0] === 'ID' && 'Idling'}
                      {label[0] === 'FD' && 'FuelDrop'}
                      {label[0] === 'OS' && 'Over Speeding'}
                      {label[0] === 'EO' && 'Engine Overrun'}
                      {label[0] === 'VS' && 'Vehicle Stoppage'}
                    </div>
                    <div className="mb-1 head">
                      {label[1] === 'HA' && 'Harsh Acceleration'}
                      {label[1] === 'HB' && 'Harsh Braking'}
                      {label[1] === 'HC' && 'Harsh Cornaring'}
                      {label[1] === 'ID' && 'Idling'}
                      {label[1] === 'FD' && 'FuelDrop'}
                      {label[1] === 'OS' && 'Over Speeding'}
                      {label[1] === 'EO' && 'Engine Overrun'}
                      {label[1] === 'VS' && 'Vehicle Stoppage'}
                    </div>
                  </>
                )}

                {label && label.length > 0 && label.length === 3 && (
                  <>
                    <div className="mb-1 head">
                      {label[0] === 'HA' && 'Harsh Acceleration'}
                      {label[0] === 'HB' && 'Harsh Braking'}
                      {label[0] === 'HC' && 'Harsh Cornaring'}
                      {label[0] === 'ID' && 'Idling'}
                      {label[0] === 'FD' && 'FuelDrop'}
                      {label[0] === 'OS' && 'Over Speeding'}
                      {label[0] === 'EO' && 'Engine Overrun'}
                      {label[0] === 'VS' && 'Vehicle Stoppage'}
                    </div>
                    <div className="mb-1 head">
                      {label[1] === 'HA' && 'Harsh Acceleration'}
                      {label[1] === 'HB' && 'Harsh Braking'}
                      {label[1] === 'HC' && 'Harsh Cornaring'}
                      {label[1] === 'ID' && 'Idling'}
                      {label[1] === 'FD' && 'FuelDrop'}
                      {label[1] === 'OS' && 'Over Speeding'}
                      {label[1] === 'EO' && 'Engine Overrun'}
                      {label[1] === 'VS' && 'Vehicle Stoppage'}
                    </div>

                    <div className="mb-1 head">
                      {label[2] === 'HA' && 'Harsh Acceleration'}
                      {label[2] === 'HB' && 'Harsh Braking'}
                      {label[2] === 'HC' && 'Harsh Cornaring'}
                      {label[2] === 'ID' && 'Idling'}
                      {label[2] === 'FD' && 'FuelDrop'}
                      {label[2] === 'OS' && 'Over Speeding'}
                      {label[2] === 'EO' && 'Engine Overrun'}
                      {label[2] === 'VS' && 'Vehicle Stoppage'}
                    </div>
                  </>
                )}

                {label && label.length > 0 && label.length === 4 && (
                  <>
                    <div className="mb-1 head">
                      {label[0] === 'HA' && 'Harsh Acceleration'}
                      {label[0] === 'HB' && 'Harsh Braking'}
                      {label[0] === 'HC' && 'Harsh Cornaring'}
                      {label[0] === 'ID' && 'Idling'}
                      {label[0] === 'FD' && 'FuelDrop'}
                      {label[0] === 'OS' && 'Over Speeding'}
                      {label[0] === 'EO' && 'Engine Overrun'}
                      {label[0] === 'VS' && 'Vehicle Stoppage'}
                    </div>
                    <div className="mb-1 head">
                      {label[1] === 'HA' && 'Harsh Acceleration'}
                      {label[1] === 'HB' && 'Harsh Braking'}
                      {label[1] === 'HC' && 'Harsh Cornaring'}
                      {label[1] === 'ID' && 'Idling'}
                      {label[1] === 'FD' && 'FuelDrop'}
                      {label[1] === 'OS' && 'Over Speeding'}
                      {label[1] === 'EO' && 'Engine Overrun'}
                      {label[1] === 'VS' && 'Vehicle Stoppage'}
                    </div>

                    <div className="mb-1 head">
                      {label[2] === 'HA' && 'Harsh Acceleration'}
                      {label[2] === 'HB' && 'Harsh Braking'}
                      {label[2] === 'HC' && 'Harsh Cornaring'}
                      {label[2] === 'ID' && 'Idling'}
                      {label[2] === 'FD' && 'FuelDrop'}
                      {label[2] === 'OS' && 'Over Speeding'}
                      {label[2] === 'EO' && 'Engine Overrun'}
                      {label[2] === 'VS' && 'Vehicle Stoppage'}
                    </div>

                    <div className="mb-1 head">
                      {label[3] === 'HA' && 'Harsh Acceleration'}
                      {label[3] === 'HB' && 'Harsh Braking'}
                      {label[3] === 'HC' && 'Harsh Cornaring'}
                      {label[3] === 'ID' && 'Idling'}
                      {label[3] === 'FD' && 'FuelDrop'}
                      {label[3] === 'OS' && 'Over Speeding'}
                      {label[3] === 'EO' && 'Engine Overrun'}
                      {label[3] === 'VS' && 'Vehicle Stoppage'}
                    </div>
                  </>
                )}
              </div>
              {props.OsValue && (
                <div className="alertValue info-bg-trip-distance">
                  {`${props.OsValue} km/hr`}
                </div>
              )}

              <div>
                <div className="locationAlert info-bg-location">
                  {props.formattedLocation}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertWindow;
