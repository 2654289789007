/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import Authorization from '../../Helpers/Authorization';
import { AdminNavLinks, NavLinks } from '../../Helpers/Constants';

const StyledSideNav = styled.div`
  position: fixed; /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  height: 100%;
  width: 3.5rem; /* Set the width of the sidebar */
  z-index: 1; /* Stay on top of everything */
  background-color: #212a54;
  padding-top: 1.5rem;
  transition: 0.3s;
`;

class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePath: window.location.pathname,
      items: NavLinks,
    };
  }

  componentDidMount() {
    const userRole = Authorization.getAuthUserRole();
    if (userRole === 'ROOTADMIN') {
      this.setState({ items: AdminNavLinks });
    }
  }

  onItemClick = path => {
    this.setState({ activePath: path });
  };

  render() {
    const { items, activePath } = this.state;

    return (
      <StyledSideNav>
        {items.map(item => (
          <NavItem
            path={item.path}
            name={item.name}
            css={item.css}
            icon={item.icon}
            onItemClick={this.onItemClick}
            active={item.path === activePath}
            key={item.key}
          />
        ))}
      </StyledSideNav>
    );
  }
}

const RouterSideNav = withRouter(SideNav);

const StyledNavItem = styled.div`
  height: 70px;
  width: 3.5rem;
  text-align: center;
  margin-bottom: 1rem;
  padding: 1rem;
  line-height: 4rem;
  border-right: ${props => (props.active ? 'solid #1698cb' : '')};

  @media only screen and (min-width: 540px) and (max-width: 720px) and (orientation: landscape) {
    height: 40px;
    padding-top: 10px;
  }
`;

class NavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      show: false,
    };
  }

  handleClick = () => {
    const { path, onItemClick } = this.props;
    onItemClick(path);
  };

  hoverFunction = name => {
    this.setState({ name, show: true });
  };

  mouseOut = () => {
    this.setState({ show: false });
  };

  render() {
    const { active } = this.props;

    return (
      <>
        <div className="d-flex wrapper-icon">
          <StyledNavItem active={active}>
            <Link
              to={this.props.path}
              className={this.props.css}
              onClick={this.handleClick}
            >
              <div
                className={this.props.icon}
                onMouseLeave={this.mouseOut}
                onMouseEnter={() => this.hoverFunction(this.props.name)}
              />
            </Link>
          </StyledNavItem>
          {this.state.show && (
            <div className="hover-bar">
              <p className="text-hover">{this.state.name}</p>
            </div>
          )}
        </div>
      </>
    );
  }
}

function SideBar() {
  return <RouterSideNav />;
}

export default SideBar;
