import React from 'react';
import TruckImg from '../../assets/images/27-11-2020.gif';
// import poof from "../../assets/images/poof.svg";

function Loader() {
  return (
    <div className="overlay">
      <div className="loader">
        <div className="loaderWraper">
          <div className="loaderimg">
            {/* <img src={poof} alt="poof" className="poof" /> */}
            <img src={TruckImg} alt="TruckImg" className="" />
          </div>
          {/* <p>Loading, Please wait...</p> */}
        </div>
      </div>
    </div>
  );
}

export default Loader;
