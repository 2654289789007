import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import VehiclePane from '../components/VehiclePane';
import FMTGroupList from '../components/FMTGroupList';
import StatusFilter from '../components/StatusFilter';
import FMTRegionList from '../components/FMTRegionList';
import FMTFilterPanel from '../components/FMTFilterPanel';
import FMTVehicleList from '../components/FMTVehicleList';

const FindMyTruck = props => {
  const {
    isExpand,
    isGridMap,
    showGroups,
    showRegions,
    defaultSpeed,
    showOverspeed,
    filterDataFMT,
    isInPlayBackStill,
    isLiveTrackingEnabled,
    /* statusFilter */
    running,
    runningData,
    runningDisable,
    idle,
    idleData,
    idleDisable,
    offline,
    offlineData,
    offlineDisable,
    /* FMT Filter Panel */
    showApplyClear,
    applyClicked,
    disableGrp,
    disablerg,
    disableOverspeed,
    /* FMTGroupList */
    checkedGroups,
    groupData,
    fmtGroupList,
    /* FMTRegionList */
    checkedRegions,
    regionData,
    fmtRegionList,
    /* FMT Vehicle List */
    disableSearch,
    checkedVehicles,
    myTruck,
    inputFocused,
    vehicleData,
  } = props;

  const clientHgt = document.documentElement.clientHeight;
  const divHeight = (clientHgt * 50) / 100;
  const eachHeight = divHeight / 5 - 5;

  return (
    <>
      {isExpand && (
        <StatusFilter
          running={running}
          runningData={runningData}
          runningDisable={runningDisable}
          idle={idle}
          idleData={idleData}
          idleDisable={idleDisable}
          offline={offline}
          offlineData={offlineData}
          offlineDisable={offlineDisable}
          runningClicked={props.runningClicked}
          idleClicked={props.idleClicked}
          offlineClicked={props.offlineClicked}
        />
      )}

      {isExpand && (
        <div className="filter-panel">
          <FMTFilterPanel
            showApplyClear={showApplyClear}
            applyClicked={applyClicked}
            disableGrp={disableGrp}
            disablerg={disablerg}
            disableOverspeed={disableOverspeed}
            onClearClick={props.onClearClick}
            onApplyClick={props.onApplyClick}
            groupClick={props.groupClick}
            regionClick={props.regionClick}
            speedClick={props.speedClick}
          />
          {showGroups && (
            <OutsideClickHandler
              onOutsideClick={() => {
                props.companyOutClick();
              }}
            >
              <FMTGroupList
                checkedGroups={checkedGroups}
                groupData={groupData}
                fmtGroupList={fmtGroupList}
                selectGroups={props.selectGroups}
                selectAllGroups={props.selectAllGroups}
              />
            </OutsideClickHandler>
          )}

          {showRegions && (
            <OutsideClickHandler
              onOutsideClick={() => {
                props.companyOutClick();
              }}
            >
              <FMTRegionList
                checkedRegions={checkedRegions}
                regionData={regionData}
                fmtRegionList={fmtRegionList}
                selectRegions={props.selectRegions}
                selectAllRegions={props.selectAllRegions}
              />
            </OutsideClickHandler>
          )}

          {showOverspeed && (
            <OutsideClickHandler
              onOutsideClick={() => {
                props.companyOutClick();
              }}
            >
              <div className="overspeed-drop-fmt">
                <ul>
                  <li>
                    <label className="dropdown-container">
                      None
                      <input
                        onClick={props.handleSpeedListSelection}
                        type="radio"
                        checked={!!defaultSpeed}
                      />
                      <span className="checkmark" />
                    </label>
                  </li>
                  {props.getOverspeedList()}
                </ul>
              </div>
            </OutsideClickHandler>
          )}
        </div>
      )}

      <OutsideClickHandler
        onOutsideClick={() => {
          props.handleOutside();
        }}
      >
        <FMTVehicleList
          isExpand={isExpand}
          disableSearch={disableSearch}
          checkedVehicles={checkedVehicles}
          myTruck={myTruck}
          inputFocused={inputFocused}
          vehicleData={vehicleData}
          searchResults={props.searchResults}
          searchVehicle={props.searchVehicle}
          handleFocus={props.handleFocus}
          filterApplied={props.filterApplied}
          clearBtnSearch={props.clearBtnSearch}
        />
      </OutsideClickHandler>

      <VehiclePane
        isExpand={isExpand}
        isGridMap={isGridMap}
        clientHgt={clientHgt}
        eachHeight={eachHeight}
        drawRoute1={props.drawRoute1}
        filterDataFMT={filterDataFMT}
        isInPlayBackStill={isInPlayBackStill}
        regIdClickParent={props.regIdClickParent}
        isLiveTrackingEnabled={isLiveTrackingEnabled}
      />
    </>
  );
};

export default FindMyTruck;
