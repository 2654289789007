/* eslint-disable */
import React from 'react';
import className from 'classnames';
import ReactTooltip from 'react-tooltip';
import truckImage from '../../../assets/images/truck.png';
import dateImage from '../../../assets/images/date.png';

const VehiclePane = (props) => {
  const {
    isExpand,
    filterDataFMT,
    isGridMap,
    isLiveTrackingEnabled,
    isInPlayBackStill,
    clientHgt,
    eachHeight,
  } = props;

  const drawRoute = (vehicleId, tripDetail) => {
    props.drawRoute1(vehicleId, tripDetail);
  };

  const regIdClick = (id, status) => {
    props.regIdClickParent(id, status);
  };

  return (
    <div
      className="vehicle-scroll fmt-scroll mr-1"
      id="scrollingDiv"
      style={{ height: isExpand ? (clientHgt * 50) / 100 : '70vh' }}
    >
      {isExpand ? (
        <>
          {filterDataFMT && filterDataFMT.length > 0 ? (
            filterDataFMT.map((data) => (
              <div
                key={data.vehicleId}
                className={className('expleftSidePanel', data.vehicleStatus)}
                style={{
                  height: eachHeight,
                }}
              >
                <div className="d-flex justify-content-between align-items-baseline">
                  <div className="d-flex alignCenter div-reg">
                    {isGridMap ? (
                      <div
                        style={
                          isLiveTrackingEnabled ||
                          isInPlayBackStill ||
                          isGridMap
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                        }
                        className="reg-width1 bg wb cursor"
                      >
                        {data.registrationId}
                      </div>
                    ) : (
                      <div
                        style={
                          isLiveTrackingEnabled || isInPlayBackStill
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                        }
                        className="reg-width1 bg wb cursor"
                        role="presentation"
                        onClick={() => regIdClick(data, data.vehicleStatus)}
                      >
                        {data.registrationId}
                      </div>
                    )}
                  </div>
                  <div className="d-flex alignCenter div-date">
                    {' '}
                    <div className="date-width1 bg-date wb">
                      {data.lastUpdatedDate}
                    </div>
                  </div>
                  <div className="d-flex alignCenter div-time">
                    <div className="date-width1 bg-time wb">
                      {data.lastUpdatedTime}
                    </div>
                  </div>
                </div>
                {data.tripDetail !== null ? (
                  <div className="d-flex  align-items-baseline">
                    <div
                      role="presentation"
                      className="d-flex alignCenter div-location"
                      onClick={() => drawRoute(data.vehicleId, data.tripDetail)}
                      data-tip={
                        `${data.tripDetail.toCity}-${data.tripDetail.fromCity}`
                          .length > 19
                          ? `${data.tripDetail.fromCity}-${data.tripDetail.toCity}`
                          : ''
                      }
                    >
                      {' '}
                      <div
                        className="date-width1 bg-location wb"
                        style={
                          `${data.tripDetail.toCity}-${data.tripDetail.fromCity}`
                            .length > 19
                            ? {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                cursor: 'pointer',
                              }
                            : { cursor: 'pointer' }
                        }
                      >
                        {data.tripDetail.fromCity}
                        {'-'}
                        {data.tripDetail.toCity}
                      </div>
                    </div>
                    <div
                      className="d-flex alignCenter driver-name-padding div-driver"
                      data-tip={
                        data.tripDetail.driverFullName.length >= 10
                          ? data.tripDetail.driverFullName
                          : ''
                      }
                    >
                      {' '}
                      <div
                        className="reg-width1 bg-driver wb"
                        style={
                          data.tripDetail.driverFullName.length >= 10
                            ? {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }
                            : {}
                        }
                      >
                        {data.tripDetail.driverFullName}
                      </div>
                    </div>

                    <ReactTooltip
                      backgroundColor="grey"
                      textColor="white"
                      place="top"
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))
          ) : (
            <div className="no-trucks-fmt padding-no-trucks">
              {' No Trucks Found'}
            </div>
          )}
        </>
      ) : (
        <>
          {filterDataFMT && filterDataFMT.length > 0 ? (
            filterDataFMT.map((data) => (
              <div
                key={data.vehicleId}
                role="presentation"
                className={className('pt-2 leftSidePanel', data.vehicleStatus)}
                onClick={() => drawRoute(data.vehicleId, data.tripDetail)}
              >
                <div className="d-flex justify-content-between align-items-baseline">
                  <img
                    className="truckImage-fmt-coll"
                    src={truckImage}
                    alt="truckImg"
                  />
                  <div className="reg-width">{data.registrationId}</div>
                </div>
                <div className="d-flex justify-content-between align-items-baseline">
                  <img
                    className="dateImage-fmt-coll"
                    src={dateImage}
                    alt="dateImg"
                  />
                  <div className="date-width">{data.lastUpdatedDate}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-trucks-fmt padding-no-trucks-coll">
              {' No Trucks Found'}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VehiclePane;
