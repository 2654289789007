import Request from 'sync-request';

class Env {
  constructor() {
    this.env = null;
  }

  /**
   * To fetch the env values
   *
   * @return json
   */
  fetchEnv() {
    let location = null;
    if (!window.location.origin) {
      // eslint-disable-next-line no-multi-assign
      location = window.location.origin = `${window.location.protocol}//${
        window.location.hostname
      }${window.location.port ? `:${window.location.port}` : ''}`;
    } else {
      location = window.location.origin;
    }

    const url = `${location}/env.json`;
    const res = Request('GET', url, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
    this.env = JSON.parse(res.getBody());
  }

  /**
   * response parser
   *
   * @return string
   */
  responseParser() {
    return response => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json();
      }
      if (
        contentType
        && (contentType.indexOf('application/pdf') !== -1
          || contentType.indexOf('application/csv') !== -1
          || contentType.indexOf('text/csv') !== -1
          || contentType.indexOf('application/ms-excel') !== -1)
      ) {
        return response.blob();
      }

      return response.text();
    };
  }

  /**
   * To set the env values
   *
   * @return json
   */
  setEnv() {
    return this.env;
  }

  /**
   * Get the value based on key
   *
   * @return string
   */
  getEnv(key) {
    const value = this.setEnv();

    return value ? value[key] : '';
  }
}
export default new Env();
