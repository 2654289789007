import React from 'react';
import runningImg from '../../../assets/images/running.png';
import offlineImg from '../../../assets/images/offline.png';
import idleImg from '../../../assets/images/idle.png';

const StatusFilter = props => {
  const {
    running,
    runningDisable,
    runningData,
    idle,
    idleDisable,
    idleData,
    offline,
    offlineData,
    offlineDisable,
  } = props;

  const runningClick = () => {
    props.runningClicked();
  };

  const idleClick = () => {
    props.idleClicked();
  };

  const offlineClick = () => {
    props.offlineClicked();
  };

  return (
    <div
      className="mt-2 d-flex justify-content-around"
      style={{ color: 'white' }}
    >
      <button
        type="button"
        className={
          running
            ? 'running-toggle status-panel d-flex justify-content-between align-items-center'
            : 'outline-none status-panel d-flex justify-content-between align-items-center'
        }
        onClick={runningClick}
        onMouseDown={e => e.preventDefault()}
        id={runningDisable ? 'disableid' : 'notDisabled'}
        disabled={runningDisable}
      >
        {' '}
        <img src={runningImg} alt="running" />
        <div>Running</div>
        {' '}
        <div>{runningData}</div>
        {' '}
      </button>
      <button
        type="button"
        className={
          idle
            ? 'status-panel d-flex justify-content-between align-items-center idle-toggle'
            : 'outline-none status-panel d-flex justify-content-between align-items-center'
        }
        onClick={idleClick}
        onMouseDown={e => e.preventDefault()}
        disabled={idleDisable}
        id={idleDisable ? 'disableid' : 'notDisabled'}
      >
        <img src={idleImg} alt="idle" />
        <div>Idle</div>
        {' '}
        <div>{idleData}</div>
        {' '}
      </button>
      <button
        type="button"
        className={
          offline
            ? 'status-panel d-flex justify-content-between align-items-center offline-toggle'
            : 'outline-none status-panel d-flex justify-content-between align-items-center'
        }
        onClick={offlineClick}
        onMouseDown={e => e.preventDefault()}
        id={offlineDisable ? 'disableid' : 'notDisabled'}
        disabled={offlineDisable}
      >
        <img src={offlineImg} alt="offline" />
        <div>Offline</div>
        {' '}
        <div>{offlineData}</div>
        {' '}
      </button>
    </div>
  );
};

export default StatusFilter;
