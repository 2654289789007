/* eslint-disable */
import {
  getgrprgn,
  getVehicles,
  showAllTrucks,
  routePlayBack,
  alertsPlayBack,
  getFilterFMTData,
  plotLiveTracking,
  submitGeofenceNew,
  geofenceEditDetails,
  getGeofenceSearchList,
  deleteGeoFenceNewPortal,
  getGeofenceDropdownList,
} from './MyFleetAction';
import {
  isResponseComplete,
  isResponseSucces,
  isResponseSuccesForLiveTrip,
  handleToastrMessage,
  Event,
  getFMTDates,
  restrictRole,
} from '../../Helpers/Utility';
import './style.css';
import pako from 'pako';
import React from 'react';
import toastr from 'toastr';
import ReactGA from 'react-ga';
import { subDays } from 'date-fns';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Loader from '../Common/loader';
import isEqual from 'react-fast-compare';
import LiveLeftCell from './FMTGeo/LiveLeftCell';
import { Col, Row } from 'react-bootstrap';
import LiveRightPanel from './components/LiveRightPanel';
import Authorization from '../../Helpers/Authorization';
import { overSpeedList, FMTMetaTitle } from '../../Helpers/Constants';
import Logo from '../../assets/images/Truck-Connect-Logo.png';
import { useLocation, withRouter } from 'react-router-dom';
import Env from '../../Helpers/env';
import Alert from '@material-ui/lab/Alert';

let fired = 0;
const userId = Authorization.getAuthUserId();

class MyLiveTrack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editResponse: {},
      vehicleListDrop: [],
      vehicleListDropInitial: [],
      groupList: [],
      regionListDrop: [],
      ipad: false,
      disableCreateButton: true,
      geofenceId: '',
      fmtGroupList: [],
      polyCord: [],
      fmtRegionList: [],
      disableOverspeed: false,
      searchList: [],
      geofenceDrawn: false,
      isLoadingFMT: false,
      isExpand: true,
      livePlottingData: {},
      disableRoleFilter: false,
      geofencedefaultPop: false,
      runningDisable: false,
      idleDisable: false,
      offlineDisable: false,
      inputFocused: false,
      inputFocusedMin: false,
      openSearchInMin: false,
      showOverspeed: false,
      gIdNew: '',
      viewClicked: false,
      popupShow: false,
      showRegions: false,
      myTruck: '',
      running: false,
      idle: false,
      status: null,
      offline: false,
      geofenceDropdownData: {},
      showCompanies: false,
      initialGeofenceShape: 'circle',
      vehicleList: [],
      vehicleData: [],
      truckList: [],
      regionList: [],
      groupList: [],
      allTruckData: [],
      geoClicked: false,
      isLiveTrackingEnabled: false,
      createGeoClicked: false,
      runningData: 0,
      editResponse: {},
      countScroll: 0,
      LiveData: [],
      isLoading: false,
      idleData: 0,
      offlineData: 0,
      checkedVehicles: [],
      defaultSpeed: true,
      wholeleftAPiData: [],
      showApplyClear: false,
      clearClicked: false,
      vehicleInfoData: {},
      isBackPlayClicked: false,
      playBackMsg: '',
      applyClicked: true,
      selectedFields: {
        companyList: [],
        overSpeed: 0,
      },
      overSpeedSelected: 0,
      companyList: [],
      showGroups: false,
      checkedCompanies: ['all'],
      checkedCompanyRadio: 'None',
      checkedRegions: [],
      checkedGroups: [],
      regionData: [],
      btnBlue: false,
      disableGrp: false,
      disablerg: false,
      groupData: [],
      overSpeedList,
      dropDownData: [],
      isPlaybackWindow: false,
      handlePlayBack: '',
      isPlayback: false,
      playBackData: [],
      isInPlayBackStill: false,
      isShowAllClicked: false,
      triPlottingInfoClicked: false,
      drawRouteClicked: false,
      slectedPlayBackVehicle: '',
      startDate: '',
      endDate: '',
      playBackLength: false,
      tripDataLength: false,
      tripDataMsg: '',
      isLightMode: false,
      isGridMap: false,
      plotAlerts: false,
      alertsPlotted: false,
      alertMarkers: [],
      isAlertsClicked: false,
      disabledAlerts: false,
      isData: false,
      isDatesChanged: true,
      initialStartDate: '',
      initialEndDate: '',
      displayTab: 'fmt',
      isError: false,
      validateId: '',
    };

    this.geoRef = React.createRef();
    this.googleMapRef = React.createRef();
  }

  request = async () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const validateId = searchParams.get('q');
    this.setState({ validateId });
    const response = await fetch(
      `${Env.env.REACT_APP_BACKEND_BASE_URL}/dicvlivelocation/validateurl/${validateId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      const body = await response.json();
      if (body.message) {
        handleToastrMessage('error', `${body.message}`);
        this.setState({ isError: true });
      } else {
        if (body.liveShareVehicleDetails) {
          let data = body.liveShareVehicleDetails;
          const wholeData = data;
          const formattedData = this.createFakeData(wholeData, 0, 10);

          this.setState(
            {
              filterDataFMT: formattedData,
              wholeleftAPiData: wholeData,
              countScroll: 0,
              isLoading: false,
            },
            () => {
              setTimeout(() => {
                this.googleMapRef.generateMarker(
                  this.state.filterDataFMT,
                  this.state.vehicleInfoData
                );
              }, 0);
            }
          );
        } else {
          handleToastrMessage('error', 'Error');
        }
      }
    }
  };

  handleResetLive = () => {
    setTimeout(() => {
      this.googleMapRef.generateMarker(
        this.state.filterDataFMT,
        this.state.vehicleInfoData
      );
    }, 0);
  };

  setExpiredError = () => {
    this.setState({ isError: true });
  };

  componentDidMount() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);

    let ipad = navigator.userAgent.match(/iPad/i);
    let surface = navigator.userAgent.match(/Linux/i);
    if (ipad !== null || surface !== null) {
      this.setState({ ipad: true });
    }
    if (window.google && window.google.maps) {
      this.request();
    } else {
      window.addEventListener('load', this.request);
    }

    if (document.getElementById('scrollingDiv') !== null) {
      document
        .getElementById('scrollingDiv')
        .addEventListener('scroll', this.scrolling);
    }

    if (document.getElementById('scrollingDivIpad') !== null) {
      document
        .getElementById('scrollingDivIpad')
        .addEventListener('scroll', this.scrollingIpad);
    }
    return () => {
      window.removeEventListener('load', this.request);
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.showAllTrucks, this.props.showAllTrucks)) {
      const { response, isFetching } = this.props.showAllTrucks;
      isFetching
        ? this.setState({ isLoading: true })
        : this.setState({ isLoading: false });
      if (
        isResponseComplete(this.props.showAllTrucks) &&
        isResponseSucces(this.props.showAllTrucks)
      ) {
        this.setState(
          {
            allTruckData:
              response.showAllTrucksList && response.showAllTrucksList,
          },
          () => {
            setTimeout(() => {
              this.googleMapRef.generateMarker(
                this.state.allTruckData,
                this.state.vehicleInfoData
              );
            }, 0);
          }
        );
      }
    }

    // if (!isEqual(prevProps.getFilterFMTData, this.props.getFilterFMTData)) {
    //   const { response, isFetching } = this.props.getFilterFMTData;

    //   isFetching
    //     ? this.setState({ isLoadingFMT: true })
    //     : this.setState({ isLoadingFMT: false, isLoading: false });

    //   if (
    //     isResponseComplete(this.props.getFilterFMTData) &&
    //     isResponseSucces(this.props.getFilterFMTData)
    //   ) {
    //     result =
    //       response &&
    //       response.findMyTruckResponse &&
    //       response.findMyTruckResponse.findMyTruckResponseList;

    //     const wholeData =
    //       response &&
    //       response.findMyTruckResponse &&
    //       response.findMyTruckResponse.findMyTruckResponseList;
    //     console.log(wholeData, 'WWWWW');
    //     const formattedData = this.createFakeData(wholeData, 0, 8);

    //     this.setState(
    //       {
    //         filterDataFMT: formattedData,
    //         wholeleftAPiData: wholeData,
    //         runningData:
    //           response &&
    //           response.findMyTruckResponse &&
    //           response.findMyTruckResponse.runningStatusCount,
    //         offlineData:
    //           response &&
    //           response.findMyTruckResponse &&
    //           response.findMyTruckResponse.offlineStatusCount,
    //         idleData:
    //           response &&
    //           response.findMyTruckResponse &&
    //           response.findMyTruckResponse.idleStatusCount,
    //         countScroll: 0,
    //       },
    //       () => {
    //         setTimeout(() => {
    //           this.googleMapRef.generateMarker(
    //             this.state.filterDataFMT,
    //             this.state.vehicleInfoData
    //           );
    //         }, 0);
    //       }
    //     );

    //     if (document.getElementById('scrollingDiv') !== null) {
    //       document
    //         .getElementById('scrollingDiv')
    //         .addEventListener('scroll', this.scrolling);
    //     }

    //     if (document.getElementById('scrollingDivIpad') !== null) {
    //       document
    //         .getElementById('scrollingDivIpad')
    //         .addEventListener('scroll', this.scrollingIpad);
    //     }
    //   } else {
    //     this.setState({ filterDataFMT: [] });
    //   }
    // }
  }

  componentWillMount() {
    let ipad = navigator.userAgent.match(/iPad/i);
    let surface = navigator.userAgent.match(/Linux/i);

    if (ipad !== null || surface !== null) {
      this.setState({ ipad: true });
    }
    if (document.getElementById('scrollingDiv') !== null) {
      document
        .getElementById('scrollingDiv')
        .addEventListener('scroll', this.scrolling);
    }

    if (document.getElementById('scrollingDivIpad') !== null) {
      document
        .getElementById('scrollingDivIpad')
        .addEventListener('scroll', this.scrollingIpad);
    }

    this.getDates();
  }

  componentWillUnmount() {
    if (document.getElementById('scrollingDiv') !== null) {
      document
        .getElementById('scrollingDiv')
        .addEventListener('scroll', this.scrolling);
    }

    if (document.getElementById('scrollingDivIpad') !== null) {
      document
        .getElementById('scrollingDivIpad')
        .addEventListener('scroll', this.scrollingIpad);
    }

    window.removeEventListener('keydown', this.handlekeydown, true);
    window.removeEventListener('keyup', this.handlekeyup, true);
  }

  disableGo = (value) => {
    this.setState({
      isDatesChanged: value,
    });
  };

  setCircleDetail = (c) => {
    this.setState({
      shapeType: 'circle',
      cirLat: c && c.geoFenceCircleLatitude,
      cirLong: c && c.geoFenceCircleLongitude,
      cirRad: c && c.geoFenceCircleRadiusInMeters,
    });
  };

  disableAlertButton = (value) => {
    this.setState({
      disabledAlerts: value,
      plotAlerts: false,
      alertsPlotted: false,
    });
  };

  enableAlerts = (value) => {
    this.setState({
      plotAlerts: value,
    });
  };

  alertsToPlot = () => {
    const vehicleData = {
      vehicleId: parseInt(this.state.selectedVehicelId),
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isAlertsClicked: true }, () => {
      if (this.state.plotAlerts && this.state.alertsPlotted === false) {
        this.googleMapRef.clearAlertMarkers();
        this.props.dispatch(alertsPlayBack(vehicleData));
      }
    });
  };

  setPolygonDetails = (d) => {
    // this.setState({
  };

  editGeoFence = (newValue) => {
    let cirDetail = {};
    let polDetail = {};
    if (newValue && newValue.geoFenceShapeName === 'circle') {
      cirDetail = {
        shapeType: 'circle',
        geoFenceCircleRadiusInMeters:
          newValue && newValue.geoFenceCircleRadiusInMeters,
        geoFenceCircleLatitude: newValue && newValue.geoFenceCircleLatitude,
        geoFenceCircleLongitude: newValue && newValue.geoFenceCircleLongitude,
      };

      this.setState({ circleDetail: cirDetail });
    } else if (newValue && newValue.geoFenceShapeName === 'polygon') {
      polDetail = {
        geoFencePolygonCoordinates:
          newValue && newValue.geoFencePolygonCoordinates,
        shapeType: 'polygon',
      };
    }
  };

  editGeoApi = (id) => {
    const circleDet = {};
    this.setState({
      gIdNew: id && id.geoFenceId,
      shapeType: id && id.geoFenceShapeName,
      polyCord: id && id.geoFencePolygonCoordinatesList,
      cirLat: id && id.circleLatitude,
      cirLong: id && id.circleLongitude,
      cirRad: id && id.radius,
    });
    const gid = id.geoFenceId;
    this.geoRef.setCreateButtonDisable();
    this.props.dispatch(geofenceEditDetails(gid));

    this.togglePopup(true);
  };

  viewChangeRight = (d) => {
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }
    this.setState({ viewClicked: d }, () => {
      setTimeout(() => {
        this.googleMapRef.removeShapes();
      }, 0);
    });
  };

  createGeopopup = () => {
    this.setState({ createGeoClicked: true, geoClicked: false });
  };

  togglePopup = (val, data, isEdit) => {
    window.removeEventListener('keydown', this.handlekeydown, true);
    window.removeEventListener('keyup', this.handlekeyup, true);

    if (val) {
      this.setState({
        popupShow: true,
        geofenceDrawn: true,
        circleDetail: data,
        isEdit: !!isEdit,
      });
    } else {
      this.setState({
        popupShow: false,
        circleDetail: {},
        isEdit: false,
      });
    }
  };

  decideGeofence = () => {
    this.setState({
      gIdNew: '',
      vehicleListDrop: this.state.vehicleListDropInitial,
    });
    Event(
      'FMT click view',
      'FMT create geofence click',
      'FMT create geofence views'
    );
    this.setState({ geofencedefaultPop: false });

    window.addEventListener('keydown', this.handlekeydown, true);
    window.addEventListener('keyup', this.handlekeyup, true);

    if (this.state.initialGeofenceShape === 'circle') {
      this.googleMapRef.generateGeofenceDrawing('circle');
    }
  };

  handlekeydown = (event) => {
    if (event.shiftKey && fired == 0) {
      fired += 1;
      this.setState({ initialGeofenceShape: 'polygon' }, () => {
        setTimeout(() => {
          this.googleMapRef.generateGeofenceDrawing('polygon');
        }, 0);
      });
    }
  };

  handlekeyup = (event) => {
    fired = 0;
    if (event.shiftKey == false) {
      this.googleMapRef.drawingModeEmpty();
      this.googleMapRef.generateGeofenceDrawing('rect');
      this.setState({ initialGeofenceShape: 'circle' });
      this.googleMapRef.clearMarkers();
      if (this.state.isLightMode) {
        this.googleMapRef.loadLightMap();
      } else {
        this.googleMapRef.initMap();
      }
    }
  };

  handlePlaybackStatus = (status, playBack = false, vehicleId) => {
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }

    this.googleMapRef.clearMarkers();
    this.googleMapRef.closeInfoWindow();
    this.googleMapRef.clearRoutePathPlayback();
    this.googleMapRef.clearEventMarkers();
    this.googleMapRef.clearEventPathPlayBack();

    if (playBack) {
      Event(
        'FMT click view',
        'FMT vehicle playback click',
        'FMT vehicle playback views'
      );
      this.setState({
        isPlaybackWindow: true,
        isExpand: false,
        isInPlayBackStill: true,
      });

      const vehicleData = {
        vehicleId: parseInt(vehicleId),
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      this.props.dispatch(routePlayBack(vehicleData));
      this.setState({
        initialStartDate: this.state.startDate,
        initialEndDate: this.state.endDate,
      });
    } else if (this.state.drawRouteClicked) {
      this.setState({ isExpand: false });
    } else {
      this.setState({
        isPlaybackWindow: false,
        isExpand: true,
        isInPlayBackStill: false,
        isLiveTrackingEnabled: false,
      });
    }
    this.setState({
      selectedVehicelId: vehicleId,
      isPlayback: status,
    });
  };

  startPlayBack = (status = '') => {
    if (status === 'exit') {
      const startDate = subDays(new Date(), 1);
      const endDate = new Date();

      const dates = getFMTDates(startDate, endDate);

      this.setState({
        isLoading: true,
        isBackPlayClicked: true,
        playBackLength: false,
        plotAlerts: false,
        alertsPlotted: false,
        isData: false,
        emDate: new Date(),
        emxDate: new Date(),
        startDate: dates.FinalFormat,
        endDate: dates.endFinalFormat,
        emTime: 0,
        emxTime: new Date().getHours(),
        mTime: 0,
        mxTime: new Date().getHours(),
      });
      this.googleMapRef.clearRoutePathPlayback();
      if (this.state.isShowAllClicked) {
        this.props.dispatch(showAllTrucks());
      } else if (this.state.triPlottingInfoClicked) {
        this.setState({ isLoading: false });
        this.drawRoute1(this.state.slectedPlayBackVehicle, { a: 'abc' });
      } else if (this.state.applyClicked) {
        this.setState({ isLoading: false }, () => {
          setTimeout(() => {
            this.googleMapRef.generateMarker(
              this.state.filterDataFMT,
              this.state.vehicleInfoData
            );
          }, 0);
        });
      } else if (
        this.state.checkedVehicles &&
        this.state.checkedVehicles.length > 0
      ) {
        const params = {
          status: null,
          filter: null,
          vehicleIds: this.state.checkedVehicles,
        };

        this.props.dispatch(getFilterFMTData(params));
      } else if (this.state.filterDataFMT.length > 5) {
        this.setState({ isLoading: false }, () => {
          setTimeout(() => {
            this.googleMapRef.generateMarker(
              this.state.filterDataFMT,
              this.state.vehicleInfoData
            );
          }, 0);
        });
      } else if (this.state.running || this.state.idle || this.state.offline) {
        const prms = {
          status: this.state.status,
          filter: {
            overSpeed: 0,
            companyIds: [],
            regionIds: [],
            groupIds: [],
          },
        };
        this.props.dispatch(getFilterFMTData(prms));
      } else {
        const params = {
          status: null,
          filter: {
            overSpeed: 0,
            companyIds: [],
            regionIds: [],
            groupIds: [],
          },
        };
        this.props.dispatch(getFilterFMTData(params));
      }

      return this.googleMapRef.playBackOption(status);
    }
    this.googleMapRef.playBackOption(status);
  };

  handlePlayBack = (val) => {
    this.setState({ handlePlayBack: val });
  };

  playBackClose = () => {
    this.setState({
      isPlayback: !this.state.isPlayback,
      isLiveTrackingEnabled: true,
      isInPlayBackStill: true,
    });
  };

  drawRoute1 = (vehicleId, tripDetail) => {
    if (vehicleId && tripDetail != null) {
      Event('FMT click view', 'FMT trip plot click', 'FMT trip plot views');
      this.googleMapRef.closeInfoWindow();
      this.googleMapRef.clearMarkers();
      this.googleMapRef.clearEventMarkers();
      this.googleMapRef.clearRoutePathPlayback();
      this.googleMapRef.clearEventPathPlayBack();
      this.handlePlayBack('');
      this.googleMapRef.pausePlayback();
      this.setState({
        isPlayback: false,
        isPlaybackWindow: false,
        isInPlayBackStill: false,
        isLiveTrackingEnabled: true,
        isExpand: false,
        isLoading: true,
        drawRouteClicked: true,
        slectedPlayBackVehicle: vehicleId,
      });
      this.props.dispatch(plotLiveTracking(vehicleId));
    }
  };

  closeLiveTracking = () => {
    this.googleMapRef.clearEventMarkers();
    this.googleMapRef.clearMarkers();
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }

    this.googleMapRef.clearEventPathPlayBack();
    this.googleMapRef.clearEventMarkers();
    this.setState({
      isBackPlayClicked: false,
      drawRouteClicked: false,
      tripDataLength: false,
      playBackLength: false,
    });

    if (this.state.isShowAllClicked) {
      this.props.dispatch(showAllTrucks());
    } else if (this.state.applyClicked) {
      this.setState({ isLoading: false }, () => {
        setTimeout(() => {
          this.googleMapRef.generateMarker(
            this.state.filterDataFMT,
            this.state.vehicleInfoData
          );
        }, 0);
      });
    } else if (
      this.state.checkedVehicles &&
      this.state.checkedVehicles.length > 0
    ) {
      const params = {
        status: null,
        filter: null,
        vehicleIds: this.state.checkedVehicles,
      };

      this.props.dispatch(getFilterFMTData(params));
    } else if (this.state.running || this.state.idle || this.state.offline) {
      const prms = {
        status: this.state.status,
        filter: {
          overSpeed: 0,
          companyIds: [],
          regionIds: [],
          groupIds: [],
        },
      };
      this.props.dispatch(getFilterFMTData(prms));
    } else {
      const params = {
        status: null,
        filter: {
          overSpeed: 0,
          companyIds: [],
          regionIds: [],
          groupIds: [],
        },
      };
      this.props.dispatch(getFilterFMTData(params));
    }

    this.setState(
      {
        isLiveTrackingEnabled: !this.state.isLiveTrackingEnabled,
        isExpand: true,
      },
      () => {}
    );
  };

  tripPlottingInfoFunction = (d) => {
    this.setState({ triPlottingInfoClicked: d });
  };

  getDates = (startDate, endDate) => {
    const dates = getFMTDates(startDate, endDate);
    this.setState({
      startDate: dates.FinalFormat,
      endDate: dates.endFinalFormat,
    });
  };

  cancelPlayBackPopUp = () => {
    this.setState({ playBackLength: false });
  };

  cancelTripPopUp = () => {
    this.setState({ tripDataLength: false });
  };

  goButton = (startDate, endDate) => {
    this.setState({
      playBackLength: false,
      tripDataLength: false,
      startDate,
      endDate,
      initialEndDate: endDate,
      initialStartDate: startDate,
    });
    this.googleMapRef.clearMarkers();
    this.googleMapRef.clearRoutePathPlayback();
    this.googleMapRef.clearEventMarkers();
    this.googleMapRef.clearEventPathPlayBack();

    this.handlePlayBack('');
    this.googleMapRef.pausePlayback();

    const vehicleData = {
      vehicleId: parseInt(this.state.selectedVehicelId),
      startDate,
      endDate,
    };

    this.props.dispatch(routePlayBack(vehicleData));
  };

  enableLightMode = (modeEabled) => {
    this.setState({
      isLightMode: modeEabled,
    });
  };

  toggleAlerts = () => {
    this.setState({ alertsPlotted: false });
  };

  allTrucks = () => {
    this.handlePlayBack('');
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }
    this.googleMapRef.pausePlayback();
    this.setState({
      playBackLength: false,
      tripDataLength: false,
      showApplyClear: false,
      applyClicked: false,
      isBackPlayClicked: false,
      isLoading: true,
      isPlayback: false,
      isPlaybackWindow: false,
      isLiveTrackingEnabled: false,
      geofencedefaultPop: false,
      isInPlayBackStill: false,
      isShowAllClicked: true,
      countScroll: 0,
    });
    this.googleMapRef.clearRoutePathPlayback();
    this.props.dispatch(showAllTrucks());
  };

  handleExpand = () => {
    this.setState({
      isExpand: !this.state.isExpand,
      inputFocused: false,
      inputFocusedMin: false,
    });
  };

  switchTab = (e) => {
    if (e === 'fmt') {
      if (restrictRole('CUSTOMER')) {
        const params = {
          status: null,
          filter: {
            overSpeed: 0,
            companyIds: [],
            regionIds: [],
            groupIds: [],
          },
        };

        this.props.dispatch(getFilterFMTData(params));
      } else {
        this.props.dispatch(getVehicles());
      }

      this.setState({
        playBackLength: false,
        tripDataLength: false,
        showApplyClear: false,
        applyClicked: false,
        isBackPlayClicked: false,
        isPlayback: false,
        isPlaybackWindow: false,
        isLiveTrackingEnabled: false,
        geofencedefaultPop: false,
        isInPlayBackStill: false,
        isShowAllClicked: false,
        countScroll: 0,
        viewClicked: false,
        isGridMap: false,
      });

      this.googleMapRef.initMap();
    } else {
      this.setState({
        isPlayback: false,
        isLiveTrackingEnabled: false,
        isInPlayBackStill: false,
        playBackLength: false,
        plotAlerts: false,
        isDatesChanged: false,
        tripDataLength: false,
      });

      this.googleMapRef.initMap();
    }

    this.setState({ displayTab: e });
  };

  handleGeofence = () => {
    this.setState({
      geofencedefaultPop: !this.state.geofencedefaultPop,
      vehicleListDrop: this.state.vehicleListDropInitial,
    });
    this.googleMapRef.closeInfoWindow();
  };

  filterApplied = () => {
    Event('FMT click view', 'FMT search Truck click', 'FMT search Truck views');
    this.googleMapRef.clearRoutePathPlayback();
    this.handlePlayBack('');
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }
    this.googleMapRef.pausePlayback();
    const data = this.state.vehicleData.filter(
      ({ isChecked }) => isChecked == true
    );
    this.setState({
      tripDataLength: false,
      applyClicked: false,
      inputFocused: false,
      inputFocusedMin: false,
      playBackLength: false,
      isBackPlayClicked: false,
      running: false,
      offline: false,
      idle: false,
      geofencedefaultPop: false,
      isInPlayBackStill: false,
      isShowAllClicked: false,
    });
    // For resetting the date during vehicle search
    const startDate = subDays(new Date(), 1);
    const endDate = new Date();
    const dates = getFMTDates(startDate, endDate);
    this.setState({
      startDate: dates.FinalFormat,
      endDate: dates.endFinalFormat,
    });
    this.onFilterOkClear(this.state.checkedVehicles);
  };

  onFilterOkClear = (v) => {
    this.setState({
      overSpeedSelected: 0,
      applyClicked: false,
      checkedCompanies: [],
      checkedGroups: [],
      checkedRegions: [],
      defaultSpeed: true,
      disableGrp: false,
      disablerg: false,
      checkedCompanyRadio: 'None',
      filterDataFMT: [],
      isPlayback: false,
      isPlaybackWindow: false,
      isLiveTrackingEnabled: false,
    });

    if (restrictRole('SUPPORT')) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: true });
    }
    const veh = v.map(Number);
    this.initialValues();
    const params = {
      status: null,
      filter: null,
      vehicleIds: v,
    };

    if (restrictRole('SUPPORT')) {
      if (v && v.length !== 0) {
        this.props.dispatch(getFilterFMTData(params));
      }
    } else {
      this.props.dispatch(getFilterFMTData(params));
    }
  };

  initialValues = () => {
    const r = [];
    const g = [];

    this.setState({
      regionData: this.state.fmtRegionList,
      groupData: this.state.fmtGroupList,
    });

    if (restrictRole('SUPPORT')) {
      this.setState({ disableGrp: true, disablerg: true });
    } else {
      this.setState({ disableGrp: false, disablerg: false });
    }
  };

  createFakeData = (fullData, startKey, counter) => {
    const data = [];
    const d = [];
    if (fullData !== null) {
      for (let i = startKey; i < startKey + counter; i++) {
        if (fullData[i] !== undefined) {
          d.push(fullData[i]);
        }
      }

      return d;
    } else {
      return d;
    }
  };

  clearBtnSearch = () => {
    this.setState({
      checkedVehicles: [],
      applyClicked: false,
      playBackLength: false,
      tripDataLength: false,
    });
  };

  submitGeofenceApi = (params) => {
    let grps = params.checkedGroups;
    let rg = params.checkedRegions;
    grps = grps.map((el) => parseInt(el));

    const vg = [];
    const vr = [];
    this.state.groupList &&
      this.state.groupList.map((e) => {
        grps.map((k) => {
          if (e.groupId == k) {
            vg.push(e.vehicle);
          }
        });
      });

    rg = rg.map((el) => parseInt(el));
    this.state.regionListDrop &&
      this.state.regionListDrop.map((e) => {
        rg.map((k) => {
          if (e.regionId == k) {
            vr.push(e.vehicle);
          }
        });
      });

    let finalGrpVehicle = [];
    let finalRgVehicle = [];
    finalGrpVehicle = [].concat.apply([], vg);
    finalRgVehicle = [].concat.apply([], vr);

    const paramGrp = [];
    const paramRg = [];

    finalGrpVehicle.map((g) => {
      paramGrp.push(g.vehicleId);
    });

    finalRgVehicle.map((g) => {
      paramRg.push(g.vehicleId);
    });

    let finalParams = {};

    finalParams = {
      geoFenceId: this.state.gIdNew,
      shapeType: this.state.shapeType,
      geoFencePolygonCoordinates:
        this.state.shapeType == 'polygon' ? this.state.polyCord : null,
      geoFenceName: params.geoName,
      dicvUserId: userId,
      geoFenceCircleLatitude:
        this.state.shapeType == 'polygon' ? null : this.state.cirLat,
      geoFenceCircleLongitude:
        this.state.shapeType == 'polygon' ? null : this.state.cirLong,
      geoFenceCircleRadiusInMeters:
        this.state.shapeType == 'polygon' ? null : this.state.cirRad,
      applyToAllVehicles: '',
      typeId: '',
      validFrom: params.start,
      validTo: params.end,
      entryAlert: params.entry,
      exitAlert: params.exit,
      skippedAlert: false,
      entryNotificationTime: null,
      exitNotificationTime: null,
      vehicleDtoList: null,
      vehicleInfoList: [],
      vehicleList:
        params.checkedVehicles.length > 0
          ? params.checkedVehicles
          : paramGrp.length > 0
          ? paramGrp
          : paramRg.length > 0
          ? paramRg
          : [],
    };

    this.props.dispatch(submitGeofenceNew(finalParams));
  };

  viewChange = (t) => {
    this.setState({ viewClicked: t });
  };

  editFrompopup = (gdetail) => {
    this.setState({
      gIdNew: gdetail && gdetail.geoFenceId,
      polyCord: gdetail && gdetail.geoFencePolygonCoordinatesList,
      shapeType: gdetail && gdetail.geoFenceShapeName,
      cirLat: gdetail && gdetail.circleLatitude,
      cirLong: gdetail && gdetail.circleLongitude,
      cirRad: gdetail && gdetail.radius,
    });
    if (gdetail && gdetail.geoFenceId) {
      this.props.dispatch(geofenceEditDetails(gdetail.geoFenceId));
    }
  };

  editGeofenceCaller = (e, geoFenceName) => {
    this.setState({ popupShow: true }, () => {
      this.geoRef.editGeofenceCall(e, geoFenceName);
    });
  };

  geofenceAllShowCaller = (e) => {
    this.setState({ popupShow: true }, () => {
      this.geoRef && this.geoRef.geofenceAllShow(e);
      this.setState({ popupShow: false });
    });
  };

  setSearchedGroup = (data) => {
    this.setState({ groupList: data });
  };

  setSearchedRegion = (data) => {
    this.setState({ regionListDrop: data });
  };

  setSearchedVehicle = (data) => {
    this.setState({ vehicleListDrop: data });
  };

  switchLoader = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  regIdClickParent = (id, status) => {
    if (!this.state.isLiveTrackingEnabled && !this.state.isInPlayBackStill) {
      this.googleMapRef.regIdClick(id, status);
    }
  };

  companyOutClick = () => {
    this.setState({
      showCompanies: false,
      showGroups: false,
      showRegions: false,
      showOverspeed: false,
    });
  };

  runningClicked = () => {
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }
    this.googleMapRef.clearRoutePathPlayback();
    this.handlePlayBack('');
    this.googleMapRef.pausePlayback();
    this.clearBtnSearch();
    this.setState(
      {
        showApplyClear: false,
        running: !this.state.running,
        isBackPlayClicked: false,
        offline: false,
        idle: false,
        isPlayback: false,
        isPlaybackWindow: false,
        isLiveTrackingEnabled: false,
        geofencedefaultPop: false,
        isInPlayBackStill: false,
        isShowAllClicked: false,
        applyClicked: false,
        playBackLength: false,
        tripDataLength: false,
        countScroll: 0,
      },
      () => {
        this.prepareStatus();
      }
    );
  };

  idleClicked = () => {
    this.handlePlayBack('');
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }
    this.googleMapRef.pausePlayback();
    this.googleMapRef.clearRoutePathPlayback();
    this.clearBtnSearch();
    this.setState(
      {
        playBackLength: false,
        tripDataLength: false,
        showApplyClear: false,
        applyClicked: false,
        idle: !this.state.idle,
        isShowAllClicked: false,
        isBackPlayClicked: false,
        running: false,
        offline: false,
        isPlayback: false,
        isPlaybackWindow: false,
        isLiveTrackingEnabled: false,
        geofencedefaultPop: false,
        isInPlayBackStill: false,
        countScroll: 0,
      },
      () => {
        this.prepareStatus();
      }
    );
  };

  offlineClicked = () => {
    this.handlePlayBack('');
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }
    this.googleMapRef.pausePlayback();
    this.clearBtnSearch();
    this.setState(
      {
        playBackLength: false,
        tripDataLength: false,
        showApplyClear: false,
        applyClicked: false,
        offline: !this.state.offline,
        isShowAllClicked: false,
        isBackPlayClicked: false,
        running: false,
        idle: false,
        isPlayback: false,
        isPlaybackWindow: false,
        isLiveTrackingEnabled: false,
        geofencedefaultPop: false,
        isInPlayBackStill: false,
        countScroll: 0,
      },
      () => {
        this.prepareStatus();
      }
    );
    this.googleMapRef.clearRoutePathPlayback();
  };

  prepareStatus = () => {
    let status = null;
    if (this.state.running) {
      status = 'RUNNING';
    } else if (this.state.idle) {
      status = 'IDLE';
    } else if (this.state.offline) {
      status = 'OFFLINE';
    } else if (
      this.state.running == false &&
      this.state.idle == false &&
      this.state.offline == false
    ) {
      status = null;
    }
    this.setState({ status });
    const prms = {
      status,
      filter: {
        overSpeed: 0,
        companyIds: [],
        regionIds: [],
        groupIds: [],
      },
    };
    this.props.dispatch(getFilterFMTData(prms));
  };

  onClearClick = () => {
    this.setState({ playBackLength: false, tripDataLength: false });
    this.googleMapRef.clearEventMarkers();
    this.googleMapRef.clearMarkers();
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }

    const params = {
      status: null,
      filter: {
        overSpeed: 0,
        companyIds: [],
        regionIds: [],
        groupIds: [],
      },
    };

    this.props.dispatch(getFilterFMTData(params));
    this.setState({
      isPlayback: false,
      isBackPlayClicked: false,
      geofencedefaultPop: false,
      isPlaybackWindow: false,
      isLiveTrackingEnabled: false,
      checkedVehicles: [],
      overSpeedSelected: 0,
      isShowAllClicked: false,
      applyClicked: false,
      checkedCompanies: [],
      checkedGroups: [],
      checkedRegions: [],
      defaultSpeed: true,
      disableGrp: false,
      disablerg: false,
      checkedCompanyRadio: 'None',
      filterDataFMT: [],
      showApplyClear: false,
      running: false,
      offline: false,
      idle: false,
      countScroll: 0,
      isInPlayBackStill: false,
    });
    this.initialValues();
  };

  onApplyClick = () => {
    this.setState({ playBackLength: false, tripDataLength: false });
    this.googleMapRef.clearEventMarkers();
    this.googleMapRef.clearMarkers();
    if (this.state.isLightMode) {
      this.googleMapRef.loadLightMap();
    } else {
      this.googleMapRef.initMap();
    }

    this.googleMapRef.clearRoutePathPlayback();
    this.googleMapRef.clearEventPathPlayBack();
    this.handlePlayBack('');
    this.googleMapRef.pausePlayback();

    let reg = [];
    let grp = [];
    let comp = [];

    this.setState({
      isPlayback: false,
      isBackPlayClicked: false,
      geofencedefaultPop: false,
      isPlaybackWindow: false,
      isLiveTrackingEnabled: false,
      applyClicked: true,
      checkedVehicles: [],
      isShowAllClicked: false,
      running: false,
      offline: false,
      idle: false,
      countScroll: 0,
      isInPlayBackStill: false,
      filterDataFMT: [],
    });

    if (
      this.state.checkedRegions &&
      this.state.checkedRegions.length == 1 &&
      this.state.checkedRegions[0] == 'all'
    ) {
      this.state.regionData.map((e, i) => {
        reg.push(e.regionId);
      });
    } else {
      reg = this.state.checkedRegions.map(Number);
    }
    if (
      this.state.checkedGroups &&
      this.state.checkedGroups.length == 1 &&
      this.state.checkedGroups[0] == 'all'
    ) {
      this.state.groupData.map((e, i) => {
        grp.push(e.groupId);
      });
    } else {
      grp = this.state.checkedGroups.map(Number);
    }

    if (this.state.checkedCompanyRadio == 'None') {
      comp = [];
    } else {
      comp = [parseInt(this.state.checkedCompanyRadio)];
    }
    const params = {
      status: this.state.status,
      filter: {
        overSpeed: parseInt(this.state.overSpeedSelected),
        companyIds: [],
        regionIds: reg,
        groupIds: grp,
      },
    };

    this.props.dispatch(getFilterFMTData(params));
  };

  groupClick = () => {
    this.setState({
      showGroups: !this.state.showGroups,
      showCompanies: false,
      showOverspeed: false,
      showRegions: false,
    });
  };

  regionClick = () => {
    this.setState({
      showRegions: !this.state.showRegions,
      showGroups: false,
      showCompanies: false,
      showOverspeed: false,
    });
  };

  speedClick = () => {
    this.setState({
      showOverspeed: !this.state.showOverspeed,
      showRegions: false,
      showGroups: false,
      showCompanies: false,
    });
  };

  selectRegions = (checkedRegions, checked) => {
    if (checkedRegions) {
      if (checkedRegions.length > 0) {
        this.setState({ showApplyClear: true });
      }
    }
    this.setState(
      {
        checkedRegions,
        checked,
      },
      () => {
        this.disableGroup(checkedRegions);
      }
    );
  };

  disableGroup = (r) => {
    if (r.length > 0) {
      this.setState({ disableGrp: true });
    }
  };

  selectAllRegions = (checkedRegions, checked) => {
    this.setState({ isVehicleSelected: true, checkedRegions, checked });
  };

  selectGroups = (checkedGroups, checked) => {
    if (checkedGroups) {
      if (checkedGroups.length > 0) {
        this.setState({ showApplyClear: true });
      }
    }
    this.setState(
      {
        checkedGroups,
        checked,
      },
      () => {
        this.disableRegions(checkedGroups);
      }
    );
  };

  selectAllGroups = (checkedGroups, checked) => {
    this.setState({ isVehicleSelected: true, checkedGroups, checked });
  };

  handleSpeedListSelection = () => {
    if (!this.state.defaultSpeed) {
      const field = this.state.selectedFields;
      field.overSpeed = 0;
      this.setState(
        {
          defaultSpeed: true,
          overSpeedSelected: 0,
          selectedFields: field,
        },
        () => {
          this.overAllFilterHandler();
        }
      );
    } else {
      this.setState(
        {
          defaultSpeed: false,
        },
        () => {
          this.overAllFilterHandler();
        }
      );
    }
  };

  getOverspeedList = () => {
    const { overSpeedList = [] } = this.state;

    return overSpeedList.map((speed, index) => {
      const { overSpeedSelected } = this.state;
      const chkd = overSpeedSelected == speed.speedLimit;

      return (
        <li key={index}>
          <label className="dropdown-container">
            {speed.speeds}
            <input
              onClick={this.setOverspeedLimit}
              type="radio"
              checked={chkd}
              name={index}
              value={speed.speedLimit}
            />
            <span className="checkmark" />
          </label>
        </li>
      );
    });
  };

  setOverspeedLimit = (e) => {
    const data = e.target.value;
    if (e.target.value) {
      if (
        e.target.value == 60 ||
        e.target.value == 100 ||
        e.target.value == 80
      ) {
        this.setState({ showApplyClear: true });
      }
    }
    const fields = this.state.selectedFields;
    fields.overSpeed = data;
    this.setState(
      {
        overSpeedSelected: data,
        defaultSpeed: false,
        selectedFields: fields,
      },
      () => {
        this.overAllFilterHandler();
      }
    );
  };

  overAllFilterHandler = () => {
    let data = [];
    const {
      selectedFields: { overSpeed },
    } = this.state;

    data =
      data &&
      data.filter((vehicle) => {
        if (overSpeed == 0) {
          return vehicle;
        }
        if (vehicle.currentVehicleSpeed >= overSpeed) {
          return vehicle;
        }
      });
  };

  disableRegions = (g) => {
    if (g.length > 0) {
      this.setState({ disablerg: true });
    }
  };

  downloadBlob = async (url) => {
    const res = await fetch(url);

    const buf = await res.arrayBuffer();

    const outBuf = pako.inflate(buf);

    const str = new TextDecoder().decode(outBuf);

    let obj = JSON.parse(str);

    this.setState({ playBackData: obj }, () => {
      if (obj && obj.data && obj.data.length > 0) {
        this.setState({
          alertsPlotted: false,
          plotAlerts: true,
          isData: true,
          disabledAlerts: false,
          isDatesChanged: true,
        });
        this.googleMapRef.clearMarkers();
        this.googleMapRef.clearRoutePathPlayback();
        setTimeout(() => {
          this.googleMapRef.drawPolylinePlayback(this.state.playBackData.data);
        }, 0);
      } else {
        this.googleMapRef.clearMarkers();
        this.googleMapRef.clearRoutePathPlayback();
        this.googleMapRef.clearFlightPlan();
        this.setState({
          playBackLength: true,
          plotAlerts: false,
          isData: false,
          isDatesChanged: true,
        });
      }
    });
  };

  enableGridMap = () => {
    this.setState({
      isGridMap: true,
    });
  };

  handleCheckBoxClickVehicles = (checkedVehicles, checked) => {
    this.setState({ isVehicleSelected: true, checkedVehicles, checked });
  };

  handleChangeTruck = (str) => {
    this.setState({ myTruck: str });
    this.filterTruck(str);
  };

  filterTruck = (str) => {
    const { vehicleStatusSelected } = this.state;
    const { vehicles } = this.props.getVehicles.response;
    const filteredVehicleList =
      vehicles &&
      Object.keys(vehicles).length > 0 &&
      vehicles.filter((vehicle) => {
        if (
          Object.keys(vehicle).length > 0 &&
          vehicle.registrationId.includes(str) &&
          vehicleStatusSelected === vehicle.vehicleStatus
        ) {
          return vehicle;
        }
      });
    this.setState({ vehicleData: filteredVehicleList });
  };

  handleFocus = () => {
    this.setState({
      inputFocused: true,
      openSearchInMin: true,
      inputFocusedMin: true,
    });
  };

  handleOutside = () => {
    this.setState({ inputFocused: false });
  };

  deleteGeofenceFunc = (id) => {
    this.props.dispatch(deleteGeoFenceNewPortal({ geoFenceIdList: [id] }));
  };

  scrolling = () => {
    const {
      isPlayback,
      isPlaybackWindow,
      isInPlayBackStill,
      isLiveTrackingEnabled,
    } = this.state;
    if (
      isPlayback ||
      isPlaybackWindow ||
      isLiveTrackingEnabled ||
      isInPlayBackStill
    ) {
      return;
    } else {
      this.setState({
        isLiveTrackingEnabled: false,
        isPlayback: false,
        isPlaybackWindow: false,
        isInPlayBackStill: false,
      });
      this.googleMapRef.clearEventMarkers();
      this.googleMapRef.clearEventPathPlayBack();
      this.googleMapRef.clearRoutePathPlayback();
      this.handlePlayBack('');
      this.googleMapRef.pausePlayback();

      let temp = 0;
      let scrollData = [];
      const scrollTop =
        (document.getElementById('scrollingDiv') &&
          document.getElementById('scrollingDiv').scrollTop) ||
        document.body.scrollTop;
      const scrollHeight =
        (document.getElementById('scrollingDiv') &&
          document.getElementById('scrollingDiv').scrollHeight) ||
        document.body.scrollHeight;
      const clientHeight =
        document.getElementById('scrollingDiv').clientHeight ||
        window.innerHeight;
      const scrolledToBottom =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      if (scrolledToBottom) {
        this.setState({ countScroll: this.state.countScroll + 1 }, () => {
          temp = this.state.countScroll * 8;
          scrollData = this.createFakeData(
            this.state.wholeleftAPiData,
            temp,
            8
          );
        });

        this.setState(
          { filterDataFMT: this.state.filterDataFMT.concat(scrollData) },
          () => {
            setTimeout(() => {
              this.googleMapRef.generateMarker(
                this.state.filterDataFMT,
                this.state.vehicleInfoData
              );
            }, 0);
          }
        );
      }
    }
  };

  scrollingIpad = () => {
    const {
      isPlayback,
      isPlaybackWindow,
      isInPlayBackStill,
      isLiveTrackingEnabled,
    } = this.state;
    if (
      isPlayback ||
      isPlaybackWindow ||
      isLiveTrackingEnabled ||
      isInPlayBackStill
    ) {
      return;
    } else {
      this.setState({
        isLiveTrackingEnabled: false,
        isPlayback: false,
        isPlaybackWindow: false,
        isInPlayBackStill: false,
      });
      this.googleMapRef.clearEventMarkers();
      this.googleMapRef.clearEventPathPlayBack();
      this.googleMapRef.clearRoutePathPlayback();
      this.handlePlayBack('');
      this.googleMapRef.pausePlayback();

      let temp = 0;
      let scrollData = [];
      var scrollTop =
        (document.getElementById('scrollingDivIpad') &&
          document.getElementById('scrollingDivIpad').scrollTop) ||
        document.body.scrollTop;
      var scrollHeight =
        (document.getElementById('scrollingDivIpad') &&
          document.getElementById('scrollingDivIpad').scrollHeight) ||
        document.body.scrollHeight;
      var clientHeight =
        document.getElementById('scrollingDivIpad').clientHeight ||
        window.innerHeight;
      var scrolledToBottom =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      if (scrolledToBottom) {
        this.setState({ countScroll: this.state.countScroll + 1 }, () => {
          temp = this.state.countScroll * 5;
          scrollData = this.createFakeData(
            this.state.wholeleftAPiData,
            temp,
            5
          );
        });

        this.setState(
          { filterDataFMT: this.state.filterDataFMT.concat(scrollData) },
          () => {
            setTimeout(() => {
              this.googleMapRef.generateMarker(
                this.state.filterDataFMT,
                this.state.vehicleInfoData
              );
            }, 0);
          }
        );
      }
    }
  };

  render() {
    return (
      <>
        <div className="d-flex justify-content-between bg-color p-2">
          <img src={Logo} className="header-logo" alt="logo" />
        </div>
        {this.state.isError ? (
          <Alert variant="filled" severity="error">
            Link is either Inactive, Expired or is Invalid !
          </Alert>
        ) : (
          <div className="main-wrapper">
            <div className="live-wrapper">
              <Row className="mr-0 ml-0 map-height">
                <LiveLeftCell
                  displayTab={this.state.displayTab}
                  switchTab={this.switchTab}
                  isExpand={this.state.isExpand}
                  allTrucks={this.allTrucks}
                  handleExpand={this.handleExpand}
                  FmtGeoButtonStyle={classnames(
                    'show-button bg-transparent p-2',
                    {
                      'disable-btn': restrictRole('SUPPORT'),
                    }
                  )}
                  disable={restrictRole('SUPPORT')}
                  isPlayback={this.state.isPlayback}
                  isLiveTrackingEnabled={this.state.isLiveTrackingEnabled}
                  handleGeofence={this.handleGeofence}
                  geofencefunc={this.geofencefunc}
                  handlePlayBack={this.handlePlayBack}
                  clearBtnSearch={this.clearBtnSearch}
                  viewChange={this.viewChange}
                  googleMap={this.googleMapRef}
                  togglePopupStatus={this.togglePopup}
                  editGeofenceCaller={this.editGeofenceCaller}
                  geofenceAllShowCaller={this.geofenceAllShowCaller}
                  switchLoader={this.switchLoader}
                  //fmt
                  drawRoute1={this.drawRoute1}
                  isGridMap={this.state.isGridMap}
                  showGroups={this.state.showGroups}
                  showRegions={this.state.showRegions}
                  defaultSpeed={this.state.defaultSpeed}
                  companyOutClick={this.companyOutClick}
                  showOverspeed={this.state.showOverspeed}
                  filterDataFMT={this.state.filterDataFMT}
                  regIdClickParent={this.regIdClickParent}
                  isInPlayBackStill={this.state.isInPlayBackStill}
                  //status Filter
                  running={this.state.running}
                  runningData={this.state.runningData}
                  runningDisable={this.state.runningDisable}
                  idle={this.state.idle}
                  idleData={this.state.idleData}
                  idleDisable={this.state.idleDisable}
                  offline={this.state.offline}
                  offlineData={this.state.offlineData}
                  offlineDisable={this.state.offlineDisable}
                  runningClicked={this.runningClicked}
                  idleClicked={this.idleClicked}
                  offlineClicked={this.offlineClicked}
                  // FMT Filter Panel
                  showApplyClear={this.state.showApplyClear}
                  applyClicked={this.state.applyClicked}
                  disableGrp={this.state.disableGrp}
                  disablerg={this.state.disablerg}
                  disableOverspeed={this.state.disableOverspeed}
                  onClearClick={this.onClearClick}
                  onApplyClick={this.onApplyClick}
                  groupClick={this.groupClick}
                  regionClick={this.regionClick}
                  speedClick={this.speedClick}
                  // FMTGroupList
                  checkedGroups={this.state.checkedGroups}
                  groupData={this.state.groupData}
                  fmtGroupList={this.state.fmtGroupList}
                  selectGroups={this.selectGroups}
                  selectAllGroups={this.selectAllGroups}
                  // FMTRegionList
                  checkedRegions={this.state.checkedRegions}
                  regionData={this.state.regionData}
                  fmtRegionList={this.state.fmtRegionList}
                  selectRegions={this.selectRegions}
                  selectAllRegions={this.selectAllRegions}
                  // FMTOverSpeed
                  getOverspeedList={this.getOverspeedList}
                  // FMT Vehicle List
                  disableSearch={this.state.disableSearch}
                  checkedVehicles={this.state.checkedVehicles}
                  myTruck={this.state.myTruck}
                  inputFocused={this.state.inputFocused}
                  vehicleData={this.state.vehicleData}
                  searchResults={this.handleCheckBoxClickVehicles}
                  searchVehicle={this.handleChangeTruck}
                  handleFocus={this.handleFocus}
                  filterApplied={this.filterApplied}
                  handleOutside={this.handleOutside}
                  handleSpeedListSelection={this.handleSpeedListSelection}
                />
                <Col md={this.state.isExpand ? 8 : 10} className="mt-4">
                  {this.state.isLoading || this.state.isLoadingFMT ? (
                    <Loader />
                  ) : null}
                  <LiveRightPanel
                    isDatesChanged={this.state.isDatesChanged}
                    alertsToggle={this.alertsToggle}
                    isData={this.state.isData}
                    disableGo={this.disableGo}
                    setCircleDetail={this.setCircleDetail}
                    disableAlertButton={this.disableAlertButton}
                    disabledAlerts={this.state.disabledAlerts}
                    enableAlerts={this.enableAlerts}
                    plotAlerts={this.state.plotAlerts}
                    alertsToPlot={this.alertsToPlot}
                    setPolygonDetails={this.setPolygonDetails}
                    deleteGeofenceFunc={this.deleteGeofenceFunc}
                    isExpand={this.state.isExpand}
                    editGeoFence={this.editGeoFence}
                    editGeoApi={this.editGeoApi}
                    viewChangeRight={this.viewChangeRight}
                    viewClicked={this.state.viewClicked}
                    createGeopopup={this.createGeopopup}
                    ref={(map) => {
                      this.googleMapRef = map;
                    }}
                    togglePopupstatus={this.togglePopup}
                    decideGeofence={this.decideGeofence}
                    geofencedefaultPop={this.state.geofencedefaultPop}
                    getPlayBack={this.handlePlaybackStatus}
                    handlePlaybackStatus={this.handlePlaybackStatus}
                    startPlayBack={this.startPlayBack}
                    handlePlayBack={this.handlePlayBack}
                    isPlayback={this.state.isPlayback}
                    handlePlayBackChanges={this.state.handlePlayBack}
                    isPlaybackWindow={this.state.isPlaybackWindow}
                    playBackClose={this.playBackClose}
                    selectedVehicelId={this.state.selectedVehicelId}
                    isLiveTrackingEnabled={this.state.isLiveTrackingEnabled}
                    closeLiveTracking={this.closeLiveTracking}
                    isInPlayBackStill={this.state.isInPlayBackStill}
                    isBackPlayClicked={this.state.isBackPlayClicked}
                    tripPlottingInfoFunction={this.tripPlottingInfoFunction}
                    getDates={this.getDates}
                    playBackLength={this.state.playBackLength}
                    cancelPlayBackPopUp={this.cancelPlayBackPopUp}
                    cancelTripPopUp={this.cancelTripPopUp}
                    tripDataLength={this.state.tripDataLength}
                    goButtonClicked={this.goButton}
                    enableLightMode={this.enableLightMode}
                    isLightMode={this.state.isLightMode}
                    toggleAlerts={this.toggleAlerts}
                    enableGridMap={this.enableGridMap}
                    livePlottingData={this.state.livePlottingData}
                    resetLive={this.handleResetLive}
                    liveShareId={this.state.validateId}
                    errorCallback={this.setExpiredError}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect((state) => ({
  getgrprgn: state.getgrprgn,
  getVehicles: state.getVehicles,
  showAllTrucks: state.showAllTrucks,
  routePlayBack: state.routePlayBack,
  alertsPlayBack: state.alertsPlayBack,
  getFilterFMTData: state.getFilterFMTData,
  plotLiveTracking: state.plotLiveTracking,
  submitGeofenceNew: state.submitGeofenceNew,
  geofenceEditDetails: state.geofenceEditDetails,
  getGeofenceSearchList: state.getGeofenceSearchList,
  deleteGeoFenceNewPortal: state.deleteGeoFenceNewPortal,
  getGeofenceDropdownList: state.getGeofenceDropdownList,
}))(withRouter(MyLiveTrack));
