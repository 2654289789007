/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-parens */
import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { setMinutes, setHours, addDays, subDays } from 'date-fns';
import { hrs } from '../../../Helpers/Constants';
import { getFMTDates } from '../../../Helpers/Utility';

class FleetDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emDate: subDays(new Date(), 1),
      emxDate: new Date(),
      startDate: subDays(new Date(), 1),
      endDate: new Date(),
      emTime: 0,
      emxTime: new Date().getHours(),
      mTime: 0,
      mxTime: 23,
    };
  }

  selectFromDate = (d) => {
    this.setState({
      emTime: hrs[0],
      emxTime: hrs[hrs.length - 1],
    });
    this.props.disableAlertButton();
    let curDate = new Date();
    const r = new Date(d);

    const { initialEndDate } = this.props;
    const dates = getFMTDates(d, initialEndDate);

    if (
      dates.FinalFormat === this.props.initialStartDate &&
      dates.endFinalFormat === this.props.initialEndDate
    ) {
      this.props.disableGo(true);
    } else {
      this.props.disableGo(false);
    }

    let finalHours;

    const startDt = moment(d).format('DD/MM/YYYY');

    const startTime = moment(d).hour();
    this.setState({ emxTime: startTime });

    curDate = moment(curDate).format('DD/MM/YYYY');

    if (new Date().getTime() < r.getTime()) {
      this.setState(
        {
          emDate: new Date().getHours(),
          mTime: new Date().getHours(),
          endDate: d,
          startDate: d.setHours(new Date().getHours()),
        },
        () => {
          this.props.selectStartDate(d);
        }
      );
    } else if (curDate === startDt) {
      const dt = new Date(d).getHours();
      const hours = hrs.filter((hour) => hour >= dt);
      finalHours = hours;
      this.setState(
        {
          emxDate: d,
          emDate: d,
          endDate: d,
          emTime: finalHours[0],
        },
        () => {
          this.props.sameDates(d);
        }
      );
    } else if ((new Date().getTime() - r.getTime()) / (1000 * 3600 * 24) > 1) {
      this.setState({ emxDate: addDays(d, 1), endDate: addDays(d, 1) }, () => {
        this.props.differentDates(d);
      });
    } else {
      this.setState(
        {
          emxDate: new Date(),
          endDate: new Date(),
          emxTime: new Date().getHours(),
        },
        () => {
          this.props.endDates();
        }
      );
    }

    this.setState({ emDate: d }, () => {
      this.props.endDay(d);
    });

    const currentNewDate = moment(this.state.endDate).format('DD/MM/YYYY');

    if (startDt === curDate && startDt === currentNewDate) {
      const dt = new Date().getHours();
      const hours = hrs.filter((hour) => hour <= dt);
      finalHours = hours;
      this.setState(
        {
          mTime: finalHours[0],
          mxTime: finalHours[finalHours.length - 1],
          emxTime: finalHours[finalHours.length - 1],
        },
        () => {
          this.props.selectSameFormatTime(
            this.state.mTime,
            this.state.mxTime,
            this.state.emxTime
          );
        }
      );
    } else if (startDt === curDate) {
      const dt = new Date().getHours();
      const hours = hrs.filter((hour) => hour <= dt);
      finalHours = hours;
      this.setState(
        {
          mTime: finalHours[0],
          mxTime: finalHours[finalHours.length - 1],
        },
        () => {
          this.props.selectSameDate(this.state.mTime, this.state.mxTime);
        }
      );
    } else {
      finalHours = hrs;
      this.setState(
        {
          mTime: finalHours[0],
          mxTime: finalHours[24],
        },
        () => {
          this.props.defaultDates(this.state.mTime, this.state.mxTime);
        }
      );
    }

    this.setState({ startDate: d }, () => {
      this.props.selectStartDate(d);
    });
  };

  selectEndDate = (d) => {
    this.props.disableAlertButton();
    const { initialStartDate } = this.props;

    const dates = getFMTDates(initialStartDate, d);

    if (
      dates.FinalFormat === this.props.initialStartDate &&
      dates.endFinalFormat === this.props.initialEndDate
    ) {
      this.props.disableGo(true);
    } else {
      this.props.disableGo(false);
    }

    let finalHours;

    const startDt = moment(d).format('DD/MM/YYYY');
    let curDate = new Date();
    curDate = moment(curDate).format('DD/MM/YYYY');

    const currentNewDate = moment(this.state.startDate).format('DD/MM/YYYY');

    if (startDt === curDate) {
      if (d.getHours() > new Date().getHours()) {
        this.setState({ endDate: d.setHours(new Date().getHours()) }, () => {
          this.props.endTimes(d);
        });
      }
      const dt = new Date().getHours();
      const hours = hrs.filter((hour) => hour <= dt);
      finalHours = hours;
    } else if (currentNewDate === startDt) {
      const dt = new Date(this.state.startDate).getHours();
      const hours = hrs.filter((hour) => hour >= dt);
      finalHours = hours;
    } else {
      const dt = moment(this.state.startDate).hour();
      const hours = hrs.filter((hour) => hour <= dt);
      finalHours = hours;
    }

    if (finalHours) {
      this.setState(
        {
          emTime: finalHours[0],
          emxTime: finalHours[finalHours.length - 1],
        },
        () => {
          this.props.selectEndTime(this.state.emTime, this.state.emxTime);
        }
      );
    }

    this.setState({ endDate: d }, () => {
      this.props.selectEndDate(d);
    });
  };

  restrictTyping = (e) => e.preventDefault();

  render() {
    const { playbackOption } = this.props;

    return (
      <div className="newplayBackContainer">
        <ul>
          <li>
            <label
              style={{
                paddingRight: '0.3rem',
              }}
            >
              {'From:'}{' '}
            </label>
            <DatePicker
              maxDate={new Date()}
              minTime={setHours(setMinutes(new Date(), 0), this.state.mTime)}
              maxTime={setHours(setMinutes(new Date(), 30), this.state.mxTime)}
              timeFormat="HH:00"
              selected={this.state.startDate}
              timeIntervals={60}
              dateFormat="dd/MM/yyyy H"
              showTimeSelect
              onChange={this.selectFromDate}
              disabled={
                !!(playbackOption === 'play' || playbackOption === 'continue')
              }
              onChangeRaw={this.restrictTyping}
              popperPlacement="bottom-bottom"
            />
          </li>
          <li>
            <label
              style={{
                paddingRight: '0.2rem',
                paddingLeft: '0.5rem',
              }}
            >
              {' To:'}{' '}
            </label>
            <DatePicker
              minDate={this.state.emDate}
              maxDate={this.state.emxDate}
              dateFormat="dd/MM/yyyy H"
              selected={this.state.endDate}
              showTimeSelect
              minTime={setHours(setMinutes(new Date(), 0), this.state.emTime)}
              maxTime={setHours(setMinutes(new Date(), 30), this.state.emxTime)}
              timeFormat="HH:00"
              timeIntervals={60}
              onChange={this.selectEndDate}
              disabled={
                !!(playbackOption === 'play' || playbackOption === 'continue')
              }
              onChangeRaw={this.restrictTyping}
              popperPlacement="bottom-bottom"
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default FleetDatePicker;
