import React from 'react';

const FleetNoData = props => (
  <div className="playBackPopUp">
    <div className="playBackPopUp-heading">
      {'No data Available for the selected Vehicle'}
    </div>
    <div className="text-center">
      <button
        type="button"
        className="playback-close-button"
        onClick={() => props.closePopUp()}
      >
        {'OK'}
      </button>
    </div>
  </div>
);

export default FleetNoData;
