import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import closeIconFmt from '../../../assets/images/close.png';

const FMTFilterPanel = props => {
  const {
    showApplyClear,
    applyClicked,
    disableGrp,
    disablerg,
    disableOverspeed,
  } = props;

  const clearClick = () => {
    props.onClearClick();
  };

  const applyClick = () => {
    props.onApplyClick();
  };

  const groupClick = () => {
    props.groupClick();
  };

  const regionClick = () => {
    props.regionClick();
  };

  const speedClick = () => {
    props.speedClick();
  };

  return (
    <>
      <div className="ml-2  filter-fmt d-flex justify-content-between">
        <div className="pl-1">Filters</div>
        <div className="pull-right fmt-btn-panel">
          {showApplyClear && (
            <>
              <button
                onClick={clearClick}
                type="button"
                className={
                  applyClicked ? 'fmt-clear-btn' : 'fmt-clear-btn-active'
                }
              >
                <span className="d-flex align-items-center">
                  <img
                    className="close-icon-fmt"
                    src={closeIconFmt}
                    alt="close"
                  />

                  {'CLEAR'}
                </span>
              </button>
              <button
                onClick={applyClick}
                type="button"
                className={
                  applyClicked ? 'fmt-apply-btn-active' : 'fmt-apply-btn'
                }
              >
                {'APPLY'}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between ml-2 mr-2">
        <button
          disabled={disableGrp}
          type="button"
          className={
            disableGrp ? 'disable-btn filter-btn d-flex' : 'filter-btn d-flex'
          }
          onClick={groupClick}
        >
          <div className="pr-2">Groups</div>
          <span className="fmt-filter-icon">
            <FontAwesomeIcon icon={faAngleDown} name="fmt-filter-icon" />
          </span>
        </button>

        <button
          disabled={disablerg}
          type="button"
          className={
            disablerg ? 'disable-btn filter-btn d-flex' : 'filter-btn d-flex'
          }
          onClick={regionClick}
        >
          <div className="pr-2">Regions</div>
          <span className="fmt-filter-icon">
            <FontAwesomeIcon icon={faAngleDown} name="fmt-filter-icon" />
          </span>
        </button>

        <button
          type="button"
          className={
            disableOverspeed
              ? 'filter-btn disable-btn d-flex'
              : 'filter-btn d-flex'
          }
          onClick={speedClick}
          disabled={disableOverspeed}
        >
          <div className="pr-2">Overspeed</div>
          <span className="fmt-filter-icon">
            <FontAwesomeIcon icon={faAngleDown} name="fmt-filter-icon" />
          </span>
        </button>
      </div>
    </>
  );
};

export default FMTFilterPanel;
