// React-Core
import React from 'react';
import { render } from 'react-dom';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import callAPIMiddleware from './middleware/callAPIMiddleware';
import reducer from './reducers';

import App from './App';

// SCSS
import './assets/scss/style.scss';
import './assets/scss/newStyles.scss';
import 'toastr/build/toastr.min.css';

// CSS
import * as serviceWorker from './serviceWorker';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

const middleware = [thunkMiddleware, callAPIMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

render(
  <Provider store={createStore(reducer, applyMiddleware(...middleware))}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
