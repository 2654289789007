/*eslint-disable*/
import React from 'react';
import closeIcon from '../../../assets/images/close_icon.png';
import refreshIcon from '../../../assets/images/refresh_icon.png';
import playIcon from '../../../assets/images/play_icon.png';
import pauseIcon from '../../../assets/images/pause_icon.png';

const spdCtrls = [2, 3, 4, 5, 6, 7, 8, 9, 10];

const SpeedControls = (props) => {
  const { selectedSpeed, playbackOption } = props;
  const spdControl = (speed) => {
    props.speedControls(speed);
  };

  const handlePlayBack = (status) => {
    props.handlePlayBackOptions(status);
  };

  const closePlayBack = () => {
    props.closePlayBack();
  };

  return (
    <div className="controlsContainer">
      <div className="d-flex text-center" style={{ paddingTop: '0.2rem' }}>
        <div
          role="presentation"
          className={selectedSpeed === 1 ? 'speed-div-active' : 'speed-div'}
          onClick={() => spdControl(1)}
        >
          {'1'}
        </div>
        {spdCtrls.map((speed) => (
          <>
            <div className="level">-</div>
            <div
              role="presentation"
              className={
                selectedSpeed === speed ? 'speed-div-active' : 'speed-div'
              }
              onClick={() => spdControl(speed)}
            >
              {speed}
            </div>
          </>
        ))}

        {playbackOption !== 'pause' ? (
          <div
            className={`route-play-btn reloadIconPause ${
              playbackOption ? 'refreshDisabled' : ''
            }`}
          >
            <img src={refreshIcon} alt="refresh_icon" />
          </div>
        ) : (
          <div
            role="presentation"
            className="route-play-btn reloadIcon"
            onClick={() => handlePlayBack('restart')}
          >
            <img src={refreshIcon} alt="refresh_icon" />
          </div>
        )}

        {playbackOption === 'play' ||
        playbackOption === 'pause' ||
        playbackOption === 'continue' ? null : (
          <div
            role="presentation"
            className="route-play-btn playIcon"
            // style={{cursor:'not-allowed'}}
            onClick={() => handlePlayBack('play')}
          >
            <img src={playIcon} alt="playIcon" />
          </div>
        )}

        {playbackOption === 'continue' || playbackOption === 'play' ? (
          <div
            role="presentation"
            className="route-play-btn pauseIcon"
            onClick={() => handlePlayBack('pause')}
          >
            <img src={pauseIcon} alt="pause_icon" />
          </div>
        ) : null}

        {playbackOption === 'pause' ? (
          <div
            role="presentation"
            className="route-play-btn playIcon"
            onClick={() => handlePlayBack('continue')}
          >
            <img src={playIcon} alt="playIcon" />
          </div>
        ) : null}

        <div
          role="presentation"
          className="route-play-btn closeIcon"
          onClick={closePlayBack}
        >
          <img src={closeIcon} alt="closeIcon" />
        </div>
      </div>
    </div>
  );
};

export default SpeedControls;
