import React from 'react';
import backButton from '../../../assets/images/back_button_icon.png';
import routePlay from '../../../assets/images/route_play_icon.png';

const LayoutControls = props => {
  const { isPlaybackWindow, playbackOption, isDatesChanged } = props;

  const handlePlayBack = status => {
    props.handlePlayBackOptions(status);
  };

  const reloadRoute = () => {
    props.reloadRoute();
  };

  const toggleWindow = () => {
    props.togglePlayBackWindow();
  };

  return (
    <div className="layoutContainer">
      {isPlaybackWindow && (
        <>
          <div className="go-button">
            <button
              type="button"
              className={
                playbackOption === 'play'
                || playbackOption === 'continue'
                || isDatesChanged
                  ? 'goButton btn no-click'
                  : 'goButton btn'
              }
              disabled={
                !!(
                  playbackOption === 'play'
                  || playbackOption === 'continue'
                  || isDatesChanged
                )
              }
              onClick={reloadRoute}
            >
              {'GO'}
            </button>
          </div>

          <div
            className="additionalCtrl"
            role="presentation"
            onClick={toggleWindow}
          >
            <img src={routePlay} alt="plug" />
          </div>

          {playbackOption === 'pause' ? (
            <div
              className="backOption"
              role="presentation"
              onClick={() => handlePlayBack('exit')}
            >
              <img src={backButton} alt="back_button" />
            </div>
          ) : (
            <div
              className="backOption"
              role="presentation"
              onClick={() => handlePlayBack('hardExit')}
            >
              <img src={backButton} alt="back_button" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LayoutControls;
