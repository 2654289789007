import {
  CustomerIcon,
  MyIcon,
  VehicleIcon,
  DealerIcon,
  DriverIcon,
  // eslint-disable-next-line import/extensions
} from '../../assets/images/svg/ProfileIcon.jsx';

const ProfileBarSideMenu = [
  {
    name: 'Driver Profile',
    icon: DriverIcon,
    path: '/profiles/driver',
  },
  {
    name: 'Dealer Profile',
    icon: DealerIcon,
    path: '/profiles/dealer',
  },
  {
    name: 'Vehicle Profile',
    icon: VehicleIcon,
    path: '/profiles/vehicle',
  },
  {
    name: 'My Profile',
    icon: MyIcon,
    path: '/profiles/my-profile',
  },
  {
    name: 'Customer Admin',
    icon: CustomerIcon,
    path: '/profiles/customer-admin',
  },
];

export default ProfileBarSideMenu;
