import React from 'react';
import { Button } from 'react-bootstrap';

const FmtGeoButton = props => {
  const {
    styleClass, onClickCall, title, disable
  } = props;

  return (
    <>
      <Button className={styleClass} onClick={onClickCall} disabled={disable}>
        {title}
      </Button>
    </>
  );
};

export default FmtGeoButton;
