/* eslint-disable */
import React from 'react';

class FMTRegionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedRegions: props.checkedRegions,
    };
  }

  handleCheckBoxRegions = (type, e, index) => {
    const { checkedRegions, fields } = this.props;
    if (
      e.target.checked &&
      e.target.value &&
      !checkedRegions.includes(e.target.value) &&
      checkedRegions.includes('all')
    ) {
      checkedRegions.push(e.target.value);
      checkedRegions.splice(checkedRegions.indexOf('all'), 1);
    } else if (
      checkedRegions.includes(e.target.value) &&
      !checkedRegions.includes('all')
    ) {
      checkedRegions.splice(checkedRegions.indexOf(e.target.value), 1);
    } else if (e.target.value === 'all' && !checkedRegions.includes('all')) {
      checkedRegions.length = 0;
      checkedRegions.push('all');
    } else if (!checkedRegions.includes('all')) {
      checkedRegions.push(e.target.value);
    }
    if (!e.target.checked && checkedRegions.length === 0) {
      checkedRegions.push('all');
      const savedAllVehicleId = [];

      this.setState(
        {
          checkedRegions,
          checked: e.target.name,
        },
        () => {
          this.props.selectAllRegions(checkedRegions, this.state.checked);
        }
      );
    }

    this.setState(
      {
        checkedRegions,
        checked: e.target.name,
      },
      () => {
        this.props.selectRegions(checkedRegions, this.state.checked);
      }
    );
  };

  render() {
    const { checkedRegions, regionData, fmtRegionList } = this.props;

    return (
      <div className="region-drop-fmt">
        <ul>
          {regionData.length > 0 ? (
            <li>
              <label className="dropdown-container">
                All
                <input
                  checked={checkedRegions.includes('all')}
                  onChange={(e) => {
                    this.handleCheckBoxRegions('all', e, '');
                  }}
                  type="checkbox"
                  name={'all'}
                  value={'all'}
                />
                <span className="checkmark" />
              </label>
            </li>
          ) : (
            <li className="no-data-fmt-drop">No Regions Found</li>
          )}

          {fmtRegionList.map((e, i) => {
            return (
              <li key={i}>
                <label className="dropdown-container">
                  {e.regionName && e.regionName}
                  <input
                    type="checkbox"
                    name={e.regionId}
                    value={e.regionId}
                    defaultChecked={this.state.checked === e.regionId}
                    checked={checkedRegions.includes(e.regionId.toString())}
                    onChange={(e) => {
                      this.handleCheckBoxRegions(e.regionId, e, i);
                    }}
                  />
                  <span className="checkmark" />
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default FMTRegionList;
